import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import AddActivityToLessonModal from "../../../../../Modals/AddActivityToLessonModal/AddActivityToLessonModal";
import SurveysModal from "../../../../../Modals/surveyModal/SurveysModal";
import { LessonContentProvider } from "../../../../../Providers/LessonContentProvider";
import { sendBulkEmails } from "../../../../../Services/api/Roles/RolesProvider";
import {
  CourseProfileContext,
  CourseProfileProvider,
} from "../../../../../Services/api/courses/Trainer/CourseProfileContext";
import {
  getCourseInfo,
  getCourseTree,
} from "../../../../../Services/api/courses/courseProvider";
import { getSurveysForLessonOrCourse } from "../../../../../Services/api/survey/SurveyProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import Content from "../Content/Content";
import LoadingContent from "../LoadingContent/LoadingContent";
import SendMessagesModal from "./SendMessagesModal";
import classes from "./details.module.css";
import { headerActions } from "./headerActions";

function CourseDetails({ id, role, status }) {
  const { t } = useTranslation();
  const history = useHistory();
  const sendMessagesRef = useRef();
  const surveysRef = useRef();

  const courseProfileContext = useContext(CourseProfileContext);
  const [showSendMessagesModal, setShowSendMessagesModal] = useState(false);
  const [courseInfo, setCourseInfo] = useState({});
  const [course, setCourse] = useState({});
  const [canEdits, setCanEdits] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalActivityOpen, setIsModalActivityOpen] = useState(false);
  const [chapterId, setchapterId] = useState(null);
  const [isProgram, setIsProgram] = useState(false);
  const [showSurviesModel, setShowSurviesModel] = useState(false);
  const [survies, setSurvies] = useState([]);

  const handleCloseModal = () => setIsModalActivityOpen(false);

  const handleOpenModal = (chapId) => {
    setIsModalActivityOpen(true);

    setchapterId(chapId);
  };

  const openSurveyModel = () => {
    setShowSurviesModel(true);
    getSurveys();
    surveysRef.current.showModal();
  };
  const closeSurveyModel = () => {
    setShowSurviesModel(false);
    surveysRef.current.dismissModal();
  };

  const openSendMessagesModal = () => {
    setShowSendMessagesModal(true);
    sendMessagesRef.current.showModal();
  };

  const closeSendMessagesModal = () => {
    setShowSendMessagesModal(false);
    sendMessagesRef.current.dismissModal();
  };

  const getSurveys = () => {
    setSurvies([]);
    getSurveysForLessonOrCourse(id, null)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          console.log(res);
          setSurvies(res.data.surveys);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  };

  const coursesPath = () => {
    if (role === "admin") {
      return "/admin/courses";
    } else {
      return `/${role}/${
        isProgram
          ? status === "Closed"
            ? "programs-history"
            : "programs"
          : status === "Closed"
          ? "courses-history"
          : "courses"
      }`;
    }
  };
  const submitSendMessage = (payload) => {
    sendBulkEmails(payload)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data.msg}
            </span>
          );
          closeSendMessagesModal();
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "courses",
      page: t("sidebar.label.courses"),
      pagePath: coursesPath(),
    },
    {
      id: "courseOutline",
      page: t("Courseoutline"),
      active: true,
    },
  ];

  useEffect(() => {
    getCourseInfo(id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          console.log("res.data", res.data);
          setCourse(res.data.course);
          courseProfileContext.setCourse(res.data.course);
          setCanEdits(res.data.course.can_edit || true);
          setIsProgram(res.data.course.is_program);
        }
      })
      .catch((err) => {});
    getCourseTree(id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCourseInfo(res.data.response.courseInfo);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!canEdits) {
      history.push("/private-profile");
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer_access_course")}
        </span>
      );
    }
  }, [canEdits]);

  return (
    <>
      <CourseProfileProvider>
        <LessonContentProvider>
          <div
            className={
              classes["course-details-container"] + " container-fluid mt-4"
            }
          >
            <div className="row">
              <div className="col-12">
                <Breadcrumb list={breadcrumbList} />
              </div>
            </div>
            <div className="row">
              <BasicModal ref={surveysRef}>
                {showSurviesModel && (
                  <SurveysModal
                    label={t("survey.surviesLabel")}
                    title={t("survey.surviesLabel")}
                    body={survies}
                    courseId={id}
                    isTrainer={true}
                    surveysModalRef={surveysRef}
                    accept={closeSurveyModel}
                  />
                )}
              </BasicModal>
              <div className="col-12">
                <MainBox>
                  <MainBoxHead
                    title={t("Courseoutline")}
                    actions={headerActions({
                      t,
                      // ...(role === "admin"
                      //   ? {
                      //       DetailedReportAction: () => {
                      //         history.push({
                      //           pathname: "/admin/all-detailed-report",
                      //           state: { courseID: id },
                      //         });
                      //       },
                      //     }
                      //   : {}),
                      [role === "trainer" && course?.available_surveys_count
                        ? "surveyAction"
                        : ""]: () => {
                        openSurveyModel();
                      },
                      [role !== "partner" && role !== "content_developer"
                        ? "chatAction"
                        : ""]: () => {
                        history.push(`/course/${id}/private-chat`);
                      },
                      [role !== "partner" && role !== "content_developer"
                        ? "groupsAction"
                        : ""]: () => {
                        history.push(`/${role}/course/${id}/teams`);
                      },
                      [role !== "partner" ? "addAction" : ""]: () => {
                        history.push(`/${role}/course/${id}/survey/add`);
                      },
                      [role !== "partner" && role !== "content_developer"
                        ? "sendMessagesAction"
                        : ""]: () => {
                        openSendMessagesModal();
                      },
                      [role !== "partner" ? "surveyList" : ""]: () => {
                        history.push(`/${role}/course/${id}/survey/list`);
                      },
                      [role !== "partner" ? "showAsTrainee" : ""]: () => {
                        history.push(`/${role}/training-courses/${id}/content`);
                      },
                      eduGoalsAction: () => {
                        history.push(`/${role}/course/educational-goals/${id}`);
                      },
                      questionBankAction: () => {
                        history.push(`/${role}/course/${id}/question-bank`);
                      },
                      [courseInfo?.quiz_results_count &&
                      role !== "content_developer"
                        ? "quizStatement"
                        : ""]: () => {
                        history.push(`/${role}/course/${id}/assessments`);
                      },
                      [courseInfo?.activities_answers_count &&
                      role !== "content_developer"
                        ? "activitiesStatement"
                        : ""]: () => {
                        history.push(`/${role}/course/${id}/activities`);
                      },
                    })}
                  />
                  <div
                    className={classes["training-course-syllabus-container"]}
                  >
                    {/* <CourseVideo courseID={id} /> */}
                    <div
                      className={classes["training-course-syllabus-section"]}
                    >
                      <Content
                        courseID={id}
                        isAdmin={
                          role === "admin" ||
                          role === "group-admin" ||
                          role === "manager"
                        }
                        role={role}
                        handleOpenModal={handleOpenModal}
                      />
                      <LoadingContent />
                    </div>
                  </div>
                </MainBox>
              </div>
            </div>
            {isModalActivityOpen && (
              <AddActivityToLessonModal
                chapterId={chapterId}
                dismissModal={handleCloseModal}
                title={t(`trainer.course.lesson.add_activity`)}
              />
            )}
            <BasicModal ref={sendMessagesRef}>
              {showSendMessagesModal && (
                <SendMessagesModal
                  onClose={closeSendMessagesModal}
                  onConfirm={submitSendMessage}
                  type="course"
                  courseID={id}
                />
              )}
            </BasicModal>
          </div>
        </LessonContentProvider>
      </CourseProfileProvider>
    </>
  );
}

export default CourseDetails;
