import { useTranslation } from "react-i18next";

// مكون يعرض رسائل الأخطاء
const ErrorMessages = ({ errors }) => {
  const errs = { ...errors };
  const { t } = useTranslation();
  // دالة لتحويل المفتاح إلى صيغة بشرية
  const humanizeKey = (key) => {
    const parts = key.split(".");
    return (
      parts
        .slice(0, -1)
        .map((part) => {
          return parseFloat(part) ? part + 1 : t(part); // استخدم القاموس أو ارجع إلى الجزء كما هو إذا لم يوجد ترجمة
        })
        .join(" - ")
    ); // اجمع الأجزاء مع "-".
  };
  return (
    <ul>
      {Object.keys(errs).map((key) => (
        <li key={key} className="tw-text-xs">
          {humanizeKey(key) ? humanizeKey(key) + ":" : ""}{" "}
          {errs[key].join(", ")}
        </li>
      ))}
    </ul>
  );
};

export default ErrorMessages;
