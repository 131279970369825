import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar-icon.svg";
import {
  addActivityComment,
  deleteChapterComment,
  fetchChapterCommentsList,
  likeChapterComment,
  unlikeChapterComment,
  updateIsBannedChapterComment,
  updateIsPublishedChapterComment,
} from "../../../../Redux/Actions/activityComment/activityComment.action";
import toLocalTimezone from "../../../../Services/Timezone";
import Chat from "../../../../assets/icons/chat.png";
import ChatActive from "../../../../assets/icons/chatActive.png";
import Comment from "../../../../assets/icons/comment.png";
import CommentActive from "../../../../assets/icons/commentActive.png";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/document.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import Like from "../../../../assets/icons/like.png";
import LikeActive from "../../../../assets/icons/likeActive.png";
import DropDown from "../../../Shared/Components/dorpdown/Dropdown";
import ActivityRepliesBox from "../NewLesson/Content/Activities/List/ActivityRepliesBox";
import CommentForm from "../NewLesson/Content/Activities/List/CommentForm";
import classes from "./discBoard.module.css";
import { downloadCommentFile } from "../../../../Services/api/ComentDownloadFile";
import { toast } from "react-toastify";

export default function DiscBoardCommentBox({
  item,
  isAdmin,
  isTrainer,
  isManager,
  role,
  discussionId,
  isContentDev,
  isClosed,
}) {
  const [activeButton, setActiveButton] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { unlikeActivityCommentLoading, likeActivityCommentLoading } =
    useSelector((state) => state.activityCommentsListReducer);

  const addReplayToComment = (payload) => {
    dispatch(addActivityComment(payload));
    getCommentsListHandler();
  };
  const imgextensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "webp",
  ];
  const getCommentsListHandler = () => {
    dispatch(
      fetchChapterCommentsList({
        id: id,
        // page: currentPage,
        // perPage: 10,
        // filter,
      })
    );
  };
  const downloadActivityFile = (id, filename) => {
    downloadCommentFile(id, filename)
      .then((res) => {})
      .catch((err) => {
        toast.error(<span style={{ fontSize: 15 }}>{err.data.msg}</span>);
      });
  };
  const handleDeleteComment = () => {
    dispatch(deleteChapterComment(item.id));
  };
  const handleLikeComment = () => {
    if (!likeActivityCommentLoading) {
      dispatch(likeChapterComment(item.id));
    }
  };
  const handleUnlikeComment = () => {
    if (!unlikeActivityCommentLoading) {
      dispatch(unlikeChapterComment(item.id));
    }
  };

  const handlePublishComment = () => {
    dispatch(updateIsPublishedChapterComment(item.id));
  };
  const handleBanComment = () => {
    dispatch(updateIsBannedChapterComment(item.id));
  };

  const commentDropdownOptions = [
    {
      label: t("discussionBoard.delete_comment"),
      onClick: () => handleDeleteComment(),
    },
    {
      label: t("discussionBoard.ban_comment"),
      onClick: () => handleBanComment(),
    },
    {
      label: t("discussionBoard.publish_comment"),
      onClick: () => handlePublishComment(),
    },
  ];

  return (
    <div className="tw-border tw-border-gray-200 tw-rounded tw-py-4 tw-space-y-4 border">
      <div className="tw-inline-flex tw-items-center tw-justify-between tw-w-full tw-px-4">
        <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
          <div
            className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
            style={{
              backgroundImage: `url(${item.user.image})`,
            }}
          ></div>
          <div className="tw-py-2">
            <div
              className=" tw-text-center sm:tw-text-start"
              style={{ color: "#24b3b9" }}
            >
              {i18n.language === "ar" ? item.user.name_ar : item.user.name_en}
            </div>
            <div
              className="tw-text-sm tw-text-gray-400 tw-text-center sm:tw-text-start"
              style={{ display: "flex", gap: 10 }}
            >
              <div>
                <CalendarIcon className="tw-h-4" />
              </div>
              <div>
                {`${moment(toLocalTimezone(item.created_at))
                  .local(true)
                  .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(item.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="tw-cursor-pointer tw-w-6 tw-h-6"
          onClick={() => console.log("clicked")}
        >
          <img src={DeleteIcon} alt="" className="tw-w-full tw-h-full" />
        </div> */}
        {(isAdmin || isTrainer || isManager) && (
          <div style={{ display: "flex" }}>
            {item.is_published === true ? (
              <div className={classes["booth-status-container"]}>
                <div
                  className={`${classes["booth-status-box"]} ${classes["booth-status-box-published"]}`}
                >
                  {t("published")}
                </div>
              </div>
            ) : item.is_banned === true ? (
              <div className={classes["booth-status-container"]}>
                <div
                  className={`${classes["booth-status-box"]} ${classes["booth-status-box-banned"]}`}
                >
                  {t("banned")}
                </div>
              </div>
            ) : (
              <div className={classes["booth-status-container"]}>
                <div
                  className={`${classes["booth-status-box"]} ${classes["booth-status-box-waiting"]}`}
                >
                  {t("waiting_publish")}
                </div>
              </div>
            )}
            <DropDown options={commentDropdownOptions} />
          </div>
        )}
      </div>
      <div
        className="tw-text-gray-400 tw-text-sm tw-px-4 ticket_view-replies-body"
        dangerouslySetInnerHTML={{
          __html: item.comment,
        }}
      />
      <div className="tw-px-4 ">
        {Object.keys(item.attachment ?? {}).length ? (
          imgextensions.includes(
            item.attachment.name.split(".").slice(-1)[0]
          ) ? (
            <>
              <div
                className={`${classes["img_cont_comment"]} tw-flex tw-flex-col  tw-items-center tw-bg-gray-100 tw-rounded tw-p-2`}
                // style={{ width: "25%" }}
              >
                <div className="tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-p-2.5  tw-bg-gray-200">
                    <img
                      src={item.attachment.url}
                      alt=""
                      className=""
                      style={{ height: 120, width: "100%" }}
                    />
                  </div>
                </div>
                <div className="tw-flex tw-justify-center">
                  <div
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    {item.attachment.name}
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      downloadActivityFile(item.id, item.attachment.name);
                    }}
                  >
                    <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2 ">
                <div className="tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                    <DocumentIcon className="tw-h-4 tw-w-4" />
                  </div>

                  <div
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    {item.attachment.name}
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      downloadActivityFile(item.id, item.attachment.name);
                    }}
                  >
                    <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                  </button>
                </div>
              </div>
            </>
          )
        ) : (
          <></>
        )}
      </div>
      <div className={classes["replays_container"]}>
        <div className={classes["replays_button"]}>
          {item.is_liked ? (
            <button
              style={{ display: "flex" }}
              onClick={() => handleUnlikeComment()}
              disabled={
                role === "partner" || isClosed || isContentDev
                  ? true
                  : false || unlikeActivityCommentLoading
              }
            >
              <div className={classes["comment_buttons"]}>
                <span>{item.likes}</span>
                <img
                  src={LikeActive}
                  alt=""
                  className={classes["button_img"]}
                />
                <span>{t("like")}</span>
              </div>
            </button>
          ) : (
            <button
              style={{ display: "flex" }}
              onClick={() => handleLikeComment()}
              disabled={
                role === "partner" || isClosed || isContentDev
                  ? true
                  : false || likeActivityCommentLoading
              }
            >
              <div className={classes["comment_buttons"]}>
                <span>{item.likes}</span>
                <img src={Like} alt="" className={classes["button_img"]} />
                <span>{t("like")}</span>
              </div>
            </button>
          )}
        </div>
        <div className={classes["button_split_div"]} />
        <div className={classes["replays_button"]}>
          <button
            style={{ display: "flex" }}
            onClick={() =>
              activeButton === 1 ? setActiveButton(0) : setActiveButton(1)
            }
            disabled={
              role === "partner" || isContentDev || isClosed ? true : false
            }
          >
            <div className={classes["comment_buttons"]}>
              <img
                src={CommentActive}
                alt=""
                className={classes["button_img"]}
              />
              <span>{t("add_replay")}</span>
            </div>
          </button>
        </div>
        <div className={classes["button_split_div"]} />
        <div className={classes["replays_button"]}>
          <button
            style={{ display: "flex" }}
            onClick={() =>
              activeButton === 2 ? setActiveButton(0) : setActiveButton(2)
            }
          >
            <div className={classes["comment_buttons"]}>
              <img src={ChatActive} alt="" className={classes["button_img"]} />
              <span> {t("replaies")}</span>
            </div>
          </button>
        </div>
      </div>
      {activeButton === 1 && (
        <CommentForm
          handleClose={() => setActiveButton(0)}
          addCommentHandler={addReplayToComment}
          commentId={item.id}
          discussionId={discussionId}
        />
      )}
      {activeButton === 2 &&
        item.replies.length > 0 &&
        item.replies.map((replay) =>
          isAdmin || isManager || isTrainer ? (
            <ActivityRepliesBox
              isChapter={true}
              item={replay}
              id={item.id}
              isAdmin={isAdmin}
              isTrainer={isTrainer}
              isManager={isManager}
              commentId={replay.id}
            />
          ) : replay.is_published ? (
            <ActivityRepliesBox
              isChapter={true}
              item={replay}
              id={item.id}
              isAdmin={isAdmin}
              isTrainer={isTrainer}
              isManager={isManager}
              commentId={replay.id}
            />
          ) : (
            <></>
          )
        )}
    </div>
  );
}
