import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import toLocalTimezone from "../../../../../../../Services/Timezone";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DownloadIcon } from "../../../../../../../assets/icons/download.svg";
import { ReactComponent as DocumentIcon } from "../../../../../../../assets/icons/document.svg";
import {
  deleteActivityComment,
  updateIsPublishedActivityComment,
  updateIsBannedActivityComment,
  updateIsBannedChapterComment,
  updateIsPublishedChapterComment,
  deleteChapterComment,
} from "../../../../../../../Redux/Actions/activityComment/activityComment.action";
import DropDown from "../../../../../../Shared/Components/dorpdown/Dropdown";
import { downloadCommentFile } from "../../../../../../../Services/api/ComentDownloadFile";
import classes from "./activities.module.css";
export default function ActivityRepliesBox({
  item,
  commentId,
  isAdmin,
  isTrainer,
  isManager,
  isChapter,
  id,
  preview,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  console.log(id);
  const handleDeleteComment = () => {
    isChapter
      ? dispatch(deleteChapterComment(commentId, true, id))
      : dispatch(deleteActivityComment(commentId, true, id));
  };

  const handlePublishComment = () => {
    isChapter
      ? dispatch(updateIsPublishedChapterComment(commentId, true, id))
      : dispatch(updateIsPublishedActivityComment(commentId, true, id));
  };
  const handleBanComment = () => {
    isChapter
      ? dispatch(updateIsBannedChapterComment(commentId, true, id))
      : dispatch(updateIsBannedActivityComment(commentId, true, id));
  };

  const downloadActivityFile = (id, filename) => {
    downloadCommentFile(id, filename)
      .then((res) => {})
      .catch((err) => {
        toast.error(<span style={{ fontSize: 15 }}>{err.data.msg}</span>);
      });
  };

  const replayDropdownOptions = [
    {
      label: t("discussionBoard.delete_replay"),
      onClick: () => handleDeleteComment(),
    },
    {
      label: t("discussionBoard.ban_replay"),
      onClick: () => handleBanComment(),
    },
    {
      label: t("discussionBoard.publish_replay"),
      onClick: () => handlePublishComment(),
    },
  ];
  const imgextensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "webp",
  ];
  return (
    <div
      style={{
        borderTop: "0.5px solid  rgb(229 231 235 / var(--tw-border-opacity))",
        paddingTop: "16px",
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        className="tw-border tw-border-gray-200 tw-rounded tw-p-4 tw-space-y-4 border"
        style={{ width: "100%" }}
      >
        <div className="tw-inline-flex tw-items-center tw-justify-between tw-w-full">
          <div className="tw-inline-flex tw-items-center tw-justify-center tw-space-s-4">
            <div
              className="tw-bg-white-200 tw-mx-auto tw-rounded-full tw-h-12 tw-aspect-square tw-bg-cover tw-bg-no-repeat tw-bg-center tw-shadow-lg"
              style={{
                backgroundImage: `url(${item.user.image})`,
              }}
            ></div>
            <div className="tw-py-2">
              <div
                className="tw-text-center sm:tw-text-start"
                style={{ color: "#24b3b9" }}
              >
                {i18n.language === "ar" ? item.user.name_ar : item.user.name_en}
              </div>
              <div className="tw-text-sm tw-text-gray-400 tw-text-center sm:tw-text-start">
                {`${moment(toLocalTimezone(item.created_at))
                  .local(true)
                  .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(item?.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
              </div>
            </div>
          </div>
          {isAdmin || isTrainer || isManager ? (
            <div style={{ display: "flex" }}>
              {item.is_published === true ? (
                <div className={classes["booth-status-container"]}>
                  <div
                    className={`${classes["booth-status-box"]} ${classes["booth-status-box-published"]}`}
                  >
                    {t("published")}
                  </div>
                </div>
              ) : item.is_banned === true ? (
                <div className={classes["booth-status-container"]}>
                  <div
                    className={`${classes["booth-status-box"]} ${classes["booth-status-box-banned"]}`}
                  >
                    {t("banned")}
                  </div>
                </div>
              ) : (
                <div className={classes["booth-status-container"]}>
                  <div
                    className={`${classes["booth-status-box"]} ${classes["booth-status-box-waiting"]}`}
                  >
                    {t("waiting_publish")}
                  </div>
                </div>
              )}
              <DropDown options={replayDropdownOptions} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className="tw-text-gray-400 tw-text-sm  ticket_view-replies-body"
          dangerouslySetInnerHTML={{
            __html: item.comment,
          }}
        />
        <div className="tw-px-4">
          {Object.keys(item.attachment ?? {}).length ? (
            imgextensions.includes(
              item.attachment.name.split(".").slice(-1)[0]
            ) ? (
              <>
                <div
                  className={`${classes["img_cont_comment"]} tw-flex tw-flex-col  tw-items-center tw-bg-gray-100 tw-rounded tw-p-2`}
                  // style={{ width: "25%" }}
                >
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-p-2.5  tw-bg-gray-200">
                      <img
                        src={item.attachment.url}
                        alt=""
                        className=""
                        style={{ height: 120, width: 120 }}
                      />
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-center">
                    <div
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.attachment.name}
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        downloadActivityFile(item.id, item.attachment.name);
                      }}
                    >
                      <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="tw-inline-flex tw-items-center tw-space-s-8  tw-bg-gray-100 tw-rounded tw-p-2">
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                      <DocumentIcon className="tw-h-4 tw-w-4" />
                    </div>

                    <div
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {item.attachment.name}
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        downloadActivityFile(item.id, item.attachment.name);
                      }}
                    >
                      <DownloadIcon className="tw-h-4 tw-w-4 tw-mx-2 tw-stroke-teal-600" />
                    </button>
                  </div>
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
