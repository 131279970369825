import { Box, Button, Checkbox, Grid, Input, InputLabel, MenuItem, Option, Radio, Select, Slider, Step, StepButton, Stepper } from "@material-ui/core";
import { Rating } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import ImojiInput from "react-input-emoji";
// import EmojiPicker from 'emoji-picker-react';
import { Divider } from '@mui/material';
import { toast } from "react-toastify";


export const Selection=(props)=>{
  require('./style.css');

  const {t} = useTranslation();

  const optionCreationBoxRef = useRef();
  const itemCreationBoxRef = useRef();
  const optionsOriginAreaRef = useRef();

  const [option, setOption] = useState("");
  const [item, setItem] = useState("");
  const [newOption, setNewOption] = useState("");
  const [items, setItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [sources, setSources] = useState([]);
  const [emojis, setEmojis] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [marks, setMarks] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const radioName = (Math.random() + 1).toString(36).substring(7);

  useEffect(() => {
    if (props.question) {
      setOptions(props.question.options || []);
      setItems(props.question.items || []);
      const localAnswers = props.answers?.filter((answer => answer.question.id == props.question.id));
      setAnswers(localAnswers || []);
    }
  }, [props.question]);

  useEffect(() => {
    if (props.mode == "edit") {
        props.saveQuestionData({options, items});
    }
    // setup marks if rendering a slider
    if (['text_slider', 'constant_sum', 'numeric_slider'].includes(props.selectionType)) {
      var localMarks = options.map((o, i) => ({value:  i, label: o.value}));
      setMarks(localMarks);
      // initiate the min & max values
      computeMinMax();
    }

    if (props.selectionType == 'graphical_rating') {
      setSources(options.map(option => option.src));
    }

  }, [options]);

  useEffect(() => {
    if (props.mode == "edit") {
        props.saveQuestionData({options, items});
    }
  }, [items]);
  
  // To prepare the max and min values for the slider component
  const computeMinMax = () => {
    var min, max = 0;
    if (options.length) {
      min = 0;
      max = options.length - 1;
    }
    setMin(min);
    setMax(max);
  }

  const addOption = (e, index) => {
    if (e.keyCode === 13) {
        saveOption()
    }
  }

  const addItem = (e) => {
    if (e.keyCode === 13) {
        saveItem()
    }
  }

  const removeOptions = async (index) => {
    const targetOption = options[index];
    if (targetOption.id) {
      var isDeleted = await props.deleteOptionFromServer(targetOption.id);
      if (! isDeleted) return;
    }
    var filteredOptions = options.filter((option, i) => i != index);
    setOptions(filteredOptions);
  }

  const removeItem = (index) => {
    var filteredItems = items.filter((option, i) => i != index);
    setItems(filteredItems);
  }

  const saveOption = () => {
    if (option == "") {
      alert("Option cannot be empty string");
      return;
    }
    setOptions([...options, {value: option}]);
    // clear previous option
    // setOption("");
    optionCreationBoxRef.current.value = "";
    optionCreationBoxRef.current.focus();
  }

  const saveItem = () => {
    if (item == "") {
      alert("Item cannot be ampty string");
      return;
    }
    setItems([...items, {name: item}]);
    itemCreationBoxRef.current.value = "";
    itemCreationBoxRef.current.focus();
  }

  const updateOption = (index, value) => {
    var option = options[index];
    option.value = value;
    setOptions(options);
  }

  const updateItem = (index, value) => {
    var item = items[index];
    item.name = value;
    setItems(items);
  }

  const isSelected = (option) => {
    let answer;
    answer = answers.filter(answer => answer.option.id == option.id);
    return answer.length? true : false;
  }

  const getContactInputValue = (option) => {
    let filteredAnswers = answers.filter(answer => answer.option.id == option.id);
    return filteredAnswers.length? filteredAnswers[0].value : null;
  }

  const isSmileySelected = (option) => {
    let answer;
    answer = answers.filter(answer => parseInt(answer.value) == option.id);
    return answer.length? true : false;
  }

  const setAnswer = (option) => {
    // ignore the action if user cannot answer
    if (!props.canAnswer) return;
    // or, continue answering
    var found = false;
    var answerIndex = -1;
    var answerIndexs = [];
    var currentAnswers = [...answers];

    currentAnswers.map((answer, index) => {
      if (option.id == answer.option.id) {
        // set related item options
        answerIndexs.push(index);
        found = true;
        answerIndex = index;
      }
    });
    // decide to add the option or remove it
    if (! found) { // add the option
      //clear other options in case it was a radio button
      if (['select_one', 'smiley_rating', 'graphical_rating'].includes(props.selectionType)) {
        currentAnswers = [];
      }
      // add the selected option to the answers list
      currentAnswers.push({value: option.id, option});
    } else { // remove the option
      currentAnswers.splice(answerIndex, 1)
    }
    setAnswers(currentAnswers);
    props.updateAnswers(props.question.id, currentAnswers);
  }

  const setContactFormAnswer = (e, option) => {
    const value = e.target.value;
    var found = false;
    var updatedAnswers = answers.map(answer => {
      if (answer.option.id == option.id) {
        found = true;
        answer.value = value;
      }
      return answer;
    });
    // insert new if not already answered
    if (! found) {
      updatedAnswers = [...answers, {value, option: {...option}}];
    }
    // set the answers state
    setAnswers(updatedAnswers);
    // update the global answers
    props.updateAnswers(props.question.id, updatedAnswers);
  }

  const setSliderAnswer = (value, item) => {
    var found = false;
    var localAnswers = [...answers];
    const selectedOption = options[value];
    if (selectedOption) {
      // allow answers update in case of multiple sliders
      if (item) {
        localAnswers.map(answer => {
          if (answer.item.id == item.id) {
            found = true;
            answer.value = selectedOption.id;
            answer.option = selectedOption;
          }
        });
        // if answer not found then add it
        if (! found) {
          localAnswers.push({value: selectedOption.id, option: selectedOption, item});
        }
      } else {
        // in case of single slider, replace the answer previous answer if exists
        localAnswers = [{value: selectedOption.id, option: selectedOption, item}];
      }
      
      setAnswers(localAnswers);
      props.updateAnswers(props.question.id, localAnswers);
    }
  }

  const getSliderValue = (item) => {
    var value = 0;
    if (! item) {
      if (answers.length) {
        props.question.options.map((o, i) => {
          if (o.id == answers[0].option.id) {
            value = i;
          }
        });
      }
    } else {
      const filteredAnswers = answers.filter(answer => answer.item.id == item.id);
      filteredAnswers.map((answer, index) => {
          props.question.options.map((o, i) => {
            if (o.id == answer.option.id) {
              value = i;
            }
          });
      });
      // return filteredAnswers.length? filteredAnswers[0].option?.value : options.length? options[0].value: "";
    }
    return value; 
  }

  const handleSelectionChange = (index) => {
    setAnswer(options[index]);
  }

  const getSelectedOption = () => {
    var valueIndex = 0;
    if (answers.length) {
      options.map((o, i) => {
        if (o.id == answers[0].option.id) {
          valueIndex = i;
        }
      });
    }
    return valueIndex; 
  }
  
  
  const handleNext = () => {
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const SortableItem = SortableElement(({sortableOption}) => <li>{sortableOption.value}</li>);

  const SortableList = SortableContainer(({items}) => {
    return (
      <ul>
        {items.map((item, index) => (
          <SortableItem 
            key={`item-${index}`} 
            index={index} 
            sortableOption={item} 
            disabled={!props.canAnswer}/>
        ))}
      </ul>
    );
  });

  const reorderItems = (from, to) => {
    var unOrderedOptions = [...options];
    var sourceItem = unOrderedOptions.splice(from, 1)[0];
    unOrderedOptions.splice(to, 0, sourceItem);
    return unOrderedOptions;
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    var reorderedOptions = reorderItems(oldIndex, newIndex);
    setOptions(reorderedOptions);
    // prepare answer
    var localAnswers = reorderedOptions.map(option => {
      return {
        value: option.id,
        option
      }
    });
    props.updateAnswers(props.question.id, localAnswers);
  };

  const getOrderedItems = () => {
    var mappedAnswers = answers.length? answers.map(answer => answer.option) : options;
    return props.canAnswer? mappedAnswers : options;
  }

  const getRankedOrderValue = (option) => {
    var matchingAnswer = "";
    answers.map(answer => {
      if (answer.option.id == option.id) {
        matchingAnswer = answer.value;
      }
    })
    return matchingAnswer;
  }

  const setRaningOrderAnswer = (value, index, option) => {
    var currentAnswers = [...answers];
    // update/add new answer
    if (currentAnswers[index] !== undefined) {
      currentAnswers[index].value = value;
    } else {
      currentAnswers.push({value, option});
    }
    setAnswers(currentAnswers);
    props.updateAnswers(props.question.id, currentAnswers);
  }

  const handelEmojiChange = (e) => {
    const emoji = e.emoji;
    setOptions([...options, {value: emoji}]);
  }

  const prepareFile = (e) => {
    let file = e.target.files[0];
    if (file.name.split(".").pop() == "exe") {
      toast.error(
        <span style={{ fontSize: "14" }}>{t("notAbleExeFile")}</span>
      );
    }
    return file;
  }

  const getImageSource = (file) => {
    if (typeof file == 'string') {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  }

  const saveFileOption = (e) => {
    const fileOption = prepareFile(e);
    setOptions([...options, {value: fileOption}]);
  }

  
  const allowDrag = (ev) => {
    ev.preventDefault();
  }

  const drop = (ev) => {
    ev.preventDefault();
    var id = ev.dataTransfer.getData("optionId");
    let droppedOption = getOptionById(id);
    var currentAnswers = [...answers];
    if (! isSelected(droppedOption)) {
      currentAnswers.push({value: droppedOption.id, option: droppedOption});
    }
    setAnswers(currentAnswers);
    props.updateAnswers(props.question.id, currentAnswers);
  }

  const allowDrop = (ev, option) => {
    ev.dataTransfer.setData("optionId", option.id);
  }

  const claculateDragableAreaWidth = () => {
    return 100 + answers.length * 50;
  }

  const getOptionById = (id) => {
    var option = null;
    options.map(o => {
      if (o.id == id) {
        option = o;
      }
    });
    return option;
  }

  
  const removeOption = (ev) => {
    ev.preventDefault();
    var id = ev.dataTransfer.getData("optionId");
    let droppedOption = getOptionById(id);
    var currentAnswers = [...answers];
    currentAnswers = currentAnswers.filter(answer => answer.option.id != id);
    setAnswers(currentAnswers);
    props.updateAnswers(props.question.id, currentAnswers);
    // remove the red border
    removeOptionsOriginBorder();
  }

  const setOptionsOriginBorder = () => {
    optionsOriginAreaRef.current.className = 'row option-remove-area'
  }

  const removeOptionsOriginBorder = () => {
    optionsOriginAreaRef.current.className = 'row'
  }

  return(<>
    {
        props.mode == 'edit'?
            <div style={{width: '100%'}}>
              {props.selectionType == 'constant_sum'?
                <>
                  <Stepper nonLinear activeStep={activeStep}>
                    <Step label={t('survey.buttons.options')}>
                      <StepButton onClick={handleBack}>{t('survey.buttons.options')}</StepButton>
                    </Step> 
                    <Step label={t('survey.buttons.items')}>
                      <StepButton onClick={handleNext}>{t('survey.buttons.items')}</StepButton>
                    </Step>
                  </Stepper>
                </> : null
              }
              {/* Options */}
              {activeStep == 0?
                <div style={{width: '100%'}}>
                  {/* Create new option */}
                  <div>
                    {props.selectionType == 'smiley_rating'?
                      // <EmojiPicker 
                      //   onEmojiClick={handelEmojiChange} 
                      //   searchDisabled
                      //   // ref={optionCreationBoxRef}
                      // />
                      <></>: props.selectionType == 'graphical_rating'?
                      <>
                        <div 
                          className="survey-card-container" 
                          style={{borderTop: 'solid 4px #ccc',  marginTop: 10, textAlign: 'center', cursor: 'pointer'}}
                          onClick={() => optionCreationBoxRef.current.click()}
                        >
                          <i className="fa fa-upload" style={{fontSize: 90}}/>
                          <br/>
                          <span>{t('survey.buttons.clickToAddOption')}</span>
                          <input 
                            type={'file'} 
                            onChange={(e) => {saveFileOption(e)}} 
                            style={{display: 'none'}}
                            ref={optionCreationBoxRef}
                          />
                        </div>
                      </> :
                      <>
                        <Input 
                          onChange={(e) => setOption(e.target.value)} 
                          onKeyDown={(e) => addOption(e)}
                          inputRef={optionCreationBoxRef}
                          style={{width: '90%'}}
                        />
                        <button onClick={() => saveOption()}>{t('survey.buttons.save')}</button>
                      </>
                    }
                  </div>
                  <br/>
                  <Divider/>
                  <br/>
                  {options.map((o, index) => {
                      // initaiate the image source 
                      return <div key={`singile-choice-option-${index}-${o.value}`} className="survey-card-container" style={{width: '90%', position: 'relative'}}>
                        <div style={{width: '95%', display: 'inline-block'}}>
                          {props.selectionType == 'smiley_rating'?
                            <span>{o.value}</span> : props.selectionType == 'graphical_rating'?
                            
                              <img src={getImageSource(o.value)} width="90px" height="90px"/>
                            :
                            <Input 
                                onChange={(e) => updateOption(index, e.target.value)} 
                                // onKeyDown={(e) => addOption(e, index)} 
                                defaultValue={o.value}
                                style={{width: '95%'}}
                            />
                          }
                        {/* <button onClick={() => saveOption(null, index)}>Update</button> */}
                        </div>
                        <i 
                          className="fa fa-trash option-delete-button" 
                          onClick={() => removeOptions(index)} 
                        />
                      </div>
                  })}
                </div> : null
              }
              {/* Items */}
              {props.selectionType == 'constant_sum' && activeStep == 1?
                <div className={'survey-card-container'}>
                  
                  {/* Create new item */}
                  <div>
                      <Input 
                        onChange={(e) => setItem(e.target.value)} 
                        onKeyDown={(e) => addItem(e)}
                        inputRef={itemCreationBoxRef}
                        style={{width: '90%'}}
                      />
                      <button onClick={() => saveOption()}>{t('survey.buttons.save')}</button>
                  </div>
                  <br/>
                  <Divider/>
                  <br/>
                  {items.map((currentItem, index) => {
                    return <div key={`item-${index}-${currentItem.name}`} className="survey-card-container" style={{width: '90%', position: 'relative'}}>
                      <div style={{width: '95%', display: 'inline-block'}}>
                        <Input 
                            onChange={(e) => updateItem(index, e.target.value)} 
                            // onKeyDown={(e) => addOption(e, index)} 
                            defaultValue={currentItem.name}
                            style={{width: '95%'}}
                        />
                      </div>
                      <i 
                        className="fa fa-trash option-delete-button"  
                        onClick={() => removeItem(index)} 
                      />
                      {/* <button onClick={() => removeItem(index)}>{t('survey.buttons.delete')}</button> */}
                    </div>
                  })} 
                </div> : null
              }

                
              {props.selectionType == 'constant_sum'?
                <>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t('survey.buttons.back')}
                  </Button>
                  <Button onClick={handleNext} disabled={activeStep === 1}>
                    {t('survey.buttons.next')}
                  </Button>
                  </div>
                </> : null
              }
            </div> 
            
            : 

            <div>
              {/* Radio & Checkbox */}
              {props.selectionType == "select_one" || props.selectionType == "select_many"?
                <fieldset>
                  {options.map((o, index) => {
                      return <div key={`singile-choice-option-${index}`}>
                        {/* Single Choice */}
                        {props.selectionType == "select_one"?
                          <Radio 
                              name={radioName}
                              id={`radio-option-${index}`}
                              // onChange={(e) => setOption(e.target.value)} 
                              onClick={() => setAnswer(o)} 
                              checked={isSelected(o)}
                              disabled={!props.canAnswer}
                          /> : 
                          <Checkbox 
                              id={`radio-option-${index}`}
                              // onChange={(e) => setOption(e.target.value)} 
                              onClick={() => setAnswer(o)} 
                              checked={isSelected(o)}
                              disabled={!props.canAnswer}
                          />
                        }
                        <label>{o.value}</label>
                      </div>
                  })}
                </fieldset> : null
              }
              {/* Dropdown List */}
              {props.selectionType == "single_choice"?
                <select 
                  disabled={!props.canAnswer}
                  onChange={(e) => handleSelectionChange(e.target.value)}
                  defaultValue={getSelectedOption()} 
                  value={props.canAnswer? null : getSelectedOption()}
                  style={{width: '100%'}}
                >
                  {options.map((o, index) => {
                    return <option key={`dropdown-list-option-${index}`} value={index}>{o.value}</option>
                  })}
                </select> : null
              }
              {/* Ordering */}
              {props.selectionType == "ordering"?
                <SortableList 
                  items={getOrderedItems()} 
                  onSortEnd={onSortEnd} 
                  disableAutoscroll
                /> : null
              }
              {/* Slider */}
              {['text_slider', 'numeric_slider'].includes(props.selectionType)?
                <div style={{width: '100%'}}>
                  <Slider 
                    marks={marks} 
                    min={min} 
                    max={max}
                    aria-label="Custom marks"
                    step={null}
                    disabled={!props.canAnswer}
                    onChange={(e, value) => setSliderAnswer(value)}
                    value={getSliderValue()}
                    style={{width: '100%'}}
                  />
                </div> : null
              }
              {/* Multiple Sliders */}
              {['constant_sum'].includes(props.selectionType)?
                <>
                  {items.map((currentItem, index) => {
                    return <div style={{width: '100%'}}>
                      <span>{currentItem.name}</span>
                      <Slider 
                        marks={marks} 
                        min={min} 
                        max={max}
                        aria-label="Custom marks"
                        step={null}
                        disabled={!props.canAnswer}
                        onChange={(e, value) => setSliderAnswer(value, currentItem)}
                        value={getSliderValue(currentItem)}
                        style={{width: '100%'}}
                      />
                  </div>
                  })}
                </> :null
              }
              {/* Rank Ordering */}
              {props.selectionType == 'rank_order'?
                <Box sx={{ flexGrow: 1 }}>
                  {options.map((option, index) => {
                    return <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Input 
                            type="number" 
                            value={props.canAnswer? null : getRankedOrderValue(option)}
                            onChange={(e) => setRaningOrderAnswer(e.target.value, index, option)}
                            disabled={! props.canAnswer}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          {option.value}
                        </Grid>
                    </Grid>
                  })}
                </Box> : null
              }
              {/* Smily rating */}
              {props.selectionType == 'smiley_rating'?
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {options.map((option, index) => {
                      return <Grid item xs={1}>
                        <span 
                          readonly={isSmileySelected(option)? "readonly" : null}
                          className={isSmileySelected(option)? 'smiley-input selected-smiley' : 'smiley-input'}
                          onClick={() => setAnswer(option)}
                        >
                          {option.value}
                        </span>
                      </Grid>
                    })}
                  </Grid>
                </Box> : null
              }
              {/* Contact Information */}
              {props.selectionType == 'contact_information'?
                <Box sx={{ flexGrow: 1 }}>
                    {options.map((option, index) => {
                      const optionValue = getContactInputValue(option)
                      return <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <span>{option.value}</span>
                        </Grid>
                        
                        <Grid item xs={8}>
                          <Input 
                            onChange={(e) => setContactFormAnswer(e, option)} 
                            defaultValue={optionValue}
                            value={!props.canAnswer? optionValue : null}
                            disabled={!props.canAnswer}
                            style={{width: '100%'}}
                          />
                        </Grid>
                      </Grid>
                    })}
                </Box> : null
              }
              {/* Graphical rating */}
              {props.selectionType == 'graphical_rating'?
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1}>
                    {options.map((option, index) => {
                      return <Grid item xs={3}>
                        <img 
                          src={getImageSource(option.value)} 
                          width="150px" 
                          height="150px"
                          onClick={() => setAnswer(option)}
                          className={isSelected(option)? 'selected-option' : ''}
                        />
                      </Grid>
                    })}
                  </Grid>
                </Box> : null
              }
              {/* Drag & Drop*/}
              {props.selectionType == 'drag_and_drop'?
                <>
                  <div className={"row"}>
                    <div
                      style={{
                        display: "flex",
                        width: claculateDragableAreaWidth(),
                        height: 50,
                        border: "2px dashed black",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      // className={"tajah-drag-div"}
                      id={"drops"}
                      onDrop={(e) => drop(e)}
                      onDragOver={(e) => allowDrag(e)}
                      onDragStart={(e) => setOptionsOriginBorder()}
                    >
                      {
                        answers.map((answer, index) => {
                          return <span
                            key={`drag-and-drop-${answer.option.id}`}
                            style={{
                              padding: 10,
                              borderWidth: 1,
                              cursor: props.canAnswer? "pointer" : 'normal',
                            }}
                            draggable={props.canAnswer}
                            onDragStart={(e) => allowDrop(e, answer.option)}
                          >
                            {answer.option.value}
                          </span>
                        })
                      }
                    </div>
                  </div>
                  {/* Original options area */}
                  <div
                    style={{
                      width: 300,
                      height: 100,
                      // border: "0px solid black",
                      marginTop: "3%",
                      // display: props.canAnswer? 'none' : ''
                    }}
                    className={"row"}
                    ref={optionsOriginAreaRef}
                    onDrop={(e) => removeOption(e)}
                    onDragOver={(e) => allowDrag(e)}
                  >
                    {options.map((option, index) => {
                      return (
                        <>
                          <div
                            // className={"tajah-drag-div"}
                            id={option.id}
                            draggable="true"
                            onDragStart={(e) => allowDrop(e, option)}
                            style={{display: isSelected(option)? 'none': ''}}
                          >
                            <span
                              style={{
                                padding: 10,
                                borderWidth: 1,
                                cursor: "pointer",
                              }}
                            >
                              {option.value}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </> : null
              }
            </div>
    }
    </>)
}