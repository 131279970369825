import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { CourseContext } from "../../../Services/api/courses/courseContext";
import { getAllCourses } from "../../../Services/api/courses/courseProvider";
import { DepartmentContext } from "../../../Services/api/toutorials/categoryContext";
import { useHistory } from "react-router-dom";
import { getAllDepartment } from "../../../Services/api/toutorials/categoryProvider";
import { ClassificationContext } from "../../../Services/api/toutorials/classificationContext";
import icon from "../../../assets/image/Icon.png";
import noResult from "../../../assets/image/Noresult.png";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import CourseCard from "../../Shared/Components/CourseCard/CourseCard";
import SkeletonSpinnerOverlay from "../../Shared/Components/Spinner/SkeletonSpinnerOverlay";
import TrainingCoursesCard from "../../TrainingCourses/TrainingCoursesLayout/TrainingCourseCard/TrainingCoursesCard";
import TrainingCourseSpinnerCard from "../../TrainingCourses/TrainingCoursesLayout/TrainingCourseSpinnerCard/TrainingCourseSpinnerCard";
import CardCarousel from "../../Shared/Components/SlickCarousel/SlickCarousel";
import classes from "./courselist.module.css";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import { getPublicCoursesList } from "../../../Services/api/PublicCourseListProvider";

function CoursesList({ isLogedIn }) {
  require("./coursesList.css");
  document.body.dir = i18n.dir();
  const history = useHistory();
  const { t } = useTranslation();
  const departmentsContext = useContext(DepartmentContext);
  const classificationContext = useContext(ClassificationContext);
  const courseContext = useContext(CourseContext);
  const [publicCoursesList, setPublicCoursesList] = useState([]);
  const courseSpinner = [];

  for (let i = 0; i < 2; i++) {
    courseSpinner.push(<TrainingCourseSpinnerCard key={i} />);
  }

  useEffect(async () => {
    getAllDepartment()
      .then((res) => {
        departmentsContext.setDepartment(res.data.data);
        departmentsContext.setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    try {
      let response = isLogedIn
        ? await getPublicCoursesList({ type: "courses", status: "Published" })
        : await getAllCourses();
      // console.log(response.data.courses);
      courseContext.setIsContentLoaded(true);
      if (response.data.status) {
        courseContext.setCoursesSearch(response.data.courses);
        isLogedIn
          ? setPublicCoursesList(response.data.courses)
          : courseContext.setCourseFilter(response.data.courses);
      }
    } catch (err) {
      console.log(err);
    }

    return () => {
      courseContext.setCoursesSearch([]);
      courseContext.setIsContentLoaded(false);
    };
  }, [isLogedIn]);

  const handleClick = (btn_id) => {
    let courseDep = courseContext.coursesSearch.filter((id) => {
      return id.department_id == btn_id;
    });

    let newCourseFilterDep = [];
    courseDep.forEach((item) => {
      newCourseFilterDep.push(item);
    });

    courseContext.setDepartment(btn_id);

    if (btn_id == 0) {
      courseContext.setCourseFilter(courseContext.coursesSearch);
    } else {
      if (courseDep.length == 0) {
        courseContext.setCourseFilter([]);
      } else {
        courseContext.setCourseFilter(newCourseFilterDep);
      }
    }
  };

  let spinner = [];
  for (let i = 1; i < 6; i++) {
    spinner.push(
      <div
        className={`col-4 col-md-2`}
        style={{
          marginTop: "-25px",
        }}
      >
        <SkeletonSpinnerOverlay
          backdrop={true}
          skeletonWidth="100"
          skeletonHight="65"
        />
      </div>
    );
  }

  // console.log(courseContext);

  return (
    <div
      className="container-fluid"
      style={{ paddingTop: "30px", paddingBottom: "50px" }}
    >
      <div className="row">
        <div className="col-12">
          <div className={classes.titleCoureses}>
            <div className={classes.titleFont}>
              {isLogedIn ? t("my_current_courses") : t("NewTrainingCourses")}
            </div>
          </div>
          {publicCoursesList?.length > 0 ? (
            <CardCarousel slidesToShow={3} courses={true}>
              {courseContext.isContentLoaded ? (
                courseContext.courseFilter.length !== 0 ? (
                  [
                    ...(isLogedIn
                      ? publicCoursesList
                      : courseContext.courseFilter),
                  ]
                    .slice(0, 6)
                    .map((item) => {
                      return (
                        <>
                          <CourseCard
                            img={item?.img}
                            mode={item.mode}
                            briefDesc={item.brief_description}
                            trainers={item.sub_trainers}
                            seat={`${item.participators}`}
                            level={item.level}
                            title={item?.name}
                            price={item?.price ? item?.price : t("free")}
                            date={item.start_date.split(" ")[0]}
                            hour={item?.number_hours}
                            levelbadge={
                              t("levels.title") + " " + item?.level == 1
                                ? t("levels.beginner")
                                : item?.level == 2
                                ? t("levels.medium")
                                : t("levels.professional")
                            }
                            handleSubmit={() =>
                              history.push(
                                `/training-courses/${item.id}/details`
                              )
                            }
                            expired={item.is_expired}
                            btn={
                              item.is_expired
                                ? t("general.endcourse")
                                : t("general.registercourse")
                            }
                            home={true}
                            // userrate={3}
                          />
                          {/* <TrainingCoursesCard key={item.id} cardInfo={item} /> */}
                        </>
                      );
                    })
                ) : (
                  <span
                    className="course-details"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "50px",
                    }}
                  >
                    <img src={noResult} alt="" />
                    <h5>{t("no_record")}</h5>
                  </span>
                )
              ) : (
                courseSpinner
              )}
            </CardCarousel>
          ) : (
            <div className={classes["no_courses"]}>
              <p>{t(`home.no_courses`)}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CoursesList;
