import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "../../../Shared/Components/Select/Select";
import { toast } from "react-toastify";
import { toServerTime } from "../../../../Services/Timezone";
import { useDispatch } from "react-redux";
import HomeIcon from "../../../../assets/icons/home.svg";
import { formatDate, formatTime } from "../../../../utils/formatDate";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import DataTable from "../../../Shared/Components/DataTable/DataTable";
import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import MainDatePicker from "../../../Shared/Components/MainDatePicker/MainDatePicker";
import classes from "./PublishRequestsForm.module.css";

import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import { getSpecialties } from "../../../../Services/api/profile/profileProvider";
import SelectWithSearch from "../../../Shared/Components/SelectWithSearch/SelectWithSearch";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import { sendCustomEmail } from "../../../../Services/api/PublishReq/PublishReqProvider";
import { useParams } from "react-router-dom";
const PublishRequestsForm = ({
  type,
  submitHandler,
  ReqDetails,
  loading,
  role,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [hoursDatepicker, setHoursDatepicker] = useState(null);
  const [minutesDatepicker, setMinutesDatepicker] = useState(null);
  const [specialties, setSpecialties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [hoursDatepickerEnd, setHoursDatepickerEnd] = useState(null);
  const [minutesDatepickerEnd, setMinutesDatepickerEnd] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [EmailModel, setEmailModel] = useState(false);

  const sendEmailHandler = (email_subject, email_body) => {
    setIsLoading(true);
    sendCustomEmail(id, email_subject, email_body)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {res.data.msg}
            </span>
          );
        }
        setEmailModel(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.data.msg}
          </span>
        );
        setEmailModel(false);
        setIsLoading(false);
      });
  };

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    ...(type === "Preview"
      ? [
          {
            id: "PublishRequests",
            page: t("PublishRequests"),
            pagePath: `/${localStorage.getItem("type")}/PublishRequests`,
          },
          {
            id: "req_preview",
            page: t("PublishRequestpreview"),
            active: true,
          },
        ]
      : [
          {
            id: "req",
            page: t("course_publish_request"),
            active: true,
          },
        ]),
  ];

  const currentDate = () => {
    let HoursDate = new Date().getHours();
    let MinutesDate = new Date().getMinutes();
    setHoursDatepicker(MinutesDate >= 30 ? HoursDate + 1 : HoursDate);
    setMinutesDatepicker(MinutesDate >= 30 ? 0 : MinutesDate + 10);
  };
  useEffect(() => {
    currentDate();
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getSpecialties()
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setSpecialties(res.data.data.specialties.data);
          if (ReqDetails.speciality.id) {
            setDepartments(
              res.data.data.specialties.data.filter(
                (specialty) => specialty.id === Number(ReqDetails.specialty.id)
              )[0].departments
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ReqDetails]);
  const levelsOptions = [
    {
      value: 1,
      id: 1,
      label: i18n.language == "ar" ? "مبتدئ" : "Beginner",
    },
    {
      id: 2,
      value: 2,
      label: i18n.language == "ar" ? "متوسط" : "Average",
    },
    {
      id: 3,
      value: 3,
      label: i18n.language == "ar" ? "متقدم" : "Advanced",
    },
  ];
  return (
    <div className={classes["create_event"]}>
      {isLoading && <SkeletonCardOverlay />}
      <div className="container-fluid">
        <div className={classes["create_event__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && !loading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      ...(role === "guest"
                        ? {
                            guest_name: ReqDetails?.guest_name,
                            guest_email: ReqDetails?.guest_email || "",
                            guest_mobile: ReqDetails?.guest_mobile || "",
                          }
                        : {}),

                      title: ReqDetails?.title || "",
                      description: ReqDetails?.description || "",
                      objectives: ReqDetails?.objectives || "",
                      speciality_id: ReqDetails?.speciality?.id || "",
                      department_id: ReqDetails?.department || "",
                      duration: ReqDetails?.duration || "",
                      topics: ReqDetails?.topics || "",
                      level: ReqDetails?.level || "",
                      is_priced: ReqDetails?.is_priced || 0,
                      available_from: ReqDetails?.available_from
                        ? getLocaleTime(new Date(ReqDetails?.available_from))
                        : setHours(
                            setMinutes(new Date(), minutesDatepicker),
                            hoursDatepicker
                          ),
                      available_to: ReqDetails?.available_to
                        ? getLocaleTime(new Date(ReqDetails?.available_to))
                        : setHours(
                            setMinutes(new Date(), minutesDatepicker),
                            hoursDatepicker
                          ),
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        let formData = new FormData();
                        Object.keys(values).forEach((field) => {
                          if (field === "file") {
                            values[field].file &&
                              formData.append("image", values[field].file);
                          } else if (
                            field === "available_to" ||
                            field === "available_from"
                          ) {
                            formData.append(
                              `${field}`,

                              toServerTime(
                                formatDate(new Date(values[field])) +
                                  " " +
                                  formatTime(new Date(values[field]))
                              )
                            );
                          } else {
                            formData.append(field, values[field]);
                          }
                        });
                        if (values.has_button === 0) {
                          formData.delete("button_text_en");
                          formData.delete("button_text_ar");
                          formData.delete("url");
                        }
                        await submitHandler(formData);
                        setSubmitting(false);
                      } catch (err) {
                        console.log("err", err);
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err.response.data.errors[key]}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.title) {
                        errors.title = t("crud.errors.required");
                      }
                      if (!values.description) {
                        errors.description = t("crud.errors.required");
                      }

                      if (!values.objectives) {
                        errors.objectives = t("crud.errors.required");
                      }
                      if (!values.speciality_id) {
                        errors.speciality_id = t("crud.errors.required");
                      }

                      if (!values.department_id) {
                        errors.department_id = t("crud.errors.required");
                      }
                      if (role === "guest") {
                        if (!values.guest_name) {
                          errors.guest_name = t("crud.errors.required");
                        }
                        if (!values.guest_email) {
                          errors.guest_email = t("crud.errors.required");
                        }
                        if (!values.guest_mobile) {
                          errors.guest_mobile = t("crud.errors.required");
                        }
                      }
                      if (!values.level) {
                        errors.level = t("crud.errors.required");
                      }

                      if (!values.duration) {
                        errors.duration = t("crud.errors.required");
                      }
                      if (!values.available_from) {
                        errors.available_from = t("crud.errors.required");
                      }
                      if (!values.available_to) {
                        errors.available_to = t("crud.errors.required");
                      }
                      if (!values.duration) {
                        errors.duration = t("crud.errors.required");
                      }
                      if (!values.topics) {
                        errors.topics = t("crud.errors.required");
                      }
                      if (!values.available_from) {
                        errors.available_from = t("crud.errors.required");
                      }

                      if (!values.available_to) {
                        errors.available_to = t("crud.errors.required");
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_event__form"]}
                      >
                        <MainBoxHead title={t("course_publish_request")} />
                        {type === "Preview" && (
                          <div
                            className={`${classes["event__form__submit__container"]} col-12`}
                          >
                            <CustomButton
                              type="button"
                              colors="#036c77"
                              value={t("send_custom_email")}
                              action={() => setEmailModel(true)}
                            />
                          </div>
                        )}

                        <div
                          className={`row ${classes["create_event__form__fields"]}`}
                        >
                          {role === "guest" ? (
                            <>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  required={true}
                                  label={t("guest_name")}
                                  type="text"
                                  name="guest_name"
                                  placeholder={t("guest_name")}
                                  onChange={handleChange}
                                  error={errors.guest_name}
                                  value={values.guest_name}
                                  disabled={type === "Preview"}
                                />
                              </div>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  required={true}
                                  label={t("guest_email")}
                                  type="email"
                                  name="guest_email"
                                  placeholder={t("guest_email")}
                                  onChange={handleChange}
                                  error={errors.guest_email}
                                  value={values.guest_email}
                                  disabled={type === "Preview"}
                                />
                              </div>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              >
                                <Input
                                  required={true}
                                  label={t("guest_mobile")}
                                  type={type === "Preview" ? "text" : "number"}
                                  name="guest_mobile"
                                  placeholder={t("guest_mobile")}
                                  onChange={handleChange}
                                  error={errors.guest_mobile}
                                  value={values.guest_mobile}
                                  disabled={type === "Preview"}
                                  style={{ direction: "ltr" }}
                                />
                              </div>
                              <div
                                className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                              ></div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t(
                                "financial.payments_request.course_title"
                              )}
                              type="text"
                              name="title"
                              placeholder={t("title")}
                              onChange={handleChange}
                              error={errors.title}
                              value={values.title}
                              required={true}
                              disabled={type === "Preview"}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("objectives")}
                              type="text"
                              name="objectives"
                              placeholder={t("objectives")}
                              onChange={handleChange}
                              error={errors.objectives}
                              value={values.objectives}
                              required={true}
                              disabled={type === "Preview"}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description"
                              style={{ color: "#777777" }}
                            >
                              {t(`description`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description"
                              name="description"
                              placeholder={t(`description`)}
                              value={values.description}
                              defaultValue={values.description}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              onChange={handleChange}
                              disabled={type === "Preview"}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description ? errors.description : null}
                            </p>
                          </div>

                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <SelectWithSearch
                              label={t("editProfile.medicalSpecialty")}
                              name={"speciality_id"}
                              onchange={(value) => {
                                setFieldValue("speciality_id", value.value);
                                setFieldValue("department_id", "");
                                setDepartments(
                                  specialties.filter(
                                    (specialty) =>
                                      specialty.id === Number(value.value)
                                  )[0]?.departments
                                );
                              }}
                              options={specialties.map((specialty) => {
                                return {
                                  id: specialty.id,
                                  value: specialty.id,
                                  label:
                                    i18n.language === "ar"
                                      ? specialty.name_ar
                                      : specialty.name_en,
                                };
                              })}
                              value={{
                                id: values.speciality_id,
                                value: values.speciality_id,
                                label:
                                  i18n.language === "ar"
                                    ? specialties.filter(
                                        (specialty) =>
                                          specialty.id ===
                                          Number(values.speciality_id)
                                      )[0]?.name_ar
                                    : specialties.filter(
                                        (specialty) =>
                                          specialty.id ===
                                          Number(values.speciality_id)
                                      )[0]?.name_en,
                              }}
                              error={errors.speciality_id}
                              initialValue={t("editProfile.medicalSpecialty")}
                              disabled={type === "Preview"}
                              required
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <SelectWithSearch
                              label={t("editProfile.department")}
                              name={"department_id"}
                              onchange={(value) => {
                                setFieldValue("department_id", value.value);
                              }}
                              options={departments.map((department) => {
                                return {
                                  id: department.id,
                                  value: department.id,
                                  label: department.name,
                                };
                              })}
                              value={
                                type === "Preview"
                                  ? {
                                      id: values.department_id.id,
                                      value: values.department_id.id,
                                      label: values.department_id.name,
                                    }
                                  : {
                                      id: values.department_id,
                                      value: values.department_id,
                                      label: departments.filter(
                                        (department) =>
                                          department.id ===
                                          Number(values.department_id)
                                      )[0]?.name,
                                    }
                              }
                              error={errors.department_id}
                              initialValue={t("editProfile.department")}
                              disabled={type === "Preview"}
                              required
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              required={true}
                              label={t("duration")}
                              type="number"
                              name="duration"
                              placeholder={t("duration")}
                              onChange={handleChange}
                              error={errors.duration}
                              value={values.duration}
                              disabled={type === "Preview"}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              required={true}
                              label={t("topics")}
                              type="text"
                              name="topics"
                              placeholder={t("topics")}
                              onChange={handleChange}
                              error={errors.topics}
                              value={values.topics}
                              disabled={type === "Preview"}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("available_from")}
                              name="available_from"
                              error={errors.available_from}
                              value={values.available_from}
                              onChange={(date) => {
                                setFieldValue("available_from", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatepicker),
                                hoursDatepicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              required={true}
                              readOnly={type === "Preview"}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.available_from
                                ? errors.available_from
                                : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <MainDatePicker
                              label={t("available_to")}
                              name="available_to"
                              error={errors.available_to}
                              value={values.available_to}
                              onChange={(date) => {
                                setFieldValue("available_to", date);

                                let CurrentDate = new Date();
                                let CurrentDateFormate =
                                  CurrentDate.toISOString().split("T")[0];
                                let DataPickerValue = date
                                  .toISOString()
                                  .split("T")[0];
                                if (
                                  new Date(DataPickerValue).getTime() >
                                  new Date(CurrentDateFormate).getTime()
                                ) {
                                  setHoursDatepicker(0);
                                  setMinutesDatepicker(0);
                                } else {
                                  currentDate();
                                }
                              }}
                              minDate={new Date()}
                              minTime={setHours(
                                setMinutes(new Date(), minutesDatepicker),
                                hoursDatepicker
                              )}
                              maxTime={setHours(setMinutes(new Date(), 30), 23)}
                              required={true}
                              readOnly={type === "Preview"}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.available_to ? errors.available_to : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Select
                              label={t("level")}
                              name="level"
                              onchange={handleChange}
                              options={levelsOptions}
                              initialValue={t("level")}
                              error={errors.level}
                              value={values.level}
                              disabled={type === "Preview"}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <Checkbox
                              name="is_priced"
                              label={t(`is_priced`)}
                              onChange={(event) => {
                                setFieldValue(
                                  "is_priced",
                                  event.target.checked
                                    ? 1
                                    : ReqDetails?.is_priced
                                    ? 1
                                    : 0
                                );
                              }}
                              checked={
                                values.is_priced === true ||
                                values.is_priced === 1
                              }
                              error={errors.is_priced}
                              disabled={type === "Preview"}
                            />
                          </div>

                          <div
                            className={`${classes["event__form__submit__container"]} col-12`}
                          >
                            <CustomButton
                              type="submit"
                              colors="#036c77"
                              value={t("create")}
                              disable={isSubmitting || type === "Preview"}
                              loading={isSubmitting}
                            />
                          </div>
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
      {EmailModel && (
        <ModelWithInput
          openModal={EmailModel}
          setOpenModal={setEmailModel}
          modalTitle={t("send_custom_email")}
          buttonText={t("general.submit")}
          type={"input-textarea"}
          handleSubmit={sendEmailHandler}
          inputLabel={t("email_subject")}
          inputLabeltwo={t("email_body")}
        />
      )}
    </div>
  );
};

export default PublishRequestsForm;
