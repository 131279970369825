import moment from "moment";
import React, { useEffect, useState } from "react";
import classes from "./CalenderNew.module.css";

export default function Daycell(props) {
  const [classtype, setClassName] = useState("");
  useEffect(() => {
    // console.log(props);

    if (props?.events.get(moment(props.date).format("YYYY-MM-DD"))) {
      setClassName("hasEvents");
    }
    if (moment(props.date).month() !== moment(props.displayMonth).month()) {
      setClassName("disabled");
    }
  }, [classtype, props.events]);

  return (
    <div
      className={`${classes[classtype]} ${classes.daycell} ${
        props.selected === moment(props.date).format("YYYY-MM-DD")
          ? classes.selected
          : null
      }`}
      onClick={() => props.setSelected(moment(props.date).format("YYYY-MM-DD"))}
    >
      {moment(props.date).date()}
    </div>
  );
}
