import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import EyeIcon from "../../../assets/icons/eye.svg";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import userCheck from "../../../assets/image/user-check.svg";
import userX from "../../../assets/image/user-x.svg";
import DeleteIcon from "../../../assets/icons/deleteTable.svg";
import DeleteIcon2 from "../../../assets/icons/delete.svg";
import classes from "./PublishRequests.module.css";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import {
  ActiveAdvertisement,
  DeactiveAdvertisement,
  deleteAdvertisement,
} from "../../../Redux/Actions/Advertisements/advertisements.action";
import ModelWithInput from "../../Shared/Components/ModelWithInput/ModelWithInput";
import {
  AcceptReq,
  RejectReq,
} from "../../../Redux/Actions/publishReq/publishReq.action";
import { toast } from "react-toastify";
// import { cancelEvent } from "../../../Redux/Actions/events/events.action";
// import { toast } from "react-toastify";

const PublishRequestsTable = ({ sortAction, currentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reqId, setReqId] = useState(null);
  const [showAcceptModel, setShowAcceptModel] = useState(false);
  const [showRejectModel, setShowRejectModel] = useState(false);

  const acceptHandler = (id) => {
    setShowAcceptModel(true);
    setReqId(id);
  };

  const ConfirmAcceptHandler = () => {
    if (!reqId) return;
    dispatch(AcceptReq(reqId));
    setShowAcceptModel(false);
  };

  const rejectHandler = (id) => {
    setShowRejectModel(true);
    setReqId(id);
  };

  const ConfirmRejectHandler = (reject_reason) => {
    if (!reqId) return;
    dispatch(RejectReq(reqId, reject_reason));
    setShowRejectModel(false);
  };

  const { publishReqsList, publishReqsListLoading } = useSelector(
    (state) => state.publishReqsListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };
  //   useEffect(() => {}, [systemUsersList]);

  const eventsActionsList = [
    {
      id: "view-details",
      icon: <img src={EyeIcon} alt="" />,
      action: (id, name) => {
        localStorage.getItem("type");
        history.push(
          `/${localStorage.getItem("type")}/PublishRequests/${id}/Preview`
        );
      },
      tooltip: t("general.view_details"),
      color: "#5A80FF",
    },

    {
      id: "accept",
      icon: <img src={userCheck} alt="" />,
      action: (id, name) => {
        if (
          name === "Approved" ||
          name === "Rejected" ||
          name === "اعتمدت" ||
          name === "رفضت"
        ) {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("action_made")}
            </span>
          );
        } else {
          acceptHandler(id);
        }
      },
      tooltip: t("general.accept_model_title"),
      color: "#24b3b9",
    },
    {
      id: "reject",
      icon: <img src={userX} alt="" />,
      action: (id, name) => {
        if (
          name === "Approved" ||
          name === "Rejected" ||
          name === "اعتمدت" ||
          name === "رفضت"
        ) {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("action_made")}
            </span>
          );
        } else {
          rejectHandler(id);
        }
      },
      tooltip: t("general.reject_model_title"),
      color: "#b92424",
    },
  ];
  const displayMode = (mode) => {
    if (mode === 0) {
      return t("general.free");
    } else if (mode === 1) {
      return t("general.paid");
    }
  };
  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "user_name",
      name: t("user_name"),
      key: "user_name",
      cell: ({ rowData }) => (
        <div>
          {rowData.user
            ? i18n.language === "ar"
              ? rowData?.user?.name_ar
              : rowData?.user?.name_en
            : rowData?.guest_name}
        </div>
      ),
      sortable: false,
    },
    {
      id: "email",
      name: t("email"),
      key: "email",
      cell: ({ rowData }) => (
        <div className={classes["system-users-list__table-email__wrapper"]}>
          <a
            className={classes["system-users-list__table-email"]}
            href={`mailto: ${rowData?.user?.email}`}
          >
            {rowData.user ? rowData?.user?.email : rowData?.guest_email}
          </a>
        </div>
      ),
      sortable: false,
    },
    {
      id: "mobile",
      name: t("guest_mobile"),
      key: "mobile",
      cell: ({ rowData }) => (
        <div style={{ direction: "ltr" }}>
          {rowData.user ? rowData?.user?.phone : rowData?.guest_mobile}
        </div>
      ),
      sortable: false,
    },
    {
      id: "user_type",
      name: t("user_type"),
      key: "user_type",
      cell: ({ rowData }) => (
        <div>
          {rowData?.user_type
            ? rowData?.user_type
            : rowData?.user?.roles[0].label || "-"}
        </div>
      ),
      sortable: false,
    },
    {
      id: "title",
      name: t("title"),
      key: "title",
      cell: ({ rowData }) => <div>{rowData?.title || "-"}</div>,
      sortable: false,
    },
    // {
    //   id: "description",
    //   name: t("description"),
    //   key: "description",
    //   cell: ({ rowData }) => <div>{rowData?.description || "-"}</div>,
    //   sortable: false,
    // },
    {
      id: "speciality",
      name: t("major"),
      key: "speciality",
      cell: ({ rowData }) => (
        <span
        //   className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
        //   style={{ backgroundColor: `${rowData?.type?.color}` }}
        >
          {i18n.language === "ar"
            ? rowData?.speciality?.name_ar || "-"
            : rowData?.speciality?.name_en || "-"}
        </span>
      ),
      sortable: false,
    },
    {
      id: "department",
      name: t("department"),
      key: "department",
      cell: ({ rowData }) => (
        <span
        //   className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
        //   style={{ backgroundColor: `${rowData?.type?.color}` }}
        >
          {i18n.language === "ar"
            ? rowData?.department?.name_ar || "-"
            : rowData?.department?.name_en || "-"}
        </span>
      ),
      sortable: false,
    },
    {
      id: "duration",
      name: t("duration"),
      key: "duration",
      cell: ({ rowData }) => <div>{rowData?.duration || "-"}</div>,
      sortable: false,
    },
    {
      id: "is_priced",
      name: t("general.type"),
      key: "is_priced",
      cell: ({ rowData }) => (
        <span
          style={{
            color: rowData?.is_priced === 0 ? "#025299" : "#12C689",
          }}
        >
          {displayMode(rowData?.is_priced) || "-"}
        </span>
      ),
      sortable: false,
    },
    {
      id: "level",
      name: t("level"),
      key: "level",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData?.level === 1
                ? "#46BB3E"
                : rowData?.level === 2
                ? "#8599B6"
                : rowData?.course_level?.value === 3
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData?.level === 1
                ? "#C1E2C822"
                : rowData?.level === 2
                ? "#C1CEE222"
                : rowData?.level === 3
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayLevel(rowData?.level) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "available_from",
      name: t("available_from"),
      key: "available_from",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.available_from).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.available_from)}
        </>
      ),
      //   sortable: true,
    },
    {
      id: "available_to",
      name: t("available_to"),
      key: "available_to",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.available_to).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.available_to)}
        </>
      ),
      //   sortable: true,
    },

    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      //   sortable: true,
    },
    {
      id: "status",
      name: t("status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: "#fff",
            backgroundColor:
              rowData?.status === t("rejected")
                ? "rgba(165, 8, 8, 0.685)"
                : rowData?.status === t("new")
                ? "#025299"
                : "#12C689",
          }}
        >
          {rowData?.status || "-"}
        </span>
      ),
      sortable: false,
    },

    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={eventsActionsList}
          id={rowData.id}
          name={rowData.status}
        />
      ),
      sortable: false,
    },
    // {
    //   id: "status",
    //   name: t("general.status"),
    //   key: "status",
    //   cell: ({ rowData }) => (
    //     <SwitchToggle
    //       status={rowData.status === "active" ? true : false}
    //       id={rowData.id}
    //       onStatusChange={() => {
    //         rowData.status === "active"
    //           ? deactiveAdStatusHandler(rowData.id)
    //           : activeAdStatusHandler(rowData.id);
    //       }}
    //     />
    //   ),
    //   sortable: false,
    // },
  ];
  // console.log("systemUsersList", systemUsersList);
  return (
    <>
      <MainTable
        columns={columns}
        data={publishReqsList || []}
        isLoading={publishReqsListLoading}
        sortAction={sortAction}
      />
      {showAcceptModel && (
        <ModelWithInput
          openModal={showAcceptModel}
          setOpenModal={setShowAcceptModel}
          modalTitle={t("Accept_model_req_title")}
          buttonText={t("Accept_button")}
          type="req"
          handleSubmit={ConfirmAcceptHandler}
        />
      )}
      {showRejectModel && (
        <ModelWithInput
          openModal={showRejectModel}
          setOpenModal={setShowRejectModel}
          modalTitle={t("Reject_model_req_title")}
          buttonText={t("general.submit")}
          type={"textarea"}
          handleSubmit={ConfirmRejectHandler}
          inputLabel={t("financial.payments_request.rejected_reason")}
        />
      )}
    </>
  );
};

export default PublishRequestsTable;
