import moment from "moment";
import { useTranslation } from "react-i18next";

import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";

import classes from "./SearchPreview.module.css";
import { Lang } from "../../../../utils";

const SearchPreviewTable = ({
  isProgram,
  isTrainee,
  data,
  loading,
  isManager,
  isTrainer,
  isTraineeCourse,
}) => {
  const { t, i18n } = useTranslation();

  const displayMode = (mode) => {
    if (mode === "Free") {
      return t("general.free");
    } else if (mode === "Paid") {
      return t("general.paid");
    }
  };
  const displayLanguage = (language) => {
    if (language === "ar") {
      return t("arabic");
    } else if (language === "en") {
      return t("english");
    }
  };

  const displayLevel = (level) => {
    if (level === "1") {
      return t("levels.beginner");
    } else if (level === "2") {
      return t("levels.medium");
    } else if (level === "3") {
      return t("levels.advanced");
    }
  };
  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    ...(isTrainer
      ? [
          {
            id: "program_name",
            name: t(`courses_list.program_name`),
            key: "program_name",
            cell: ({ rowData }) => (
              <div className={classes["coupon-list__table-name"]}>
                {rowData?.program_name || "-"}
              </div>
            ),
            sortable: false,
          },
          {
            id: "name",
            name: t(`courses_list.course_name`),
            key: "name",
            cell: ({ rowData }) => (
              <div className={classes["coupon-list__table-name"]}>
                {rowData?.name || "-"}
              </div>
            ),
          },
        ]
      : [
          {
            id: "name",
            name: t(
              `courses_list.${isProgram ? "program_name" : "course_name"}`
            ),
            key: "name",
            cell: ({ rowData }) => (
              <div className={classes["coupon-list__table-name"]}>
                {rowData?.name || "-"}
              </div>
            ),
          },
        ]),

    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },
    {
      id: "code",
      name: t("general.code"),
      key: "code",
      cell: ({ rowData }) => <>{rowData?.code || "-"}</>,
    },
    ...(isProgram
      ? []
      : [
          {
            id: "serial_number",
            name: t("general.serial_number"),
            key: "serial_number",
            cell: ({ rowData }) => <>{rowData?.run_serial_number || "-"}</>,
            sortable: false,
          },
        ]),
    {
      id: "specialty",
      name: t("courses_list.specialty"),
      key: "specialty",
      cell: ({ rowData }) => <>{rowData?.professional_medical_field || "-"} </>,
      sortable: false,
    },
    {
      id: "department",
      name: t("courses_list.department"),
      key: "department",
      cell: ({ rowData }) => <>{rowData?.specialty || "-"} </>,
      sortable: false,
    },
    {
      id: "language",
      name: t("general.language"),
      key: "language",
      cell: ({ rowData }) => (
        <span
          style={{
            color: rowData?.language === "ar" ? "#E62626" : "#12C689",
          }}
        >
          {displayLanguage(rowData?.language) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "level",
      name: t("general.level"),
      key: "level",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color:
              rowData.level === "1"
                ? "#46BB3E"
                : rowData.level === "2"
                ? "#8599B6"
                : rowData.level === "3"
                ? "#E22020"
                : "normal",
            backgroundColor:
              rowData.level === "1"
                ? "#C1E2C822"
                : rowData.level === "2"
                ? "#C1CEE222"
                : rowData.level === "3"
                ? "#E8919122"
                : "normal",
          }}
        >
          {displayLevel(rowData?.level) || "-"}{" "}
        </span>
      ),
      sortable: false,
    },
    {
      id: "number_hours",
      name: t("courses_list.number_hours"),
      key: "number_hours",
      cell: ({ rowData }) => <>{rowData?.number_hours || "-"} </>,
      sortable: false,
    },
    {
      id: "mode",
      name: t("general.type"),
      key: "mode",
      cell: ({ rowData }) => (
        <span
          style={{
            color: rowData?.mode === "Free" ? "#E62626" : "#12C689",
          }}
        >
          {displayMode(rowData?.mode) || "-"}
        </span>
      ),
      sortable: false,
    },
    ...(isTraineeCourse
      ? [
          {
            id: "completion_rate",
            name: t("general.completion_rate"),
            key: "completion_rate",
            cell: ({ rowData }) => (
              <>
                {Number(rowData?.progress_evidences_count) === 0 ||
                Number(rowData?.progress_evidences_count) === null ||
                Number(rowData?.progress_trackers_count) === 0 ||
                Number(rowData?.progress_trackers_count) === null
                  ? 0
                  : Math.round(
                      (rowData?.progress_evidences_count /
                        rowData?.progress_trackers_count) *
                        100
                    )}
                %
              </>
            ),
          },
          {
            id: "watch_progress",
            name: t("general.watch_progress"),
            key: "watch_progress",
            cell: ({ rowData }) => (
              <>
                {Number(rowData?.watch_evidences_count) === 0 ||
                Number(rowData?.watch_evidences_count) === null ||
                Number(rowData?.watch_trackers_count) === 0 ||
                Number(rowData?.watch_trackers_count) === null
                  ? 0
                  : Math.round(
                      (rowData?.watch_evidences_count /
                        rowData?.watch_trackers_count) *
                        100
                    )}
                %
              </>
            ),
          },

          {
            id: "issuing_certificate",
            name: t("general.issuing_certificate"),
            key: "issuing_certificate",
            cell: ({ rowData }) => (
              <div
                className={`${
                  classes[`departments-trainees__table-certificate`]
                } ${
                  classes[
                    `departments-trainees__table-certificate-${
                      rowData?.has_certificate > 0 ? "yes" : "no"
                    }`
                  ]
                }`}
              >
                {rowData?.has_certificate ? t("yes") : t("no")}
              </div>
            ),
          },
        ]
      : []),

    {
      id: "date",
      name: isTrainee
        ? t("general.subscription_date")
        : t("general.enrollment_date"),
      key: "date",
      cell: ({ rowData }) => (
        <>
          {moment(
            isTrainee
              ? rowData?.enrolled_at
              : isManager
              ? rowData?.created_at
              : rowData?.assigned_at
          ).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(
            isTrainee
              ? rowData?.enrolled_at
              : isManager
              ? rowData?.created_at
              : rowData?.assigned_at
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.start_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.start_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.end_date)}
        </>
      ),
      sortable: false,
    },
  ];

  return <MainTable columns={columns} data={data || []} isLoading={loading} />;
};

export default SearchPreviewTable;
