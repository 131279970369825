import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export const getDiscussion = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_discussion, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};
export const addDiscussion = (Payload, id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.add_discussion, { id: id }), Payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const EditDiscussion = (id, Payload) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.update_discussion, { id: id }), Payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

//Get View Discussion.
export const getViewDiscussion = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_view_discussion, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const LikePost = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.post_like_discussion, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const DislikePost = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.post_dislike_discussion, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

//create_post_discussion

export const createPostDiscussion = (id, Payload) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.create_post_discussion, { id: id }), Payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const DeletePost = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_post_discussion, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const reportForComment = (id, payload) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(URLBuilder(env.abuse_report_discussion, { id: id }), payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

// Export lesson discussions comments
export function exportChapterDiscussionComments(id, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.export_chapter_interactive_content, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              export: type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute(
                "download",
                "interActive content" + "." + "pdf"
              );
            } else {
              link.setAttribute(
                "download",
                "interActive content" + "." + "xlsx"
              );
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportLessonDiscussionComments(id, type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          URLBuilder(env.export_lesson_interactive_content, {
            id,
          }),
          {
            responseType: "blob",
            params: {
              export: type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if (type === "pdf") {
              link.setAttribute(
                "download",
                "interActive content" + "." + "pdf"
              );
            } else {
              link.setAttribute(
                "download",
                "interActive content" + "." + "xlsx"
              );
            }
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
