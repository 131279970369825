import LearningAnalysis from "../../Modules/Admin/Analysis/LearningAnalysis";
import Coupons from "../../Modules/Admin/Coupon/Coupons/Coupons";
import AssignUsersToDepartment from "../../Modules/Admin/Department/AssignUsers/AssignUsers";
import EvaluationList from "../../Modules/Admin/Evaluation/EvaluationList/EvaluationList";
import Fund from "../../Modules/Admin/Financial/fund/fund";
import WalletDetails from "../../Modules/Admin/Financial/userWallet/walletDetails/walletDetails";
import PendingTrainers from "../../Modules/Admin/PendingTrainers/PendingTrainers";
import SystemUserEdit from "../../Modules/Admin/SystemUsers/SystemUsersList/SystemUserDetails/SystemUserEdit";
import SystemUserPreview from "../../Modules/Admin/SystemUsers/SystemUsersList/SystemUserDetails/SystemUserPreview";
import SystemUsersList from "../../Modules/Admin/SystemUsers/SystemUsersList/SystemUsersList";
import FAQ from "../../Modules/FAQ_s/FAQ";
import RateTicket from "../../Modules/Shared/Components/Tickets/Rate/Rate";

import ActivitiesStatement from "../../Modules/Admin/ActivitesMarks/Statement";
import AddCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Add";
import EditCourseTeam from "../../Modules/Trainer/Course/CourseTeams/Edit";
import CourseTeamsList from "../../Modules/Trainer/Course/CourseTeams/List";
import CourseContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContent";
import LessonActivityView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonActivityView/LessonActivityView";
import LessonContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonContentView/LessonContentView";
import UnitContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/UnitContentView/UnitContentView";
import Exam from "../../Modules/trainees/Exams/Exam";
import { Activity, PeymentsRequest } from "../trainers";
import {
  AboutUs,
  AboutUsEdit,
  AcrivitiesMark,
  ActiveAccount,
  AddAdvertisement,
  AddCertificateTemplates,
  AddCourse,
  AddEduGoals,
  AddEvents,
  AddFaq,
  AddOffers,
  AddQuestionToQuestionBankWrapper,
  AddSurvey,
  AddTax,
  AddTicket,
  AddTicketsType,
  Advertisements,
  Analysis,
  AssessmentAdd,
  AssessmentAnswers,
  AssessmentEdit,
  AssessmentParticipants,
  AssessmentPreview,
  AssessmentStatistics,
  AssessmentsList,
  AssessmentsStatement,
  AssignTicketTypeToUser,
  BankTransfers,
  CertificateTemplates,
  CouponDetails,
  CourseAnalysis,
  CourseDetails,
  CourseDueReport,
  CourseListTrainee,
  CourseListTrainer,
  CoursePartners,
  CourseProfile,
  CoursesList,
  CreateAccount,
  CreateCoupon,
  CreateDepartment,
  CreateSpecialties,
  Dashboard,
  DepartmentsList,
  DepartmentsTrainees,
  DepartmentsTrainers,
  DetialedReport,
  DiscBoard,
  EditAdvertisement,
  EditCertificateTemplates,
  EditCoupon,
  EditCourse,
  EditDepartment,
  EditEduGoals,
  EditEvents,
  EditFaq,
  EditOffers,
  EditRefundSetting,
  EditRole,
  EditSpecialties,
  EditTax,
  EditTrainer,
  EduGoals,
  Events,
  FaqList,
  GenericNotFound,
  LearningActivityRegisteredList,
  LessonCanvas,
  ListQuestionBank,
  ListSurvey,
  ListTicketTypes,
  ListTickets,
  Logs,
  Offers,
  Payments,
  PercentageTrainersList,
  PeymentRequestsCoursesPrograms,
  PlatformManagement,
  PlatformManagementEdit,
  PlatformManagmentAdd,
  ProgramPaymentList,
  ProgramPaymentListFinancial,
  PublishRequests,
  PublishRequestsPreview,
  QuizAnalysis,
  RatesDetails,
  Refund,
  RefundSetting,
  RefundSettingList,
  ReportFinancial,
  ReportHoursTrainee,
  ReportHoursTrainer,
  ReportTraineeCourses,
  ReportTraineePrograms,
  ReportTrainer,
  Search,
  SearchFree,
  SearchHistory,
  SearchHistoryDetails,
  SendMessage,
  SpecialtiesDetails,
  SpecialtiesList,
  StatisticsSurvey,
  SurveyAnswers,
  SurveyParticipants,
  SurveyQuestions,
  SurveyResponse,
  SurveyView,
  SystemPreferences,
  Tax,
  TermsAndConditions,
  TotalIncomeReport,
  TraieeAnalysis,
  TraineeAnswers,
  TraineeCertificates,
  TraineeChapter,
  TraineeCourseLesson,
  TraineeList,
  TrainerList,
  TrainerRates,
  TypeFileAdd,
  TypeFileEdit,
  TypeFileList,
  UserWallet,
  ViewTicket,
  WebxAddToken,
  WebxListToken,
  WebxUpdateToken,
  addRefundSetting
} from "./";
import PeymentsRequestFilter from "../../Modules/trainees/privateProfile/peymentsRequests/PeymentRequestFilter";
import AnswersReply from "../../Modules/LiveChat/answersReply/AnswersReply";
import UpdateAnswersReply from "../../Modules/LiveChat/answersReply/UpdateAnswersReply";
import SearchPreview from "../../Modules/Admin/Search/SearchPreview/SearchPreview";

const routesTrainingManager = [
  { component: Dashboard, path: "/", exact: true },
  {
    component: () => <Activity isAdmin={true} />,
    path: "/list/activity/:id",
    exact: true,
  },
  {
    component: () => <CertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates",
    exact: true,
  },
  {
    component: () => <PublishRequests />,
    path: "/PublishRequests",
    exact: true,
  },
  {
    component: () => <PlatformManagement />,
    path: "/PlatformManagement",
    exact: true,
  },
  {
    component: () => <PlatformManagmentAdd />,
    path: "/PlatformManagement/add",
    exact: true,
  },
  {
    component: () => <PlatformManagementEdit />,
    path: "/PlatformManagement/:id/edit",
    exact: true,
  },
  {
    component: () => <PublishRequestsPreview />,
    path: "/PublishRequests/:id/Preview",
    exact: true,
  },
  {
    component: () => <Advertisements isAdmin={true} />,
    path: "/advertisements-list",
    exact: true,
  },
  {
    component: () => <AddAdvertisement isAdmin={true} />,
    path: "/advertisements-list/add",
    exact: true,
  },
  {
    component: () => <EditAdvertisement isAdmin={true} />,
    path: "/advertisements-list/:id/edit",
    exact: true,
  },
  {
    component: () => <Events isAdmin={true} />,
    path: "/events",
    exact: true,
  },
  {
    component: () => <AddEvents isAdmin={true} />,
    path: "/events/add",
    exact: true,
  },
  {
    component: () => <EditEvents isAdmin={true} />,
    path: "/events/:id/edit",
    exact: true,
  },
  {
    component: () => <AddCertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/add",
    exact: true,
  },
  {
    component: () => <AddCertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/:id/add",
    exact: true,
  },
  {
    component: () => <CertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/:id",
    exact: true,
  },
  {
    component: () => <EditCertificateTemplates isAdmin={true} />,
    path: "/certificateTemplates/edit/:id",
    exact: true,
  },
  {
    component: () => <TraineeCertificates isAdmin={true} />,
    path: "/trainee/:id/certificates",
    exact: true,
  },
  {
    component: () => <TraineeCertificates isAdmin={true} />,
    path: "/trainee/:id/:source/certificates",
    exact: true,
  },

  {
    component: CreateSpecialties,
    path: "/category/add",
    exact: true,
  },

  {
    component: () => <DetialedReport isAdmin={true} isAll={true} />,
    path: "/all-detailed-report",
    exact: true,
  },
  {
    component: () => <DetialedReport isAdmin={true} />,
    path: "/detailed-report",
    exact: true,
  },
  {
    component: () => <AcrivitiesMark role="manager" />,
    path: "/course/lesson/:id/:activityId/activities-grades",
    exact: true,
  },
  {
    component: () => <ActivitiesStatement isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/activities",
    exact: true,
  },

  {
    component: CreateDepartment,
    path: "/department/add",
    exact: true,
  },
  {
    component: CreateCoupon,
    path: "/coupon/add",
    exact: true,
  },

  {
    component: AddTicketsType,
    path: "/ticket/type/add",
    exact: true,
  },
  {
    component: () => <EditSpecialties role={"manager"} />,
    path: "/category/edit/:id",
    exact: true,
  },
  {
    component: () => <EditDepartment role={"manager"} />,
    path: "/department/edit/:id",
    exact: true,
  },
  {
    component: EditCoupon,
    path: "/coupon/edit/:id",
    exact: true,
  },
  {
    component: CouponDetails,
    path: "/coupon/:id",
    exact: true,
  },
  {
    component: EditRole,
    path: "/role/:id",
    exact: true,
  },
  {
    component: AddTicketsType,
    path: "/ticket/type/edit/:id",
    exact: true,
  },
  {
    component: () => <SpecialtiesList role={"manager"} />,
    path: "/category",
    exact: true,
  },
  {
    component: () => <SpecialtiesDetails role={"manager"} />,
    path: "/category/:id",
    exact: true,
  },
  {
    component: DepartmentsTrainees,
    path: "/category/:id/trainees",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainers isManager={true} />,
    path: "/category/:id/trainers",
    exact: true,
  },
  {
    component: () => <CoursesList role="manager" notInProgram={true} />,
    path: "/courses",
    exact: true,
  },
  {
    component: () => <AddCourse role="admin" />,
    path: "/course/add",
    exact: true,
  },
  {
    component: () => <EditCourse role="manager" />,
    path: "/course/edit/:id",
    exact: true,
  },
  {
    component: () => <CoursesList role="manager" status="Closed" />,
    path: "/courses-history",
    exact: true,
  },
  {
    component: () => <CoursesList isProgram={true} role="manager" status="Closed" />,
    path: "/programs-history",
    exact: true,
  },
  {
    component: CourseDetails,
    path: "/course/preview/:id",
    exact: true,
  },
  {
    component: () => <DiscBoard role="admin" />,
    path: "/course/:courseId/discussion-board/:id",
    exact: true,
  },
  {
    component: CourseProfile,
    path: "/course/profile/:id",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainers isManager={true} />,
    path: "/course/:id/trainers",
    exact: true,
  },
  {
    component: DepartmentsTrainees,
    path: "/course/:id/trainees",
    exact: true,
  },
  {
    component: CoursePartners,
    path: "/course/:id/partners",
    exact: true,
  },
  {
    component: () => <Payments type="course" />,
    path: "/course/:id/trainees-payments",
    exact: true,
  },
  {
    component: PeymentRequestsCoursesPrograms,
    path: "/peyment-requests-courses",
    exact: true,
  },
  {
    component: () => <PeymentRequestsCoursesPrograms isProgram={true} />,
    path: "/peyment-requests-programs",
    exact: true,
  },
  {
    component: () => <Payments type="financial" />,
    path: "/financial-movement",
    exact: true,
  },
  {
    component: () => <CourseProfile isAdmin={true} role={"manager"} />,
    path: "/course/:id",
    exact: true,
  },
  {
    component: (props) => <AddQuestionToQuestionBankWrapper isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/question-bank/add",
    exact: true,
  },
  {
    component: () => <AddQuestionToQuestionBankWrapper editMode={true} isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/question-bank/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListQuestionBank isAdmin={true} role={"manager"} />,
    path: "/course/:courseId/question-bank",
    exact: true,
  },
  {
    component: (props) => <EduGoals isAdmin={true} role={"manager"} />,
    path: "/course/educational-goals/:id",
    exact: true,
  },
  {
    component: (props) => <AddEduGoals isAdmin={true} role={"manager"} />,
    path: "/course/educational-goals/add/:id",
    exact: true,
  },
  {
    component: (props) => <EditEduGoals isAdmin={true} role={"manager"} />,
    path: "/course/educational-goals/edit/:id",
    exact: true,
  },
  {
    component: (props) => <LessonCanvas isAdmin={true} isTrainer={true} />,
    path: "/course/lesson/:id",
    exact: true,
  },
  {
    component: () => <CourseContentView role={"manager"} />,
    path: "/training-courses/:id/content",
    exact: true,
  },
  {
    component: () => <UnitContentView role={"manager"} />,
    path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId",
    exact: true,
  },
  {
    component: () => <LessonContentView role={"manager"} />,
    path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId",
    exact: true,
  },
  {
    component: () => <LessonActivityView role={"manager"} />,
    path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
    exact: true,
  },
  {
    component: () => <CourseContentView courseFromProgram={true} role={"manager"} />,
    path: "/training-courses/:programId/course/:id/content",
    exact: true,
  },
  {
    component: (props) => <Exam {...props} quizable={`chapter`} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/exam/:id",
    exact: true,
  },
  {
    component: (props) => <LessonCanvas role={"manager"} isAdmin={true} isTrainer={true} />,
    path: "/course/:courseId/lesson/:id",
    exact: true,
  },
  {
    component: () => <CoursesList role="manager" isProgram={true} />,
    path: "/programs",
    exact: true,
  },
  {
    component: () => <AddCourse isProgram={true} role="manager" />,
    path: "/program/add",
    exact: true,
  },
  {
    component: () => <EditCourse isProgram={true} role="manager" />,
    path: "/program/edit/:id",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainers isProgram={true} isManager={true} />,
    path: "/program/:id/trainers",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainees isProgram={true} />,
    path: "/program/:id/trainees",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainees programCourses={true} />,
    path: "/program/:id/course/:courseId/trainees",
    exact: true,
  },
  {
    component: () => <CoursePartners isProgram={true} />,
    path: "/program/:id/partners",
    exact: true,
  },
  {
    component: () => <Payments type="program" isProgram={true} />,
    path: "/program/:id/trainees-payments",
    exact: true,
  },
  {
    component: () => <CoursesList role="manager" programCourses={true} />,
    path: "/program/:id/courses",
    exact: true,
  },
  {
    component: () => <ProgramPaymentList isAdmin={true} role={"manager"} />,
    path: "/program/:id/payments",
    exact: true,
  },
  {
    component: () => <DepartmentsTrainers programCourses={true} isManager={true} />,
    path: "/program/:id/course/:courseId/trainers",
    exact: true,
  },
  {
    component: () => <AddCourse programCourses={true} role="manager" />,
    path: "/program/:id/course/add",
    exact: true,
  },
  {
    component: () => <PeymentsRequest role="manager" />,
    path: "/payments-requests",
    exact: true,
  },
  {
    component: () => <EditCourse programCourses={true} role="manager" />,
    path: "/program/:id/course/edit/:courseId",
    exact: true,
  },
  {
    component: () => <CourseTeamsList isManager={true} role={"manager"} />,
    path: "/course/:courseId/teams",
    exact: true,
  },
  {
    component: () => <AddCourseTeam isManager={true} role={"manager"} />,
    path: "/course/:courseId/teams/add",
    exact: true,
  },
  {
    component: () => <EditCourseTeam isManager={true} role={"manager"} />,
    path: "/course/:courseId/teams/:id/edit",
    exact: true,
  },
  {
    component: () => <DepartmentsList role="manager" />,
    path: "/department",
    exact: true,
  },
  {
    component: () => <SystemUsersList role="manager" />,
    path: "/roles",
    exact: true,
  },
  {
    component: PendingTrainers,
    path: "/pendings-trainers",
    exact: true,
  },
  {
    component: SystemUserPreview,
    path: "/roles/:id",
    exact: true,
  },
  {
    component: SystemUserEdit,
    path: "/roles/edit/:id",
    exact: true,
  },
  {
    component: EvaluationList,
    path: "/evaluation",
    exact: true,
  },
  {
    component: SendMessage,
    path: "/message/send",
    exact: true,
  },
  {
    component: ListTicketTypes,
    path: "/ticket/types",
    exact: true,
  },
  {
    component: AnswersReply,
    path: "/livechat/answers-reply",
    exact: true,
  },
  {
    component: AboutUs,
    path: "/about-us",
    exact: true,
  },
  {
    component: AboutUsEdit,
    path: "/about-us-edit",
    exact: true,
  },
  {
    component: ActiveAccount,
    path: "/active-account",
    exact: true,
  },
  {
    component: () => <CreateAccount role={"manager"} />,
    path: "/create-account",
    exact: true,
  },
  {
    component: BankTransfers,
    path: "/bank-transfers",
    exact: false,
  },
  {
    component: GenericNotFound,
    path: "/page-not-found",
    exact: false,
  },
  {
    component: Coupons,
    path: "/coupon",
    exact: true,
  },

  {
    component: AssignTicketTypeToUser,
    path: "/ticket/type/assign/:id",
    exact: true,
  },
  {
    component: AddTicket,
    path: "/ticket/add",
    exact: true,
  },
  {
    component: ViewTicket,
    path: "/ticket/view/:id",
    exact: true,
  },
  {
    component: ListTickets,
    path: "/tickets",
    exact: true,
  },
  {
    component: SystemPreferences,
    path: "/preferences",
    exact: true,
  },
  {
    component: CourseListTrainee,
    path: "/course/trainee/list",
    exact: true,
  },
  {
    component: CourseListTrainer,
    path: "/course/trainer/list",
    exact: true,
  },
  {
    component: TrainerList,
    path: "/course/trainer/add/:id",
    exact: true,
  },
  {
    component: TraineeList,
    path: "/course/trainee/add/:id",
    exact: true,
  },
  {
    component: UpdateAnswersReply,
    path: "/livechat/create-answers-reply",
    exact: true,
  },
  {
    component: UpdateAnswersReply,
    path: "/livechat/edit-answers-reply/:id",
    exact: true,
  },
  {
    component: AddFaq,
    path: "/add-faq",
    exact: true,
  },
  {
    component: FaqList,
    path: "/faq-list",
    exact: true,
  },
  {
    component: FAQ,
    path: "/faq",
    exact: true,
  },
  {
    component: EditFaq,
    path: "/edit-faq/:id",
    exact: true,
  },
  {
    component: RateTicket,
    path: "/ticket/rate/:id",
    exact: true,
  },
  {
    component: AssignUsersToDepartment,
    path: "/department/:id/assign/users",
    exact: true,
  },
  {
    component: TypeFileList,
    path: "/typefile/list",
    exact: true,
  },
  {
    component: TypeFileEdit,
    path: "/typefile/edit/:id",
    exact: true,
  },
  {
    component: TypeFileAdd,
    path: "/typefile/add",
    exact: true,
  },
  {
    component: ReportTrainer,
    path: "/report/trainer/list",
    exact: true,
  },
  {
    component: ReportHoursTrainer,
    path: "/report/trainer/hours/list",
    exact: true,
  },
  {
    component: ReportTraineeCourses,
    path: "/report/trainee-courses/list",
    exact: true,
  },
  {
    component: ReportTraineePrograms,
    path: "/report/trainee-programs/list",
    exact: true,
  },
  {
    component: ReportHoursTrainee,
    path: "/report/trainee/hours/list",
    exact: true,
  },
  {
    component: ReportFinancial,
    path: "/financial/report",
    exact: true,
  },
  {
    component: TotalIncomeReport,
    path: "/financial/total-income-report",
    exact: true,
  },
  {
    component: CourseDueReport,
    path: "/financial/course-due-report",
    exact: true,
  },
  {
    component: Offers,
    path: "/offer",
    exact: true,
  },
  {
    component: AddOffers,
    path: "/offer/add",
    exact: true,
  },
  {
    component: EditOffers,
    path: "/offer/edit/:id",
    exact: true,
  },
  {
    component: WebxAddToken,
    path: "/webx/add-token",
    exact: true,
  },
  {
    component: WebxListToken,
    path: "/teams/list",
    exact: true,
  },
  {
    component: WebxUpdateToken,
    path: "/teams/update/:id",
    exact: true,
  },
  {
    component: Tax,
    path: "/tax",
    exact: true,
  },
  {
    component: AddTax,
    path: "/tax/add",
    exact: true,
  },
  {
    component: EditTax,
    path: "/tax/edit/:id",
    exact: true,
  },
  {
    component: Refund,
    path: "/refund",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} fromTrainer={true} role={"manager"} />,
    path: "/survey/trainer/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} role={"manager"} />,
    path: "/survey/list",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/survey/list",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/add",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <AddSurvey {...props} role={"manager"} />,
    path: "/course/:targetCourseId/lesson/:targetLessonId/survey/edit/:id",
    exact: true,
  },
  {
    component: (props) => <ListSurvey {...props} fromTrainer={true} role="manager" />,
    path: "/survey/trainer/list",
    exact: true,
  },
  {
    component: (props) => <SurveyParticipants role="manager" />,
    path: "/survey/:id/participants",
    exact: true,
  },
  {
    component: (props) => <SurveyParticipants role="manager" {...props} fromTrainer={true} />,
    path: "/survey/trainer/:id/participants",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse />,
    path: "/survey/:id/participants/:responseId",
    exact: true,
  },
  {
    component: (props) => <SurveyResponse {...props} fromTrainer={true} />,
    path: "/survey/trainer/:id/participants/:responseId",
    exact: true,
  },
  {
    component: (props) => <SurveyView role={"manager"} preview={true} editor={true} />,
    path: "/survey/preview/:id",
    exact: true,
  },
  {
    component: () => <CoursesList role="manager" isProgram={true} />,
    path: "/program/list",
    exact: true,
  },
  {
    component: (props) => <SurveyView role={"manager"} preview={true} editor={true} />,
    path: "/course/:targetCourseId/survey/preview/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyView role={"manager"} preview={true} editor={true} />,
    path: "/survey/trainer/preview/:id",
    exact: true,
  },
  {
    component: (props) => <SurveyView role={"manager"} preview={true} editor={true} fromTrainer={true} />,
    path: "/course/:targetCourseId/survey/trainer/preview/:id",
    exact: true,
  },

  {
    component: TraineeAnswers,
    path: "/survey/trainee-trainer/answers",
    exact: true,
  },
  {
    component: StatisticsSurvey,
    path: "/survey/statistics/:id",
    exact: true,
  },
  {
    component: RefundSetting,
    path: "/refund-setting",
    exact: true,
  },
  {
    component: ProgramPaymentListFinancial,
    path: "/program-payment-date",
    exact: true,
  },
  {
    component: Analysis,
    path: "/analysis",
    exact: true,
  },
  {
    component: UserWallet,
    path: "/users-wallet",
    exact: true,
  },
  {
    component: Fund,
    path: "/fund",
    exact: true,
  },
  {
    component: WalletDetails,
    path: "/users-wallet/details/:id",
    exact: true,
  },
  {
    component: Logs,
    path: "/logs",
    exact: true,
  },
  {
    component: TraieeAnalysis,
    path: "/trainee-analysis",
    exact: true,
  },
  {
    component: TraineeCourseLesson,
    path: "/trainee-analysis/:userId/:courseId",
    exact: true,
  },
  {
    component: TraineeChapter,
    path: "/trainee-chapter-analysis",
    exact: true,
  },

  {
    component: () => <TrainerRates role={"manager"} />,
    path: "/trainer-rate/list",
    exact: true,
  },
  {
    component: PercentageTrainersList,
    path: "/percentage-trainer/list",
    exact: true,
  },
  {
    component: EditTrainer,
    path: "/percentage-trainer/edit",
    exact: true,
  },

  {
    component: CourseAnalysis,
    path: "/course-analysis",
    exact: true,
  },
  {
    component: QuizAnalysis,
    path: "/course-analysis/:id/quiz-analysis/:quizId",
    exact: true,
  },
  {
    component: RefundSettingList,
    path: "/refund-setting/list",
    exact: true,
  },
  {
    component: addRefundSetting,
    path: "/refund-setting/add",
    exact: true,
  },
  {
    component: EditRefundSetting,
    path: "/refund-setting/edit/:id",
    exact: true,
  },

  {
    component: SurveyQuestions,
    path: "/survey/:id/questions",
    exact: true,
  },
  {
    component: (props) => <SurveyQuestions {...props} fromTrainer />,
    path: "/survey/trainer/:id/questions",
    exact: true,
  },
  {
    component: SurveyAnswers,
    path: "/survey/:surveyId/questions/:questionId/answers",
    exact: true,
  },
  {
    component: RatesDetails,
    path: "/trainer-rate/list/:id/course/:courseId",
    exact: true,
  },
  {
    component: (props) => <LearningAnalysis {...props} role={"manager"} />,
    path: "/learning-analysis",
    exact: true,
  },
  {
    component: LearningActivityRegisteredList,
    path: "/learning-analysis/registered/:courseId?",
    exact: true,
  },
  {
    component: TermsAndConditions,
    path: "/terms-and-conditions",
    exact: true,
  },
  {
    component: Search,
    path: "/search",
    exact: true,
  },
  {
    component: SearchPreview,
    path: "/search/preview/:id",
    exact: true,
  },
  {
    component: SearchHistory,
    path: "/search/history",
    exact: true,
  },
  {
    component: SearchHistoryDetails,
    path: "/search/history/:id",
    exact: true,
  },
  {
    component: () => <SearchFree role={"manager"} />,
    path: "/search/free",
    exact: true,
  },
  {
    component: () => <AssessmentsList quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsList quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsStatement isAdmin={true} />,
    path: "/course/:courseId/assessments",
    exact: true,
  },
  {
    component: () => <AssessmentsStatement isAdmin={true} />,
    path: "/course/:courseId/assessments",
    exact: true,
  },

  {
    component: () => <EvaluationList quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <EvaluationList quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/evaluation",
    exact: true,
  },
  {
    component: () => <AssessmentAdd quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => <AssessmentAdd quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/add",
    exact: true,
  },
  {
    component: () => <AssessmentParticipants quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => <AssessmentParticipants quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
    exact: true,
  },
  {
    component: () => <AssessmentAnswers quizable={"chapter"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => <AssessmentAnswers quizable={"lesson"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
    exact: true,
  },
  {
    component: () => <AssessmentStatistics quizable={"chapter"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => <AssessmentStatistics quizable={"lesson"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
    exact: true,
  },
  {
    component: () => <AssessmentPreview quizable={"chapter"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => <AssessmentPreview quizable={"lesson"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/:quizId",
    exact: true,
  },
  {
    component: () => <AssessmentEdit quizable={"chapter"} role={"manager"} />,
    path: "/course/:courseId/chapter/:quizableId/assessment/edit/:id",
    exact: true,
  },
  {
    component: () => <AssessmentEdit quizable={"lesson"} role={"manager"} />,
    path: "/course/:courseId/lesson/:quizableId/assessment/edit/:id",
    exact: true,
  },
];

export default routesTrainingManager;
