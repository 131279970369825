import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CommentIcon from "@mui/icons-material/Comment";
import ScaleIcon from "@mui/icons-material/Scale";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import AddActivityToLessonModal from "../../../../../../../Modals/AddActivityToLessonModal/AddActivityToLessonModal";
import EditActivityToLessonModal from "../../../../../../../Modals/EditActivityToLessonModal/EditActivityToLessonModal";
import { LessonContentContext } from "../../../../../../../Providers/LessonContentProvider";
import DeleteIconModal from "../../../../../../../assets/icons/delete.svg";
import CourseDetailsHeader from "../../../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import SharedModal from "../../../../../../Shared/Components/SharedModal/SharedModal";
import { ActivitiesActions } from "./activitiesActions";

export default function ActivityItem({
  activity,
  handleCloseModal,
  isAdmin,
  isTrainer,
  isManager,
  isPartner,
}) {
  require("./List.css");
  const [opened, setOpened] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const lessonContentContext = useContext(LessonContentContext);
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  const onDeleteFile = async (targetId) => {
    try {
      lessonContentContext.deleteActivity(targetId).then(() => {
        lessonContentContext.fetchLessonContent(id);
      });
      lessonContentContext.fetchLessonActivities(id);
      // lessonContentContext.fetchLessonContent(id);
      lessonContentContext.setIsModalOpen(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("trainer.course.lesson.failed_deleting_content")}
        </span>
      );
    }
  };
  const handleOpenDeleteModal = (event, targetId) => {
    event.stopPropagation();
    event.preventDefault();
    lessonContentContext.setTargetModalContent(deleteModal(targetId));
    lessonContentContext.setIsModalOpen(true);
  };

  const deleteModal = (targetId) => {
    return (
      <SharedModal
        icon={DeleteIconModal}
        title={t("modal.trainer.course.lesson.material.delete.title")}
        confirmMsg={t("modal.trainer.course.lesson.material.delete.body")}
        confirmText={t("general.confirm")}
        cancelText={t("general.cancel")}
        onCancel={handleCloseModal}
        onConfirm={() => onDeleteFile(targetId)}
        isLoading={false}
      />
    );
  };

  return (
    <div
      key={activity.id}
      className={`tajah-lesson-activity-list-item ${opened ? "opened" : ""}`}
    >
      <CourseDetailsHeader
        title={activity.name}
        actions={ActivitiesActions({
          viewAction: () =>
            history.push(
              localStorage.getItem("type") === "group-admin"
                ? `/group-admin/list/activity/${activity.id}`
                : localStorage.getItem("type") === "manager"
                ? `/manager/list/activity/${activity.id}`
                : localStorage.getItem("type") === "content_developer"
                ? `/content_developer/list/activity/${activity.id}`
                : isAdmin
                ? `/admin/list/activity/${activity.id}`
                : isTrainer
                ? `/trainer/list/activity/${activity.id}`
                : isManager
                ? `/manager/list/activity/${activity.id}`
                : isPartner
                ? `/partner/list/activity/${activity.id}`
                : `/list/activity/${activity.id}`
            ),
          ...(!isPartner && {
            editAction: () => {
              lessonContentContext.setTargetModalContent(
                // <EditActivityToLessonModal
                //   dismissModal={handleCloseModal}
                //   title={t(`trainer.course.lesson.activity.edit`)}
                //   activity={activity}
                // />
                <AddActivityToLessonModal
                  dismissModal={handleCloseModal}
                  title={t(`trainer.course.lesson.activity.edit`)}
                  activity={activity}
                />
              );
              lessonContentContext.setIsModalOpen(true);
            },
            deleteAction: (e) => {
              handleOpenDeleteModal(e, activity.id);
            },
          }),
        })}
        setOpened={setOpened}
        opened={opened}
      />
      {opened && (
        <div className={"tajah-activity-details-canvas"}>
          <div className={"tajah-activity-details-row"}>
            <CheckCircleIcon
              sx={{
                color: activity.interactions_agree ? "green" : "#9B9B9B",
              }}
            />
            <div className={"tajah-activity-details-row-text"}>
              {t("trainer.course.lesson.activity.require_approval")}
            </div>
          </div>

          <div className={"tajah-activity-details-row"}>
            <CommentIcon />
            <div className={"tajah-activity-details-row-text"}>
              {t("trainer.course.lesson.activity.contributions")} -{" "}
              {activity?.interactions_num}
            </div>
          </div>

          <div className={"tajah-activity-details-row"}>
            <ScaleIcon />
            <div className={"tajah-activity-details-row-text"}>
              {t("trainer.course.lesson.activity.max_size")}{" "}
              {activity.file_size}
              <span style={{ fontWeight: "bold" }}>KB</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
