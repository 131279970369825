import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  exportSurveysStatisticsQuestions,
  getSurveysStatisticsQuestions,
} from "../../../../Services/api/survey/SurveyProvider";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar-icon.svg";
import HomeIcon from "../../../../assets/icons/home.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import { ReactComponent as UserGroupIcon } from "../../../../assets/icons/user-group.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import { smileyFaces } from "../QuestionTypes";
import IconsTooltip from "./../../../../Components/tooltips/IconsTooltip";
import i18n from "./../../../../i18n/i18n";
import { Lang } from "./../../../../utils";

const colors = [
  "#2e3e5f",
  "#24b3b9",
  "#8599b6",
  "#845EC2",
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#2C73D2",
  "#008E9B",
  "#008F7A",
  "#B39CD0",
  "#FBEAFF",
  "#C34A36",
];

const pickColor = (index) => {
  if (colors.length > index) {
    return colors[index];
  } else {
    return "#FCF8FF";
  }
};

function SurveyQuestions({ isPublic, role, fromTrainer = false }) {
  require("./list.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [survey, setSurvey] = useState({});
  const [questions, setQuestions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [yList, setYList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const { id } = useParams();

  const getOptions = (question, chart) => {
    return {
      indexAxis: yList.includes(question.id) ? "y" : "x",
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: yList.includes(question.id) ? "right" : "bottom",
          // display: chart.datasets.length > 1,
        },
        title: {
          display: question.charts.length > 1,
          text: chart.label,
          // ? `${question.name} (${chart.label})`
          // : `${question.name}`,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: chart.labels.length > 1 && yList.includes(question.id),
          ticks: {
            precision: 0,
          },
          grid: {
            // borderWidth: 0,
            borderWidth: "rgba(0, 0, 0, 0)",
          },
        },
        y: {
          stacked: chart.labels.length > 1 && yList.includes(question.id),
          ticks: {
            precision: 0,
            // mirror: true,
          },
          grid: {
            // borderWidth: 0,
            borderWidth: "rgba(0, 0, 0, 0)",
          },
        },
      },
    };
  };

  const encode3DQuestion = (question) => {
    var charts = [];
    question.options.map((option) => {
      var labels = [];
      var datasets = [];
      // get labels
      question.items.map((item) => {
        labels.push(item.name);
      });
      // get datasets
      question.item_options.map((itemOption, index) => {
        datasets.push({
          label: itemOption.name,
          backgroundColor: pickColor(index),
          data: question.items.map((item) => {
            return question.responses.filter(
              (response) =>
                response.survey_question_item_id == item.id &&
                response.survey_question_option_id == option.id &&
                response.value == itemOption.id
            ).length;
          }),
        });
      });
      charts.push({
        label: option.value,
        datasets,
        labels,
      });
    });

    return charts;
  };

  const encode2DQuestions = (question) => {
    var charts = [];
    var datasets = [];
    var labels = [];
    question.items.map((item) => {
      labels.push(item.name);
    });
    question.options.map((option, index) => {
      datasets.push({
        label: option.value,
        backgroundColor: pickColor(index),
        data: question.items.map((item) => {
          return question.responses.filter(
            (response) =>
              response.survey_question_item_id == item.id &&
              response.survey_question_option_id == option.id
          ).length;
        }),
      });
    });

    charts.push({
      label: "",
      datasets,
      labels,
    });

    return charts;
  };

  const encode1DQuestion = (question) => {
    var charts = [];
    var labels = [];
    var data = [];
    var datasets = [];

    // prepare labels
    question.options.map((option, index) => {
      if (question.type == "graphical_rating") {
        labels.push(index + 1);
      } else {
        labels.push(option.value);
      }
    });
    // datasets
    if (["ordering", "rank_order", "drag_and_drop"].includes(question.type)) {
      // prepare datasets for ordering values
      for (var i = 0; i < question.options.length; i++) {
        datasets.push({
          label: i + 1,
          backgroundColor: pickColor(i),
          data: question.options.map((option) => {
            return question.responses.filter(
              (response) =>
                response.survey_question_option_id == option.id &&
                response.value == i
            ).length;
          }),
        });
      }
      charts.push({
        label: "",
        datasets,
        labels,
      });
    } else {
      question.options.map((option) => {
        if (["star_rating", "smiley_rating"].includes(question.type)) {
          data.push(
            question.responses.filter(
              (response) => parseInt(response.value) == option.id
            ).length
          );
        } else {
          data.push(
            question.responses.filter(
              (response) => response.survey_question_option_id == option.id
            ).length
          );
        }
      });

      charts.push({
        label: "",
        datasets: [
          {
            label: t("survey.statistics.selectionTimes"),
            backgroundColor: pickColor(0),
            data,
          },
        ],
        labels,
      });
    }
    return charts;
  };

  useEffect(() => {
    var mappedQuestions = [];
    var mappedCountries = [];
    getSurveysStatisticsQuestions(id)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setSurvey(res.data.survey);
          res.data.statistics
            .filter((item) => item.type != "contact_information")
            .map((question) => {
              var charts = [];
              if (question.item_options?.length) {
                // item options based questions
                charts = encode3DQuestion(question);
              } else if (question.items?.length) {
                // item based questions
                charts = encode2DQuestions(question);
              } else if (question.options?.length) {
                // options based questions
                charts = encode1DQuestion(question);
              } else if (
                ["star_rating", "smiley_rating"].includes(question.type)
              ) {
                question.options = [...Array(5).keys()].map((i, index) => ({
                  id: index + 1,
                  value:
                    question.type == "smiley_rating"
                      ? smileyFaces(t)[index]?.name
                      : index + 1,
                }));
                charts = encode1DQuestion(question);
              }
              // append the mapped question
              if (charts.length) {
                mappedQuestions.push({
                  id: question.id,
                  name: question.name,
                  type: question.type,
                  is_required: question.is_required,
                  responses: question.responses,
                  charts,
                });
              }
            });

          res.data.country_statistics.map((country) => {
            mappedCountries.push({
              name: country.name,
              count: Number(country.count),
            });
          });
          setQuestions(mappedQuestions);
          setCountries(mappedCountries);

          setIsLoading(false);
        }
      })
      .catch((err) => {});
  }, []);

  const forRole = survey?.roles
    ? survey.roles.length == 1
      ? survey.roles[0].name
      : survey.roles.length == 0
      ? "all"
      : "trainerAndTrainee"
    : "";

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: t("survey.title"),
      page: t("survey.title"),
      pagePath: `/${role}/course/${survey.course_id}/survey/list`,
    },
    {
      id: id,
      page: survey?.name,
      pagePath: `/${role}/course/${survey.course_id}/survey/preview/${id}`,
    },
    {
      id: 1,
      page: t(`analysis.questions`),
      active: true,
    },
  ];

  function exportFile(type) {
    setIsUpdating(true);
    exportSurveysStatisticsQuestions(id, type)
      .then((res) => {
        if (res.status && res.status === 200) {
          setIsUpdating(false);
          toast.success(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("export_success")}
            </span>
          );
        } else {
          throw res;
        }
      })
      .catch((err) => {
        setIsUpdating(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("export_faild")}
          </span>
        );
      });
  }

  return (
    <div className="container-fluid" id={"content-section"}>
      <div className="row">
        <div className="col-12 sm:tw-px-2 tw-py-8">
          {/*TODO*/}
          <Breadcrumb list={breadcrumbList} />
        </div>
      </div>
      {/* <Bar data={{ labels: ['f', 'e'], datasets: [{data: [5, 6]}] }} /> */}
      {isUpdating ? <SkeletonCardOverlay skeletonWidth="100" /> : <div></div>}
      {isLoading ? (
        <div className="mt-5" style={{ height: "65vh" }}>
          <SkeletonCard />
        </div>
      ) : (
        <div className="tw-bg-white tw-shadow tw-rounded tw-p-8 tw-space-y-10 tw-mb-8">
          <div className="md:tw-flex tw-items-center tw-justify-between">
            <div className="md:tw-flex tw-items-center tw-justify-center tw-space-s-4">
              <div className="tw-space-y-2 tw-py-2">
                <div className="sm:tw-flex tw-items-center tw-space-s-2">
                  <div className="tw-text-lg sm:tw-text-3xl tw-text-teal-700">
                    {survey.name}
                  </div>
                </div>
                <div className="tw-space-y-2 sm:tw-flex sm:tw-items-center sm:tw-space-s-4 sm:tw-space-y-0 tw-text-teal-500">
                  {survey.course_id ||
                  survey.lesson_id ||
                  survey.exhibition_id ? (
                    <></>
                  ) : (
                    <div className="tw-flex tw-items-center tw-space-s-2">
                      <CalendarIcon className="tw-h-4" />
                      <div>{`${new Date(
                        survey.start_time
                      ).toLocaleDateString()} ${t("to")} ${new Date(
                        survey.end_time
                      ).toLocaleDateString()}`}</div>
                    </div>
                  )}
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <UserGroupIcon className="tw-h-4" />
                    <div>
                      {t(`addressed`)} {t(`survey.select.option.${forRole}`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-space-s-6">
              <button
                type="button"
                onClick={() => exportFile("pdf")}
                className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#b91414] tw-border-none"
              >
                <img src={PdfIcon} alt="" />
              </button>
              <button
                type="button"
                onClick={() => exportFile("xlsx")}
                className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded tw-bg-[#138d5f] tw-border-none"
              >
                <img src={XlsIcon} alt="" />
              </button>
            </div>
          </div>
          <>
            {questions.map((question) => {
              return (
                <div className="tw-border !tw-border-gray-200 tw-divide-y tw-divide-gray-200 tw-rounded">
                  {
                    question.charts.length ? (
                      <>
                        <div className="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-between">
                          <div className="tw-flex tw-items-center tw-space-s-2">
                            <div className="tw-flex tw-items-center tw-space-s-2 tw-font-bold tw-text-gray-700">
                              <div>{`${question.name} (${t(
                                `survey_types.${question.type}`
                              )} - ${
                                question.is_required
                                  ? t("survey.required")
                                  : t("survey.optional")
                              })`}</div>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              let temp = [...yList];
                              const ind = temp.findIndex(
                                (i) => i == question.id
                              );
                              if (ind < 0) {
                                temp.push(question.id);
                              } else {
                                temp.splice(ind, 1);
                              }
                              setYList(temp);
                            }}
                            className={`fal fa-poll tw-p-1 tw-rounded tw-bg-gray-600 tw-text-white tw-transition-all tw-duration-500 ${
                              yList.includes(question.id) ? "" : "tw-rotate-90"
                            }`}
                          ></button>
                        </div>
                        <div className="tw-py-2 tw-px-4">
                          {question.charts.map((chart, index) => {
                            return (
                              <div className="tw-h-60">
                                <Bar
                                  options={getOptions(question, chart)}
                                  data={{
                                    labels: chart.labels,
                                    datasets: chart.datasets.map((dataset) => ({
                                      ...dataset,
                                      maxBarThickness: 120,
                                      grouped: true,
                                    })),
                                  }}
                                  // height={yList.includes(question.id) ? 15 * chart.labels.length * chart.labels.length : 100 }
                                  key={`question-${question.id}-bar-${index}`}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null
                    // question.responses.map( response => {
                    //   return <div>
                    //     <span>{response.created_at}: </span>{response.value}
                    //   </div>
                    // })
                  }
                </div>
              );
            })}
            {countries.length ? (
              <div className="tw-border !tw-border-gray-200 tw-divide-y tw-divide-gray-200 tw-rounded tw-mt-4">
                <div className="tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-between">
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-flex tw-items-center tw-space-s-2 tw-font-bold tw-text-gray-700">
                      <div>{t("survey.statistics.countries")}</div>
                    </div>
                  </div>
                </div>
                {/* <div className="tw-py-2 tw-px-4">
                  <div className="tw-flex tw-items-center tw-space-s-2">
                    <div className="tw-flex tw-items-center tw-space-s-2 tw-font-bold tw-text-gray-700">
                      <div>{t("survey.statistics.countries")}</div>
                    </div>
                  </div>
                  <div className="tw-space-y-2 tw-py-2 tw-px-4">
                    {countries.map((country, index) => {
                      return (
                        <div className="tw-flex tw-items-center tw-space-s-2">
                          <div className="tw-flex tw-items-center tw-space-s-2">
                            <div>{country.name}</div>
                            <div className="tw-text-gray-500">{country.count}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div> */}
                <div className="tw-h-60">
                  <Pie
                    data={{
                      labels: countries.map((country) => country.name),
                      datasets: [
                        {
                          data: countries.map((country) => country.count),
                          backgroundColor: countries.map((country, index) =>
                            pickColor(index)
                          ),
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        title: {
                          display: false,
                          text: t("survey.statistics.countries"),
                        },
                        tooltip: {
                          callbacks: {
                            label: function (context) {
                              var label = context.label || "";
                              if (context.parsed) {
                                label += `: ${context.parsed} (${(
                                  (context.parsed /
                                    context.dataset.data.reduce(
                                      (a, b) => a + b
                                    )) *
                                  100
                                ).toFixed(2)}%)`;
                              }
                              return label;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            ) : null}
          </>
        </div>
      )}
    </div>
  );
}

export default SurveyQuestions;
