import React, { useEffect, useRef, useState } from "react";
import HomeIcon from "../../assets/icons/home.svg";
import Breadcrumb from "../Shared/Components/Breadcrumb/Breadcrumb";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFaqGeneralList } from "../../Redux/Actions/faq/faq.action";
import NoResult from "../Shared/Components/NoResult/NoResult";
import SkeletonCard from "../Shared/Components/Spinner/SkeletonCard";
import classes from "./FAQ.module.css";
import "./index.css";

const FAQUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { faqGeneral, loading } = useSelector((state) => state.faqReducer);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "FQA",
      page: t("FAQ.common_questions"),
      active: true,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const [data, setData] = useState([]);
  const [catActiveIndexes, setCatActiveIndexes] = useState(0);

  useEffect(() => {
    dispatch(getFaqGeneralList());
  }, []);

  useEffect(() => {
    setData(faqGeneral);
  }, [faqGeneral]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleCatAccordion = (index, fromAdd) => {
    setCatActiveIndexes((prevIndex) =>
      prevIndex === index ? (fromAdd ? index : null) : index
    );
  };

  return (
    <div className="container-fluid">
      <div className={"breadcrumbs-my-30"}>
        <Breadcrumb list={breadcrumbList} />
      </div>

      <div className="">
        <div className={`header-faq ${classes["faq-container"]}`}>
          <h3>{t("FAQ.frequently_asked_questions_groups")}</h3>
        </div>

        {loading ? (
          <div
            className={classes["faq-container"]}
            style={{
              height: "34.5vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : data.length > 0 ? (
          data.map((faq, index) => {
            return (
              <>
                {faq.faqs.length > 0 && (
                  <div className={classes["faq-container"]}>
                    <div
                      className="header-faq-end-user"
                      onClick={() => toggleCatAccordion(index)}
                    >
                      <h3>{faq.title}</h3>
                      <div
                        style={{
                          color: "#2e6a75",
                        }}
                      >
                        {catActiveIndexes === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </div>

                    {catActiveIndexes === index && (
                      <div className="pt-30">
                        {faq.faqs.map((ques, index) => (
                          <div
                            className={`faq-item ${
                              activeIndex === ques.id ? "active_faq" : ""
                            }`}
                            key={faq.id}
                          >
                            <div
                              className="faq-question"
                              onClick={() => toggleAccordion(ques.id)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ fontSize: "18px" }}>
                                  {ques.question}
                                </p>
                              </div>

                              <div className="d-flex-icons-faq">
                                {activeIndex === ques.id ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </div>
                            </div>
                            {activeIndex === ques.id && (
                              <div className="faq-answer">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ques.answer,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })
        ) : (
          <div className={classes["faq-container"]}>
            <NoResult value={t("no_record")} classes={classes.notfound} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQUser;
