import { Box, CircularProgress } from "@material-ui/core";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Checkbox from "../../Modules/Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import Input from "../../Modules/Shared/Components/Input/Input";
import Select from "../../Modules/Shared/Components/Select/Select";
import {
  LessonContentContext,
  LessonContentProvider,
} from "../../Providers/LessonContentProvider";
import { fetchChapterCommentsList } from "../../Redux/Actions/activityComment/activityComment.action";
import DeleteIcon from "../../assets/icons/delete.svg";
import classes from "./AddActivity.module.css";
import SkeletonCardOverlay from "../../Modules/Shared/Components/Spinner/SkeletonCardOverlay";
import { addActivityChapter } from "../../Services/api/courses/courseProvider";

function AddActivityToLessonModal(props) {
  require("./AddActivityToLessonModal.css");
  const lessonContentContext = useContext(LessonContentContext);
  const dispatch = useDispatch();

  const getCommentsListHandler = () => {
    dispatch(
      fetchChapterCommentsList({
        id: props.chapterId,
      })
    );
  };

  const { t } = useTranslation();
  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);

  return (
    <LessonContentProvider>
      <div className="background_black_add_activity">
        <div
          className="add_activity_modal"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="add_activity_modal_header">
            <h2 className="add_activity_modal_title">{props.title}</h2>
            <div
              className="add_activity_modal_close"
              onClick={props.dismissModal}
            >
              <img src={DeleteIcon} alt="close" />
            </div>
          </div>
          <Formik
            initialValues={{
              name: props?.activity?.name || "",
              desc: props?.activity?.desc || "",
              ...(props?.activity
                ? {}
                : props.chapterId
                ? { discussable_id: props.chapterId }
                : { discussable_id: id }),
              include_view_per: props?.activity?.include_view_per
                ? props?.activity?.include_view_per
                : false,
              file_size: props?.activity?.file_size || "",
              interactions_num: props?.activity?.interactions_num || "",
              interactions_agree:
                props?.activity?.interactions_agree !== null ||
                props?.activity?.interactions_agree !== undefined
                  ? props?.activity?.interactions_agree == true
                    ? "1"
                    : "0"
                  : "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setIsLocalSubmitting(true);
              let formData = new FormData();
              Object.keys(values).forEach((field) => {
                formData.append(field, values[field]);
              });

              formData.append(
                "include_view_per",
                values.include_view_per ? 1 : 0
              );

              try {
                if (props.activity) {
                  await lessonContentContext.editActivity(
                    formData,
                    props.chapterId ? props.chapterId : props.activity.id,
                    id
                  );
                  await lessonContentContext.fetchLessonContent(id);
                } else if (props.chapterId) {
                  await addActivityChapter(formData);
                  getCommentsListHandler();
                } else {
                  await lessonContentContext.addActivity(formData);
                  await lessonContentContext.fetchLessonContent(id);
                }

                props.dismissModal();
              } catch (error) {
                console.log(error);
                setIsLocalSubmitting(false);
                setSubmitting(false);
                return;
              }
            }}
            validate={(values) => {
              setHasSubmitted(true);
              const errors = {};
              if (!values.name) {
                errors.name = t("crud.errors.required");
              }

              if (values.require_approval === "") {
                errors.require_approval = t("crud.errors.required");
              }

              if (!values.file_size) {
                errors.file_size = t("crud.errors.required");
              }

              if (!values.interactions_num) {
                errors.interactions_num = t("crud.errors.required");
              }

              return errors;
            }}
            validateOnChange={hasSubmitted}
          >
            {({
              setFieldValue,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="add_activity_modal_form">
                <div className="add_activity_modal_form_fields">
                  <Input
                    type="text"
                    placeholder={t("trainer.course.lesson.activity.crud.name")}
                    label={t("trainer.course.lesson.activity.name")}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name}
                    required
                  />
                  {props.isChapter ? (
                    <></>
                  ) : (
                    <div style={{ display: "flex", gap: "20px" }}>
                      {/* <Checkbox
                    name="include_achivement_per"
                    label={t("include_achivement_per")}
                    onChange={(event) => {
                      setFieldValue(
                        "include_achivement_per",
                        event.target.checked ? true : false
                      );
                    }}
                    checked={values.include_achivement_per === true}
                    error={errors.include_achivement_per}
                  /> */}
                      <Checkbox
                        name="include_view_per"
                        label={t("include_view_per")}
                        onChange={(event) => {
                          setFieldValue(
                            "include_view_per",
                            event.target.checked ? 1 : 0
                          );
                        }}
                        checked={
                          values.include_view_per === true ||
                          values.include_view_per === 1
                        }
                        error={errors.include_view_per}
                        value={
                          values.include_view_per === true ||
                          values.include_view_per === 1
                        }
                      />
                      {/* <span>{values.include_view_per}</span> */}
                    </div>
                  )}

                  <div>
                    <label htmlFor="desc">
                      {t("trainer.course.lesson.activity.description")}
                    </label>
                    <SunEditor
                      name="desc"
                      onChange={(desc) => {
                        setFieldValue("desc", desc);
                      }}
                      defaultValue={values.desc}
                      value={values.desc}
                      setOptions={{
                        height: 200,
                        buttonList: [
                          ["undo", "redo"],
                          ["fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["removeFormat"],
                          ["fontColor", "hiliteColor", "outdent", "indent"],
                          ["align", "horizontalRule", "list", "table"],
                          ["link", "video"],
                        ],
                      }}
                    />
                    <div>
                      {errors && errors.desc ? (
                        <span className={"attachment_panel_input_error"}>
                          {errors.desc}
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <Input
                    type="number"
                    placeholder={t("trainer.course.lesson.activity.crud.rpu")}
                    label={t("trainer.course.lesson.activity.rpu")}
                    name="interactions_num"
                    onChange={handleChange}
                    value={values.interactions_num}
                    error={errors.interactions_num}
                    min={1}
                    required
                  />

                  <Input
                    type="number"
                    placeholder={t(
                      "trainer.course.lesson.activity.crud.max_size"
                    )}
                    label={t("trainer.course.lesson.activity.max_size")}
                    name="file_size"
                    onChange={handleChange}
                    value={values.file_size}
                    error={errors.file_size}
                    required
                  />

                  <Select
                    name="interactions_agree"
                    label={t("trainer.course.lesson.activity.require_approval")}
                    onchange={handleChange}
                    options={[
                      { label: t("no"), value: 0, id: 0 },
                      { label: t("yes"), value: 1, id: 1 },
                    ]}
                    error={errors.interactions_agree}
                    value={values.interactions_agree}
                    initialValue={t(
                      "trainer.course.lesson.activity.require_approval"
                    )}
                    required
                  />
                  <div className="add_activity_actions">
                    <div
                      className={`${classes["create_tax__form__field-actions"]} col-12`}
                    >
                      <button
                        className={
                          classes["create_tax__form__field-actions-submit"]
                        }
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress color="white" size={30} />
                          </Box>
                        ) : (
                          t("crud.placeholders.submit")
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </LessonContentProvider>
  );
}

export default AddActivityToLessonModal;
