import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";

export function getAllDetails(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.all_details, {
          params: { ...params },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAllExhibitionSponser(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_exhibition_sposer, {
          params: { ...params },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function getAllExhibitionEnrolled(params) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_exhibition_Enroll, {
          params: { ...params },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function exportDetails(data, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.all_details_export, {
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "User Details" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
