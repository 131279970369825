import { useTheme } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import adminDoc from "../../../assets/image/admin-doc.png";
import dollar from "../../../assets/image/dollar.png";
import help from "../../../assets/image/help.png";
import not from "../../../assets/image/notification.png";
import settings from "../../../assets/image/settings.png";
import sup from "../../../assets/image/support.png";
import user from "../../../assets/image/user(2).png";
import Qassem_logo from "../../../assets/image/Qassem_logo.svg";
import GlobIcon from "../../../assets/icons/globIcon.svg";
import i18n from "../../../i18n/i18n";
import { Lang } from "../../../utils";
import Sidebar from "../sidebar/sidebar";
// import { useTheme } from "styled-components";
import moment from "moment";
import toLocalTimezone from "../../../Services/Timezone";
import {
  getNotifications,
  readNotifications,
} from "../../../Services/api/Notifications/notificationsProvider";
import HeaderLogo from "../../../assets/image/Header.png";
import ar from "../../../assets/image/saudi-arabia.png";
import en from "../../../assets/image/united-kingdom.png";
import classes from "./adminHeader.module.css";
import UserDropDown from "../../Shared/Components/Header/DynamicHeaderWithoutLogin/UserDropDwon/UserDropDown";
import { useHistory } from "react-router-dom";
import Notification from "./Notification";
function AdminHeader(props) {
  require("./adminHeader.css");
  const { t } = useTranslation();
  const history = useHistory();
  document.body.dir = i18n.dir();
  const theme = useTheme();
  const type_login = localStorage.getItem("type_login");
  const authContext = useContext(AuthContext);
  const [sidebar, setSidebar] = useState(false);
  const [ListOfNotification, setListOfNotification] = useState([]);
  const [ReadNotification, setReadNotification] = useState(false);
  const [IconReadNotification, setIconReadNotification] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("roles");
    localStorage.removeItem("type");
    localStorage.removeItem("name_ar");
    localStorage.removeItem("name_en");
    localStorage.removeItem("img");
    localStorage.removeItem("phone");
    localStorage.removeItem("completed_profile");
    localStorage.removeItem("group");

    authContext.setAuth({});
    authContext.setRoles([]);
    props.history.push("/");
  };

  const sidebarHandler = (event) => {
    event.preventDefault();
    setSidebar(!sidebar);
  };

  const [addAnimationSide, setAddAnimationSide] = useState(false);

  const onConfirm = () => {
    setAddAnimationSide(true);
    setTimeout(() => {
      setSidebar(false);
    }, 300);
  };

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [sidebar]);

  const changeLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  let buttonLang = <div onClick={() => changeLanguage("ar")}>AR</div>;
  if (document.body.dir === "rtl") {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("en")}>
        <div className="lang_btn_qassem">
          <div className="lang_name">English</div>
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />
          {/* <img src={en} alt="" /> */}
        </div>
      </div>
    );
  } else {
    buttonLang = (
      <div className="lang_style" onClick={() => changeLanguage("ar")}>
        <div className="lang_btn_qassem">
          {/* <img src={ar} alt="" /> */}
          <div className="lang_name">العربية</div>
          <img src={GlobIcon} alt="" style={{ width: 20, height: 20 }} />
        </div>
      </div>
    );
  }

  const userOptions = [
    ...(authContext.roles.includes("admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/admin"),
          },
        ]
      : authContext.roles.includes("accountant")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/accountant"),
          },
        ]
      : authContext.roles.includes("group-admin")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/group-admin"),
          },
        ]
      : authContext.roles.includes("manager")
      ? [
          {
            label: t("admin.label.dashboard"),
            onClick: () => history.push("/manager"),
          },
        ]
      : []),

    {
      label: t("selectGroups"),
      onClick: () => history.push("/my-groups"),
    },
    ...(authContext.roles.includes("admin") ||
    authContext.roles.includes("accountant")
      ? []
      : [
          {
            label: t("account_info"),
            onClick: () => history.push("/private-profile"),
          },
        ]),
    {
      label: t("edit_personal_profile"),
      onClick: () => history.push("/edit-profile"),
    },
    ...(type_login === "normal"
      ? [
          {
            label: t("accountde"),
            onClick: () => history.push("/update-password"),
          },
        ]
      : []),

    // {
    //   label: t("admin.label.list_tickets"),
    //   onClick: () =>
    //     history.push(
    //       `${
    //         localStorage.getItem("type") === "admin"
    //           ? "/admin/tickets"
    //           : "/tickets"
    //       }`
    //     ),
    // },
    {
      label: t("logout"),
      onClick: logout,
    },
  ];
  let notification_read = localStorage.getItem("ReadNotification");
  return (
    <div className={classes["header_shadow"]}>
      {sidebar === true ? (
        <>
          <Sidebar
            addAnimationSide={addAnimationSide}
            setAddAnimationSide={setAddAnimationSide}
            dismissSideBar={onConfirm}
            roles={props.roles}
          />
          <div className="backdrop_admin" onClick={onConfirm} />
        </>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <nav className={classes.adminNav}>
              <div className={classes.navbar_logo_admin}>
                <div
                  className={classes["hamburger-pointer"]}
                  onClick={sidebarHandler}
                >
                  <div className="hamburger-button" />
                  <div className="hamburger-button" />
                  <div className="hamburger-button" />
                </div>
                <NavLink exact to="/">
                  <img
                    src={Qassem_logo}
                    alt="..."
                    className={classes.image_logo_new}
                  />
                </NavLink>
                {/* <div className="line_logo"></div> */}
              </div>
              <div className={classes.flex_menu}>
                <Notification isAdmin={true} />
                <UserDropDown options={userOptions} />
                {buttonLang}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AdminHeader);
