import React, { useContext, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";

import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import { useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import QuestionDetailModal from "../../../../Modals/surveyModal/QuestionDetailModal";
import QuestionModal from "../../../../Modals/surveyModal/QuestionModal";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import { SideBySideMatrix } from "../types/SideBySideMatrix";
import { BasicMatrix } from "./BasicMatrix";
import { InputQuestion } from "./InputQuestion";
import Section from "./Section";
import { Selection, SingleChoice } from "./Selection";
import { SliderQuestion } from "./SliderQuestion";

const selectionTypes = [
  "select_one",
  "select_many",
  "single_choice",
  "ordering",
  "rank_order",
  "smiley_rating",
  "text_slider",
  "constant_sum",
  "numeric_slider",
  "contact_information",
  "graphical_rating",
  "drag_and_drop",
];

export default function Page(props) {
  require("./style.css");

  const surveysSectionModalRef = useRef();

  const sortingContainerRef = useRef();

  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState(props.question?.title);
  const [question, setQuestion] = useState(props.question?.question);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [questions, setQuestions] = useState(props.question?.questions || []);

  useEffect(() => {
    setQuestions(props.question?.questions || []);
    setTitle(props.question.title);
  }, [props.question]);

  const SortableItem = SortableElement(({ sortableQuestion, questionIndex }) => {
    return (
      <div
        key={`page-sortable-question-${questionIndex}`}
        className={sortableQuestion.type != "section" ? "survey-card-container" : ""}
        index={questionIndex}
      >
        {sortableQuestion.type == "side_by_side_matrix" ? (
          <>
            <div>{sortableQuestion.name}:</div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <SideBySideMatrix
                key={`question-${questionIndex}`}
                questionIndex={questionIndex}
                question={sortableQuestion}
                mode="preview"
                saveQuestion={saveQuestion}
                deleteOptionFromServer={props.deleteOptionFromServer}
                preview={props.preview}
              />
            </div>
          </>
        ) : null}
        {sortableQuestion.type == "basic_matrix" ? (
          <>
            <div>{sortableQuestion.name}:</div>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <BasicMatrix
                key={`question-${questionIndex}`}
                // id={sortableQuestion.id}
                questionIndex={questionIndex}
                question={sortableQuestion}
                mode="preview"
                saveQuestion={saveQuestion}
                deleteOptionFromServer={props.deleteOptionFromServer}
                preview={props.preview}
              />
            </div>
          </>
        ) : null}
        {/* Section */}
        {sortableQuestion.type == "section" ? (
          <div>
            <div>{sortableQuestion.name}</div>
            <Section
              key={`question-${questionIndex}`}
              question={sortableQuestion}
              questionIndex={questionIndex}
              saveQuestion={saveQuestion}
              removeQuestion={removeQuestion}
              deleteQuestionFromServer={props.deleteQuestionFromServer}
              deleteOptionFromServer={props.deleteOptionFromServer}
              x
              preview={props.preview}
            />
          </div>
        ) : null}
        {/* Multiple Choices */}
        {selectionTypes.includes(sortableQuestion.type) ? (
          <div>
            <div>{sortableQuestion.name}</div>
            <Selection
              questionIndex={questionIndex}
              question={sortableQuestion}
              mode="preview"
              selectionType={sortableQuestion.type}
              deleteOptionFromServer={props.deleteOptionFromServer}
              preview={props.preview}
            />
          </div>
        ) : null}
        {/* Inputs */}
        {["string", "email", "phone", "date", "calender", "single_file", "star_rating", "text"].includes(
          sortableQuestion.type
        ) ? (
          <div>
            <div>{sortableQuestion.name}</div>
            <InputQuestion
              questionIndex={questionIndex}
              question={sortableQuestion}
              mode="preview"
              selectionType={sortableQuestion.type}
              deleteOptionFromServer={props.deleteOptionFromServer}
              preview={props.preview}
            />
          </div>
        ) : null}
        {/* Buttons */}
        <div
          style={{
            display: props.preview ? "none" : "flex",
            alignItems: "flex-start",
            justifyContent: "pace-between",
            alignContent: "space-between",
          }}
        >
          <button
            type="button"
            // disabled={isSubmitting}
            className="admin_add_button"
            onClick={() => {
              setQuestion({ ...sortableQuestion });
              setQuestionIndex(questionIndex);
            }}
          >
            تعديل
          </button>
          <button
            type="button"
            // disabled={isSubmitting}
            className="admin_add_button"
            onClick={() => removeQuestion(questionIndex)}
          >
            {t("delete")}
          </button>
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ questions }) => {
    return (
      <div>
        {questions.map((q, index) => (
          <SortableItem key={`page-sortable-item-${index}`} questionIndex={index} sortableQuestion={q} index={index} />
        ))}
      </div>
    );
  });

  const reorderItems = (from, to) => {
    var unOrderedQuestions = [...questions];
    var sourceItem = unOrderedQuestions.splice(from, 1)[0];
    unOrderedQuestions.splice(to, 0, sourceItem);
    return unOrderedQuestions;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    var reorderedQuestions = reorderItems(oldIndex, newIndex);
    reorderedQuestions = reorderedQuestions.map((q, i) => {
      q.order = i;
      return q;
    });
    setQuestions(reorderedQuestions);
    const page = {
      ...props.question,
      questions: reorderedQuestions,
    };
    props.saveQuestion(page, props.questionIndex);
  };

  const saveQuestion = (modalQuestion, index) => {
    var updatedQuestions = [];
    // decide to update or create new
    if (index != null && index !== undefined) {
      updatedQuestions = props.question.questions.map((q, i) => {
        if (index == i) {
          return modalQuestion;
        }
        return q;
      });
    } else {
      if (props.question?.questions) {
        modalQuestion.order = props.question.questions.length + 1;
        updatedQuestions = [...props.question.questions, modalQuestion];
      } else {
        modalQuestion.order = 0;
        updatedQuestions = [modalQuestion];
      }
    }
    // update the questions list
    const page = {
      ...props.question,
      name: props.question.name,
      questions: updatedQuestions,
    };
    // update the parent
    props.saveQuestion(page, props.questionIndex);

    // reset question and question index
    setQuestion(null);
    setQuestionIndex(null);
  };

  const removeQuestion = (index) => {
    const removedQuestion = questions[index];
    if (removedQuestion.id) {
      if (!props.deleteQuestionFromServer(removedQuestion.id)) {
        return;
      }
    }
    var filteredQuestions = props.question.questions.filter((question, i) => i != index);
    const page = {
      ...props.question,
      name: props.question.name,
      questions: filteredQuestions,
    };
    props.saveQuestion(page, props.questionIndex);
  };

  useEffect(() => {
    if (question !== null && questionIndex !== null) {
      surveysSectionModalRef.current.showModal();
    }
  }, [question, questionIndex]);

  const dismissModal = () => {
    // reset question and question index
    setQuestion(null);
    setQuestionIndex(null);
    // dismiss the modal
    surveysSectionModalRef.current.dismissModal();
  };

  return (
    <>
      {props.surveyMode == "presentation" ? null : (
        <BasicModal ref={surveysSectionModalRef}>
          <QuestionModal
            label={"New Question"}
            title={"New Question"}
            surveysModalRef={surveysSectionModalRef}
            accept={() => surveysSectionModalRef.current.dismissModal()}
            saveQuestion={saveQuestion}
            question={question}
            questionIndex={questionIndex}
            deleteOptionFromServer={props.deleteOptionFromServer}
            dismissModal={dismissModal}
          />
        </BasicModal>
      )}
      <div>
        <div className="container-fluid profile_wrapper survey-section ">
          <div className="admin_add_form" ref={sortingContainerRef}>
            {props.surveyMode != "presentation" ? (
              <SortableList
                questions={questions}
                onSortEnd={onSortEnd}
                helperContainer={() => sortingContainerRef.current ?? document.body}
                disableAutoscroll
              />
            ) : null}

            {props.surveyMode == "presentation"
              ? questions.map((question, index) => {
                  return (
                    <div
                      key={`page-sortable-question-${index}`}
                      className={question.type != "section" ? "survey-card-container" : ""}
                      index={index}
                    >
                      {question.type == "side_by_side_matrix" ? (
                        <>
                          <div>
                            {question.name} {question.is_required ? <span className="required-question">*</span> : null}
                            :
                          </div>
                          <div style={{ display: "flex", alignItems: "flex-start" }}>
                            <SideBySideMatrix
                              key={`question-${index}`}
                              // id={sortableQuestion.id}
                              questionIndex={index}
                              question={question}
                              mode="preview"
                              surveyMode={props.surveyMode}
                              answers={props.answers}
                              updateAnswers={props.updateAnswers}
                              canAnswer={props.canAnswer}
                            />
                          </div>
                        </>
                      ) : null}
                      {question.type == "basic_matrix" ? (
                        <>
                          <div>
                            {question.name}:{" "}
                            {question.is_required ? <span className="required-question">*</span> : null}
                          </div>
                          <div style={{ display: "flex", alignItems: "flex-start" }}>
                            <BasicMatrix
                              key={`question-${index}`}
                              // id={sortableQuestion.id}
                              questionIndex={index}
                              question={question}
                              mode="preview"
                              surveyMode={props.surveyMode}
                              answers={props.answers}
                              updateAnswers={props.updateAnswers}
                              canAnswer={props.canAnswer}
                            />
                          </div>
                        </>
                      ) : null}
                      {/* Section */}
                      {question.type == "section" ? (
                        <div>
                          <div>
                            {question.name} {question.is_required ? <span className="required-question">*</span> : null}
                          </div>
                          <Section
                            key={`question-${index}`}
                            question={question}
                            questionIndex={index}
                            surveyMode={props.surveyMode}
                            answers={props.answers}
                            updateAnswers={props.updateAnswers}
                            canAnswer={props.canAnswer}
                          />
                        </div>
                      ) : null}
                      {/* Multiple Choices */}
                      {selectionTypes.includes(question.type) ? (
                        <div>
                          <div>
                            {question.name} {question.is_required ? <span className="required-question">*</span> : null}
                          </div>
                          <Selection
                            questionIndex={index}
                            question={question}
                            mode="preview"
                            selectionType={question.type}
                            answers={props.answers}
                            updateAnswers={props.updateAnswers}
                            canAnswer={props.canAnswer}
                          />
                        </div>
                      ) : null}
                      {/* Inputs */}
                      {["string", "email", "phone", "date", "calender", "single_file", "star_rating", "text"].includes(
                        question.type
                      ) ? (
                        <div>
                          <div>
                            {question.name} {question.is_required ? <span className="required-question">*</span> : null}
                          </div>
                          <InputQuestion
                            questionIndex={index}
                            question={question}
                            mode="preview"
                            selectionType={question.type}
                            surveyMode={props.surveyMode}
                            answers={props.answers}
                            updateAnswers={props.updateAnswers}
                            canAnswer={props.canAnswer}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}

            {/* Buttons */}
            <div
              className="row"
              style={{ display: props.surveyMode == "presentation" || props.preview ? "none" : "", marginTop: "3%" }}
            >
              <div className="col-md-6">
                <button
                  type="button"
                  // disabled={isSubmitting}
                  className="admin_add_button"
                  onClick={() => surveysSectionModalRef.current.showModal()}
                >
                  {t("survey.buttons.addQuestion")}
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="submit"
                  // disabled={isSubmitting}
                  className="admin_add_button"
                  onClick={() => props.removeQuestion(props.questionIndex)}
                >
                  {t("survey.buttons.deleteQuestion")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
