import { ReactComponent as CloseIcon } from "../../../../../assets/icons/crose.svg";
import { useTranslation } from "react-i18next";
import QuestionForm from "./QuestionForm";

export default function QuestionWarpper({
  onSubmit,
  courseId,
  questionData,
  questionTypes,
  disabled,
  onClose,
}) {
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-py-8 tw-h-full tw-w-full">
      <div
        className={`tw-bg-white tw-shadow tw-rounded tw-w-full tw-max-h-full md:tw-w-2/3 lg:tw-w-3/4 xl:tw-w-1/2 tw-divide-y inner-scrollbar`}
        style={{ overflowY: "overlay" }}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-px-8 tw-py-4">
          <div className="tw-text-xl tw-text-teal-700">{`${t(
            questionData ? "edit_question" : "add_question"
          )}`}</div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="tw-h-6 tw-w-6" />
          </button>
        </div>
        <div className="tw-px-8 tw-py-4 ">
          <QuestionForm
            onSubmit={onSubmit}
            courseId={courseId}
            questionData={questionData}
            disabled={disabled}
            questionTypes={questionTypes}
          />
        </div>
      </div>
    </div>
  );
}
