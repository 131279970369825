import classes from "./CalenderNew.module.css";
import Img from "../../../assets/image/EventDefault.png";
import moment from "moment";
import { utcToLocal } from "../../../utils/utcToLocal";
import DateIcon from "../../../assets/icons/dateIcon.svg";
import LocationIcon from "../../../assets/icons/location1.svg";
export default function EventCard({ event }) {
  return (
    <div className={classes.eventCard}>
      <div className={`${classes.type} ${classes[event.type.value]}`}></div>
      <img src={event.cover || Img} alt="" className={classes.eventImg} />
      <div className={classes.eventContent}>
        <h3 className={classes.eventTitle}>{event.title}</h3>
        <div className={classes.eventDetails}>
          <span className={classes.iconscontainer}>
            <img src={DateIcon} alt="" style={{ marginTop: "-3px" }} />{" "}
            <p className={classes.iconText}>
              {moment(event.date).format("YYYY-MM-DD")}
              {utcToLocal(event.date)}
            </p>
          </span>
          <span className={classes.iconscontainer}>
            <img src={LocationIcon} alt="" />
            <p className={classes.iconText}>{event.location}</p>
          </span>
        </div>
      </div>
    </div>
  );
}
