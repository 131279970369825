import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PasswordInput from "../../../Components/Input/PasswordInput";
import ValidatorInput from "../../../Components/Input/ValidatorInput";
import {
  createNewUser,
  register,
} from "../../../Services/api/auth/AuthProvider";
import i18n from "../../../i18n/i18n";
import Input from "../../Shared/Components/Input/Input";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import { Hint } from "./../../../Components";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import DeleteIcon from "../../../assets/icons/delete.svg";
import { addGroupsToUserExistingEmail } from "../../../Services/api/Roles/RolesProvider";

function CreateAccount({ role }) {
  require("./account.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [nameEnError, setNameEnError] = useState("");
  const [nameArError, setNameArError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [conPasswordError, setConPasswordError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modelMsg, setModelMsg] = useState("");
  const [modelEmail, setModelEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  /** TODO: */
  const history = useHistory();
  /** */

  const nameEnHandleChange = (event) => {
    setNameEn(event.target.value);
  };

  const nameArHandleChange = (event) => {
    setNameAr(event.target.value);
  };

  const confirmPasswordHandleChange = (confirmPassword) => {
    setConfirmPassword(confirmPassword);
  };

  const emailHandleChange = (email) => {
    setEmail(email);
  };

  const passwordHandleChange = (password) => {
    setPassword(password);
  };
  function validate() {
    let hasError = false;
    if (!nameEn) {
      setNameEnError(t("crud.errors.required"));
      hasError = true;
    }
    if (!nameAr) {
      setNameArError(t("crud.errors.required"));
      hasError = true;
    }
    if (!email) {
      setEmailError(t("crud.errors.required"));
      hasError = true;
    }
    if (email && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailError(t("crud.errors.email"));
      hasError = true;
    }
    if (!password) {
      setPasswordError(t("crud.errors.required"));
      hasError = true;
    }
    if (!confirmPassword) {
      setConPasswordError(t("crud.errors.required"));
      hasError = true;
    }

    if (password.length < 8) {
      setPasswordError(t("8char"));
      hasError = true;
    }

    const containsUppercase = /[A-Z]/.test(password);
    const containsLowercase = /[a-z]/.test(password);
    const containsNumber = /[0-9]/.test(password);
    const containsSymbol = /[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]/.test(
      password
    );
    const isValidLength = password.length >= 8 && password.length <= 16;

    if (!containsUppercase) {
      setPasswordError(t("password_validation.uppercase"));
      hasError = true;
    }

    if (!containsLowercase) {
      setPasswordError(t("password_validation.lowercase"));
      hasError = true;
    }

    if (!containsNumber) {
      setPasswordError(t("password_validation.digit"));
      hasError = true;
    }

    if (!containsSymbol) {
      setPasswordError(t("password_validation.symbol"));
      hasError = true;
    }

    if (!isValidLength) {
      setPasswordError(t("password_validation.long"));
      hasError = true;
    }

    if (confirmPassword && password && confirmPassword !== password) {
      setConPasswordError(t("confirmPasswordError"));
    }

    // reset the errors for the fields that are valid now
    if (nameEn && nameEnError) {
      setNameEnError("");
    }
    if (nameAr && nameArError) {
      setNameArError("");
    }
    if (email && emailError) {
      setEmailError("");
    }
    if (password && passwordError) {
      setPasswordError("");
    }
    if (confirmPassword && conPasswordError) {
      setConPasswordError("");
    }

    if (hasError) {
      return false;
    }

    return true;
  }

  const AddUserExistingInOtherGroup = () => {
    addGroupsToUserExistingEmail(modelEmail)
      .then((res) => {
        // console.log(res);

        if (res.status === 200) {
          setShowModal(false);
          if (role === "group-admin") {
            history.push({
              pathname: "/group-admin",
              state: {
                email: email,
              },
            });
          } else if (role === "manager") {
            history.push({
              pathname: "/manager",
              state: {
                email: email,
              },
            });
          } else {
            history.push({
              pathname: "/admin",
              state: {
                email: email,
              },
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validate();
    setIsSubmitting(true);
    setIsLoading(true);
    if (isValid) {
      try {
        let response = await createNewUser({
          nameEn,
          nameAr,
          email,
          password,
          confirmPassword,
        });
        setIsSubmitting(false);
        setIsLoading(false);
        if (response.status === 201 && response.data.status) {
          if (role === "group-admin") {
            history.push({
              pathname: "/group-admin",
              state: {
                email: email,
              },
            });
          } else if (role === "manager") {
            history.push({
              pathname: "/manager",
              state: {
                email: email,
              },
            });
          } else {
            history.push({
              pathname: "/admin",
              state: {
                email: email,
              },
            });
          }
          setIsLoading(false);
        }
      } catch (err) {
        // console.log(err);
        setIsSubmitting(false);
        setIsLoading(false);
        if (err.response.status === 409) {
          setModelEmail(err.response.data.email);
          setModelMsg(err.response.data.message);
          setShowModal(true);
        } else {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {typeof err === "string" ? (
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    display: "block",
                  }}
                >
                  {err}
                </span>
              ) : err.response.data ? (
                <>
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {err.response.data.msg}
                  </span>
                  <ul>
                    {Object.keys(err.response.data.errors).map((key) => {
                      return (
                        <li>
                          <span style={{ fontSize: 11 }}>
                            {err.response.data.errors[key]}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                " Error"
              )}
            </span>
          );
        }
      } finally {
        setIsSubmitting(false);
        setIsLoading(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  setTimeout(() => {
    setIsLoading(false);
  }, 500);

  return (
    <>
      <div
        className="container-fluid"
        style={{
          height: `${isLoading ? `90%` : null} `,
        }}
      >
        <div
          className="row"
          style={{
            height: `${isLoading ? `100%` : null} `,
          }}
        >
          <div
            className="col-12"
            style={{
              height: `${isLoading ? `100%` : null} `,
            }}
          >
            <div className="admin_label">
              <NavLink
                to={
                  localStorage.getItem("type") == "group-admin"
                    ? "/group-admin"
                    : localStorage.getItem("type") == "manager"
                    ? "/manager"
                    : "/admin"
                }
              >
                {t("admin.label.admin_label")}
              </NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("admin.label.create_account")}
            </div>
            {isLoading ? (
              <div
                style={{
                  height: `${isLoading ? `90%` : null} `,
                }}
              >
                <SkeletonCard />
              </div>
            ) : (
              <div className="admin_card">
                <div className="admin_forms">
                  <form onSubmit={handleSubmit} className="admin_form_flex_col">
                    <Input
                      label={t("nameEn")}
                      value={nameEn}
                      onChange={nameEnHandleChange}
                      error={nameEnError}
                    />
                    <Input
                      label={t("nameAr")}
                      value={nameAr}
                      onChange={nameArHandleChange}
                      error={nameArError}
                    />
                    <Input
                      label={t("Email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={emailError}
                      validationParams="email"
                      type="text"
                      placeholder={t("Email")}
                    />
                    <PasswordInput
                      setContent={passwordHandleChange}
                      classes="admin_component_input"
                    />
                    {passwordError && (
                      <div className="text-danger">{passwordError}</div>
                    )}

                    <PasswordInput
                      setContent={confirmPasswordHandleChange}
                      classes="admin_component_input"
                    />
                    {conPasswordError && (
                      <div className="text-danger">{conPasswordError}</div>
                    )}

                    <div className="admin_btns">
                      <button
                        type="submit"
                        className="button_loading auth_btn"
                        disabled={disabled}
                      >
                        <div className="">{t("CreateAnAccount")}</div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <SharedModal
          icon={DeleteIcon}
          title={" "}
          confirmMsg={`${modelMsg} ${modelEmail}`}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={() => {
            AddUserExistingInOtherGroup();
          }}
          isLoading={false}
        />
      )}
    </>
  );
}

export default CreateAccount;
