import moment from "moment";
import { useTranslation } from "react-i18next";

import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";

import classes from "./SearchPreview.module.css";

const SearchPreviewExbiTable = ({ isBooth, data, loading }) => {
  const { t, i18n } = useTranslation();

  const columns = [
    {
      id: "order",
      name: "#",
      key: "order",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "name",
      name: t(`Exhibition.name`),
      key: "name",
      cell: ({ rowData }) => (
        <div>{isBooth ? rowData?.exhibition_name : rowData?.name}</div>
      ),
    },
    {
      id: "id",
      name: t("general.id"),
      key: "id",
      cell: ({ rowData }) => <>{rowData?.id}</>,
      sortable: false,
    },

    ...(isBooth
      ? [
          {
            id: "name2",
            name: t(`Exhibition.name2`),
            key: "name2",
            cell: ({ rowData }) => <div>{rowData?.name}</div>,
          },
        ]
      : [
          {
            id: "date",
            name: t("general.subscription_date"),
            key: "date",
            cell: ({ rowData }) => (
              <>
                {moment(rowData?.visit_date).format("DD/MM/YYYY")}
                <br />
                {utcToLocal(rowData?.visit_date)}
              </>
            ),
            sortable: false,
          },
        ]),
    {
      id: "start_date",
      name: t("general.start_date"),
      key: "start_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.exhibition_start_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.exhibition_start_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.exhibition_end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.exhibition_end_date)}
        </>
      ),
      sortable: false,
    },
  ];

  return <MainTable columns={columns} data={data || []} isLoading={loading} />;
};

export default SearchPreviewExbiTable;
