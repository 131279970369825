import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  DOC_PDF,
  PNG_TYPE,
  acceptDocuments,
  acceptImages,
  fromKbToMb,
} from "../../../../Services/Validator";
import { getListType } from "../../../../Services/api/TypeFile/TypeFileProvider";
import bankIcon from "../../../../assets/icons/bank (1).svg";
import walletVisa from "../../../../assets/icons/wallet.svg";
import Input from "../../../Shared/Components/Input/Input";
import InputFile from "../../../Shared/Components/InputFile/InputFile";
import PhoneInput from "../../../Shared/Components/PhoneInput/PhoneInput";
import classes from "./trainingCoursePayment.module.css";
import Select from "react-select";

export default function CoursePaymentForm({
  courseDetails,
  handleChange,
  setFieldValue,
  handleSelectPaymentClick,
  selectedBox,
  values,
  trainees,
  errors,
  isGift,
}) {
  const { t, i18n } = useTranslation();
  const [listTypeFile, setListTypeFile] = useState([]);

  const getListTypeFile = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          console.log("res?.data?.data", res?.data?.data);
          setListTypeFile(res?.data?.data);
          resolve();
        },
        (error) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {t("programs.failed_loading")}
            </span>
          );
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    getListTypeFile();
  }, []);

  const getTypeFile = (searchTypes) => {
    return listTypeFile.filter((file) => searchTypes.includes(file.type));
  };

  const acceptFiles = () => {
    return `${acceptImages()},${acceptDocuments()}`;
  };

  return (
    <div className={classes["payment-form"]}>
      <div className={classes["payment-box"]}>
        <div className={classes["payment-box-title"]}>
          {t("payments.payment_method")}
        </div>
        <div className={classes["payment-box-options"]}>
          <div
            className={
              classes[
                `payment-box-option${
                  selectedBox.bankSelected ? "-selected" : ""
                }`
              ]
            }
            onClick={() => handleSelectPaymentClick("bank")}
          >
            <img src={bankIcon} alt="payment-icon" />
            <h4>{t("payments.bank_transfer")}</h4>
          </div>
          <div
            className={
              classes[
                `payment-box-option${
                  selectedBox.walletSelected ? "-selected" : ""
                }`
              ]
            }
            onClick={() => handleSelectPaymentClick("wallet")}
          >
            <img src={walletVisa} alt="payment-icon" />
            <h4>{t("payments.wallet")}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
