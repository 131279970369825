import { Rating } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SurveyPresenter from "../../../trainees/survey/SurveyPresenter";
import SurveyTrainer from "./../../../trainees/survey/SurveyTrainer";

export default function Trainees(props) {
  require("./trainees.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const surveyAnswers = history.location.state.answers;
  const survey = history.location.state.survey;
  const user = history.location.state.user;
  const isPublic = history.location.state.isPublic;
  const guestName = history.location.state.guestName;
  const guestEmail = history.location.state.guestEmail;

  const downloadfile = (value) => {
    window.location.href = value;
  };

  function get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  return (
    <SurveyPresenter
      survey={survey}
      questions={survey}
      answers={surveyAnswers}
      canAnswer={false}
      user={user}
      isPublic={isPublic}
      guestName={guestName}
      guestEmail={guestEmail}
    />
  );
}
