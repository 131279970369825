import React, { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { ListToken } from "../../../Services/api/Zoom/ZoomProvider";
import { NavLink } from "react-router-dom";
import toLocalTimezone from "../../../Services/Timezone";
import editIcon from "../../../assets/icons/pen.svg";
import IconsTooltip from "../../../Components/tooltips/IconsTooltip";

export default function AddToken() {
  require("./token.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setisLoading] = useState(true);
  const [webxToken, setWebxToken] = useState(null);
  const [TeamsToken, setTeamsToken] = useState(null);

  useEffect(() => {
    // ListTokenWebx();
    ListTokenTeams();
  }, []);

  // const ListTokenWebx = async() => {
  //     try{
  //         let wbex = await ListToken('webx')
  //         setisLoading(false)
  //         setWebxToken(wbex.data.token)
  //     }catch (err){
  //         if(err.response.status === 404){
  //             setWebxToken('notFound')
  //         }else{
  //             toast.error(
  //                 <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>{err.response.data.msg}</span>
  //             );
  //         }
  //     }
  // }
  const ListTokenTeams = async () => {
    try {
      let TeamsRes = await ListToken("teams");
      setisLoading(false);
      setTeamsToken(TeamsRes.data.token);
    } catch (err) {
      if (err.response.status === 404) {
        setTeamsToken("notFound");
      } else {
        toast.error(
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
        );
      }
    }
  };

  return (
    <div className="faq-add-pg">
      <div className="container-fluid profile_wrapper">
        <div className="admin_label">
          <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("zoom.Webx_teams")}
        </div>
      </div>
      <div className="container-fluid profile_wrapper">
        <div className={isLoading ? null : "table_bank_tran faq_box_white"}>
          {isLoading ? (
            <div
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <SkeletonCard />
            </div>
          ) : (
            <>
              <div className="box_faq" style={{ width: "90%" }}>
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ backgroundColor: "#025299" }}>
                        <th scope="col">{t("zoom.type")}</th>
                        <th scope="col">{t("zoom.token")}</th>
                        <th scope="col">{t("zoom.refreshToken")}</th>
                        <th scope="col"> {t("zoom.last_updated")}</th>
                        <th scope="col"> {t("zoom.Edit_Add")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {webxToken ? (
                        webxToken === "notFound" ? (
                          <tr>
                            <td>Webx</td>
                            <td
                              style={{ maxWidth: "400px", overflowY: "auto" }}
                            >
                              {" "}
                              not Found{" "}
                            </td>
                            <td> ---- </td>
                            <td>
                              <div className="release_icon">
                                <NavLink to="/admin/webx/add-token">
                                  add Token
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>{webxToken.provider}</td>
                            <td
                              style={{ maxWidth: "400px", overflowY: "auto" }}
                            >
                              {webxToken.token}
                            </td>
                            <td
                              style={{ maxWidth: "400px", overflowY: "auto" }}
                            >
                              {webxToken.refresh_token}
                            </td>
                            <td>
                              {new Date(
                                toLocalTimezone(webxToken.updated_at)
                              ).toDateString()}
                            </td>
                            <td>
                              <div className="release_icon">
                                <NavLink
                                  to={{
                                    pathname: `/admin/teams/update/${webxToken.id}`,
                                    state: webxToken,
                                  }}
                                >
                                  <IconsTooltip
                                    title={t("modal.coupon.buttons.edit")}
                                    content={<img src={editIcon} alt="" />}
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : null}
                      {TeamsToken ? (
                        TeamsToken === "notFound" ? (
                          <tr>
                            <td>Teams</td>
                            <td
                              style={{ maxWidth: "400px", overflowY: "auto" }}
                            >
                              {" "}
                              not Found{" "}
                            </td>
                            <td> ---- </td>
                            <td>
                              <div className="release_icon">
                                <NavLink to="/admin/webx/add-token">
                                  add Token
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>{TeamsToken.provider}</td>
                            <td
                              style={{ maxWidth: "400px", overflowY: "auto" }}
                            >
                              {TeamsToken.token}
                            </td>
                            <td
                              style={{ maxWidth: "400px", overflowY: "auto" }}
                            >
                              {TeamsToken.refresh_token}
                            </td>
                            <td>
                              {new Date(
                                toLocalTimezone(TeamsToken.updated_at)
                              ).toDateString()}
                            </td>
                            <td>
                              <div className="release_icon">
                                <NavLink
                                  to={{
                                    pathname: `/admin/teams/update/${TeamsToken.id}`,
                                    state: TeamsToken,
                                  }}
                                >
                                  <IconsTooltip
                                    title={t("modal.coupon.buttons.edit")}
                                    content={<img src={editIcon} alt="" />}
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
