import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import HomeIcon from "../../../../assets/icons/home.svg";
import {
  exportDetails,
  getAllDetails,
  getAllExhibitionEnrolled,
  getAllExhibitionSponser,
} from "../../../../Services/api/searchDetails/SearchDetailsProvider";
import { toast } from "react-toastify";
import classes from "./SearchPreview.module.css";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import { filterActions } from "./SearchPreviewActions";
import SearchPreviewFilter from "./SearchPreviewFilter";
import SearchPreviewTable from "./SearchPreviewTable";
import { Pagination } from "@mui/material";

import SearchPreviewExbiTable from "./SearchPreviewExbiTable";
export default function SearchPreview() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState({});
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [traineePrograms, settraineePrograms] = useState([]);
  const [traineeCourses, settraineeCourses] = useState([]);
  const [trainerPrograms, settrainerPrograms] = useState([]);
  const [trainerCourses, settrainerCourses] = useState([]);
  const [managerPrograms, setmanagerPrograms] = useState([]);
  const [managerCourses, setmanagerCourses] = useState([]);
  const [Booths, setBooths] = useState([]);
  const [Exhibitions, setExhibitions] = useState([]);

  const [traineeProgramsMeta, settraineeProgramsMeta] = useState([]);
  const [traineeCoursesMeta, settraineeCoursesMeta] = useState([]);
  const [trainerProgramsMeta, settrainerProgramsMeta] = useState([]);
  const [trainerCoursesMeta, settrainerCoursesMeta] = useState([]);
  const [managerProgramsMeta, setmanagerProgramsMeta] = useState([]);
  const [managerCoursesMeta, setmanagerCoursesMeta] = useState([]);
  const [BoothsMeta, setBoothsMeta] = useState([]);
  const [ExhibitionsMeta, setExhibitionsMeta] = useState([]);

  const [traineeProgramsLoading, settraineeProgramsLoading] = useState(false);
  const [traineeCoursesLoading, settraineeCoursesLoading] = useState(false);
  const [trainerProgramsLoading, settrainerProgramsLoading] = useState(false);
  const [trainerCoursesLoading, settrainerCoursesLoading] = useState(false);
  const [managerProgramsLoading, setmanagerProgramsLoading] = useState(false);
  const [managerCoursesLoading, setmanagerCoursesLoading] = useState(false);
  const [BoothsLoading, setBoothsLoading] = useState(false);
  const [ExhibitionsLoading, setExhibitionsLoading] = useState(false);

  const [traineeProgramsPage, settraineeProgramsPage] = useState(1);
  const [traineeCoursesPage, settraineeCoursesPage] = useState(1);
  const [trainerProgramsPage, settrainerProgramsPage] = useState(1);
  const [trainerCoursesPage, settrainerCoursesPage] = useState(1);
  const [managerProgramsPage, setmanagerProgramsPage] = useState(1);
  const [managerCoursesPage, setmanagerCoursesPage] = useState(1);
  const [BoothsPage, setBoothsPage] = useState(1);
  const [ExhibitionsPage, setExhibitionsPage] = useState(1);

  const allCurrentPage = (page) => {
    setmanagerCoursesPage(page);
    setmanagerProgramsPage(page);
    settrainerCoursesPage(page);
    settrainerProgramsPage(page);
    settraineeCoursesPage(page);
    settraineeProgramsPage(page);
  };

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
  });
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "dashboard",
      page: t("admin.label.dashboard"),
      pagePath: "/admin",
    },
    {
      id: "exhibition",
      page: t("search.conduct_search"),
      pagePath: "/admin/search",
    },
    {
      id: "exhibition",
      page: t("general.view_details"),
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e, field) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name || field]: value });
  };
  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };
  const resetFilterHandler = () => {
    setFilterData({
      start_date: "",
      end_date: "",
    });
    setIsResetApplied(true);
  };
  const getFilterData = () => {
    const filter = { user_id: id, perPage: 10 };

    filterData.start_date && (filter.start_date = filterData.start_date);
    filterData.end_date && (filter.end_date = filterData.end_date);

    return filter;
  };

  const getExhibitionBooth = (currentPage = 1) => {
    const filter = getFilterData();
    filter.page = currentPage;
    setBoothsLoading(true);
    getAllExhibitionSponser(filter)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setBooths(res.data.data.booths.data);
          setBoothsMeta(res.data.data.booths.meta);
          setBoothsLoading(false);
        }
      })
      .catch((err) => {
        setBoothsLoading(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };
  const getAllExhibitionEnroll = (currentPage = 1) => {
    const filter = getFilterData();
    setCurrentFilter(filter);
    filter.page = currentPage;
    setExhibitionsLoading(true);
    getAllExhibitionEnrolled(filter)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setExhibitions(res.data.data.visits.data);
          setExhibitionsMeta(res.data.data.visits.meta);
          setExhibitionsLoading(false);
        }
      })
      .catch((err) => {
        setExhibitionsLoading(false);
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  const getListHandler = (role, is_program, currentPage = 1) => {
    const filter = getFilterData();

    filter.role = role;
    filter.is_program = is_program;
    filter.page = currentPage;
    if (role === "trainee" && is_program === 1) {
      settraineeProgramsLoading(true);
    }
    if (role === "trainee" && is_program === 0) {
      settraineeCoursesLoading(true);
    }
    if (role === "trainer" && is_program === 1) {
      settrainerProgramsLoading(true);
    }
    if (role === "trainer" && is_program === 0) {
      settrainerCoursesLoading(true);
    }
    if (role === "manager" && is_program === 1) {
      setmanagerProgramsLoading(true);
    }
    if (role === "manager" && is_program === 0) {
      setmanagerCoursesLoading(true);
    }
    getAllDetails(filter)
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          if (role === "trainee" && is_program === 1) {
            settraineePrograms(res.data.data.courses.data);
            settraineeProgramsMeta(res.data.data.courses.meta);
            settraineeProgramsLoading(false);
          }
          if (role === "trainee" && is_program === 0) {
            settraineeCourses(res.data.data.courses.data);
            settraineeCoursesMeta(res.data.data.courses.meta);
            settraineeCoursesLoading(false);
          }
          if (role === "trainer" && is_program === 1) {
            settrainerPrograms(res.data.data.courses.data);
            settrainerProgramsMeta(res.data.data.courses.meta);
            settrainerProgramsLoading(false);
          }
          if (role === "trainer" && is_program === 0) {
            settrainerCourses(res.data.data.courses.data);
            settrainerCoursesMeta(res.data.data.courses.meta);
            settrainerCoursesLoading(false);
          }
          if (role === "manager" && is_program === 1) {
            setmanagerPrograms(res.data.data.courses.data);
            setmanagerProgramsMeta(res.data.data.courses.meta);
            setmanagerProgramsLoading(false);
          }
          if (role === "manager" && is_program === 0) {
            setmanagerCourses(res.data.data.courses.data);
            setmanagerCoursesMeta(res.data.data.courses.meta);
            setmanagerCoursesLoading(false);
          }
        }
      })
      .catch((err) => {
        if (role === "trainee" && is_program === 1) {
          settraineeProgramsLoading(false);
        }
        if (role === "trainee" && is_program === 0) {
          settraineeCoursesLoading(false);
        }
        if (role === "trainer" && is_program === 1) {
          settrainerProgramsLoading(false);
        }
        if (role === "trainer" && is_program === 0) {
          settrainerCoursesLoading(false);
        }
        if (role === "manager" && is_program === 1) {
          setmanagerProgramsLoading(false);
        }
        if (role === "manager" && is_program === 0) {
          setmanagerCoursesLoading(false);
        }
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };
  const exportHandler = async (type) => {
    setIsExportLoading(true);
    const filter = { ...currentFilter };
    delete filter.page;
    delete filter.perPage;
    try {
      await exportDetails(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };
  useEffect(() => {
    getListHandler("trainer", 1);
    getListHandler("trainer", 0);
    getListHandler("trainee", 1);
    getListHandler("trainee", 0);
    getListHandler("manager", 1);
    getListHandler("manager", 0);
    getExhibitionBooth();
    getAllExhibitionEnroll();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [isResetApplied]);

  useEffect(() => {
    getListHandler("trainer", 1, trainerProgramsPage);
  }, [trainerProgramsPage]);
  useEffect(() => {
    getListHandler("trainer", 0, trainerCoursesPage);
  }, [trainerCoursesPage]);
  useEffect(() => {
    getListHandler("trainee", 1, traineeProgramsPage);
  }, [traineeProgramsPage]);
  useEffect(() => {
    getListHandler("trainee", 0, traineeCoursesPage);
  }, [traineeCoursesPage]);
  useEffect(() => {
    getListHandler("manager", 1, managerProgramsPage);
  }, [managerProgramsPage]);
  useEffect(() => {
    getListHandler("manager", 0, managerCoursesPage);
  }, [managerCoursesPage]);
  useEffect(() => {
    getExhibitionBooth(BoothsPage);
  }, [BoothsPage]);
  useEffect(() => {
    getAllExhibitionEnroll(ExhibitionsPage);
  }, [ExhibitionsPage]);

  return (
    <div className={classes["coupon-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      {/* {isResetApplied && <SkeletonCardOverlay skeletonWidth="100" />} */}
      <div className="container-fluid">
        <div className={classes["coupon-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  //   title={t("general.view_details")}
                  actions={filterActions({
                    // showFilter: showFilterHandler,

                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <SearchPreviewFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    // currentPage={currentPage}
                    setCurrentPage={allCurrentPage}
                    filterHandler={() => {
                      getListHandler("trainer", 1);
                      getListHandler("trainer", 0);
                      getListHandler("trainee", 1);
                      getListHandler("trainee", 0);
                      getListHandler("manager", 1);
                      getListHandler("manager", 0);
                      getExhibitionBooth();
                      getAllExhibitionEnroll();
                    }}
                    resetFilterHandler={resetFilterHandler}
                  />
                )}
                <MainBoxHead title={t("trainee_course")} />
                <SearchPreviewTable
                  isTrainee={true}
                  isTraineeCourse={true}
                  data={traineeCourses}
                  loading={traineeCoursesLoading}
                />
                <Pagination
                  count={traineeCoursesMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    settraineeCoursesPage(page);
                  }}
                  page={traineeCoursesPage}
                />
                <MainBoxHead title={t("trainee_program")} />
                <SearchPreviewTable
                  isTrainee={true}
                  isProgram={true}
                  data={traineePrograms}
                  loading={traineeProgramsLoading}
                />
                <Pagination
                  count={traineeProgramsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    settraineeProgramsPage(page);
                  }}
                  page={traineeProgramsPage}
                />

                <MainBoxHead title={t("trainer_course")} />
                <SearchPreviewTable
                  //   isTrainee={true}
                  data={trainerCourses}
                  loading={trainerCoursesLoading}
                />
                <Pagination
                  count={trainerCoursesMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    settrainerCoursesPage(page);
                  }}
                  page={trainerCoursesPage}
                />
                <MainBoxHead title={t("trainer_program")} />
                <SearchPreviewTable
                  isProgram={true}
                  isTrainer={true}
                  data={trainerPrograms}
                  loading={trainerProgramsLoading}
                />
                <Pagination
                  count={trainerProgramsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    settrainerProgramsPage(page);
                  }}
                  page={trainerProgramsPage}
                />
                <MainBoxHead title={t("manager_course")} />
                <SearchPreviewTable
                  isManager={true}
                  data={managerCourses}
                  loading={managerCoursesLoading}
                />
                <Pagination
                  count={managerCoursesMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setmanagerCoursesPage(page);
                  }}
                  page={managerCoursesPage}
                />
                <MainBoxHead title={t("manager_program")} />
                <SearchPreviewTable
                  isProgram={true}
                  isManager={true}
                  data={managerPrograms}
                  loading={managerProgramsLoading}
                />
                <Pagination
                  count={managerProgramsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setmanagerProgramsPage(page);
                  }}
                  page={managerProgramsPage}
                />
                <MainBoxHead title={t("visits")} />
                <SearchPreviewExbiTable
                  data={Exhibitions}
                  loading={ExhibitionsLoading}
                />
                <Pagination
                  count={ExhibitionsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setExhibitionsPage(page);
                  }}
                  page={ExhibitionsPage}
                />
                <MainBoxHead title={t("Booth")} />
                <SearchPreviewExbiTable
                  isBooth={true}
                  data={Booths}
                  loading={BoothsLoading}
                />
                <Pagination
                  count={BoothsMeta?.totalPages || 0}
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setBoothsPage(page);
                  }}
                  page={BoothsPage}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
