import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { aboutUS } from "../../Services/api/toutorials/aboutUsProvider";
import { Lang } from "../../utils";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BoxCard, ContainerBox } from "../Shared/Components";
import HomeIcon from "../../assets/icons/Group 3.svg";
import classes from "./aboutUs.module.css";
import MainBox from "../Shared/Components/MainBox/MainBox";
import { useHistory } from "react-router-dom";

function About(props) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [aboutUs, setAboutUs] = useState({});
  // require("./About.css");
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    aboutUS()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setAboutUs(res.data.aboutUS);
          setIsloading(false);
        }
      })
      .catch((err) => {
        console.log("ERROR");
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ margin: "40px 0" }}>
          <div
            style={{
              display: "flex",
              gap: "15px",
              flex: "2 1 auto",
              alignItems: "center",
            }}
          >
            <img
              src={HomeIcon}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />

            <p className={classes["title"]}>{t("AboutUs")}</p>
          </div>
        </div>
        <MainBox>
          {/* <Typography
            component={"h1"}
            variant="h2"
            color={"primary.main"}
            fontWeight={500}
            mb={"32px"}
            fontFamily="dinNextRegular"
          >
            {t("TermsAndConditionsTitle")}
          </Typography> */}

          <div
            dangerouslySetInnerHTML={{
              __html: aboutUs[`description_${i18n.language}`],
            }}
            className={`text-muted me-2 fs-7 fw-semibold ${classes["about_us_content"]}`}
          ></div>
        </MainBox>{" "}
      </div>
    </>
  );
}

export default About;
