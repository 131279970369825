import React, { useContext, useEffect, useState } from "react";
import classes from "./BriefSection.module.css";
import { useTranslation } from "react-i18next";
import AboutImg from "../../../assets/image/aboutpic.png";
import Oval from "../../../assets/image/Oval.svg";
import { getAllStatistics } from "../../../Services/api/HomeCoursesProvider";
import Calender from "./Calender/Calender";
import { AuthContext } from "../../../Services/api/auth/AuthContext";

export default function BriefSection() {
  const { t, i18n } = useTranslation();
  const [statistics, setStatistics] = useState({});
  const [statisticsLoading, setStatisticsLoading] = useState({});
  const authContext = useContext(AuthContext);
  useEffect(() => {
    // setTrainersLoading(true);
    const apiCall = getAllStatistics;

    apiCall({ perPage: 10, page: 1 })
      .then((res) => {
        if (res.status && res.status === 200) {
          console.log(res);
          setStatistics(res.data);

          setStatisticsLoading(true);
        }
        setStatisticsLoading(true);
      })
      .catch((err) => {
        setStatisticsLoading(true);
        console.log(err);
      });
  }, []);
  return (
    // <div className="container-fluid" style={{ padding: 0 }}>
    <div
      className={classes.BriefSection}
      style={authContext.auth.token ? {} : { backgroundColor: "#e6e6e65d" }}
    >
      {/* <div className={classes.BriefSectionImg}>
        <img src={AboutImg} alt="" className={classes.img} />
      </div> */}
      <div className={classes.BriefSectionText}>
        <p
          style={
            i18n.language === "ar"
              ? { borderRight: "4px solid #025299", paddingInlineStart: 20 }
              : { borderLeft: "4px solid #025299", paddingInlineStart: 20 }
          }
        >
          {t("about_qassim")}
        </p>
        <span>
          هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي
          القارئ عن التركيز على الشكل الخارجي للنص او شكل تموضع الفقرات في الفحة
          التي يقرأها شكل تموضع الفقرات في الفحة التي يقرأهالفحة التي يقرأه
          الفحة التي يقرأها
        </span>
      </div>
      <div className={classes.statistcs}>
        <div style={{ textAlign: "center" }}>
          <p>{statistics.trainers}</p>
          <span>{t("admin.label.num_trainer")}</span>
        </div>
        <div style={{ textAlign: "center" }}>
          <p>{statistics.courses}</p>
          <span>{t("admin.label.num_courses")}</span>
        </div>
      </div>

      {/* <Calender /> */}
    </div>
    // </div>
  );
}
