import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import IconsTooltip from "../../../Components/tooltips/IconsTooltip";
import {
  getSurveysForLessonOrCourse,
  getSurveysPublic,
} from "../../../Services/api/survey/SurveyProvider";
import SkeletonCard from "../../Shared/Components/Spinner/SkeletonCard";

const is_loggedIn = localStorage.getItem("token") ? true : false;

export default function GeneralSurveys(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(async () => {
    let res = null;
    try {
      setIsLoading(true);
      if (is_loggedIn) {
        res = await getSurveysForLessonOrCourse(null, null);
      } else {
        res = await getSurveysPublic();
      }
      // stop the loader
      setIsLoading(false);
      // set the available surveys
      if (res && res.status && res.status == 200 && res.data.status) {
        setSurveys(res.data.surveys);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }, []);
  return (
    <div className="surveys">
      {/* <div
        className="container-fluid profile_wrapper"
        style={{ paddingTop: "40px" }}
      >
        <div className="row">
          <div className="col-12">
            <div className="admin_label">
              <NavLink to="/">{t("Home")}</NavLink>
              <p style={{ padding: "0 5px" }}>/</p>
              {t("survey.title")}
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid" style={{ marginTop: "3rem" }}>
        <div className="row">
          <div className="col-12">
            <div className={isLoading ? null : "table_bank_tran"}>
              <h3 className="table_header">{t("survey.title")}</h3>
              {isLoading ? (
                <div
                  style={{
                    height: "65vh",
                    width: "100%",
                  }}
                >
                  <SkeletonCard />
                </div>
              ) : (
                <div className="admin_input_sponsor row">
                  <div className={"container-fluid"}>
                    <div className="table-responsive tw-px-1">
                      {surveys.length == 0 ? (
                        <p style={{ textAlign: "center" }}>
                          {t("survey.notFound")}
                        </p>
                      ) : (
                        <table className="table table-bordered">
                          <thead>
                            <tr style={{ textAlign: "center" }}>
                              <th scope="col">#</th>
                              <th scope="col">{t("survey.trainee.name")}</th>
                              <th scope="col"> {t("survey.trainee.title")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {surveys.map((survey, index) => {
                              return (
                                <tr
                                  style={{ textAlign: "center" }}
                                  key={survey.id}
                                >
                                  <td scope="row">{index + 1}</td>
                                  <td>{survey.name}</td>
                                  <td className="tw-w-64">
                                    <button
                                      type="button"
                                      disabled={
                                        survey.user_answered &&
                                        (!survey.is_shared || !is_loggedIn)
                                      }
                                      onClick={() => {
                                        if (survey.user_answered) {
                                          history.push({
                                            pathname: `/survey/${survey.id}/statistics`,
                                          });
                                        } else {
                                          history.push({
                                            pathname: `/general-surveys/${survey.id}`,
                                            state: { surveyId: survey.id },
                                          });
                                        }
                                      }}
                                      className={`tw-bg-teal-700 tw-p-2 tw-rounded-lg tw-text-white disabled:tw-bg-white disabled:tw-border-2 disabled:tw-border-teal-700 disabled:tw-text-gray-700 tw-w-full`}
                                    >
                                      {t(
                                        survey.user_answered
                                          ? survey.is_shared && is_loggedIn
                                            ? "view_survey_results"
                                            : "survey_has_been_answered"
                                          : "enter_survey"
                                      )}
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
