import { useTranslation } from "react-i18next";
import { LessonContentProvider } from "../../../../../Providers/LessonContentProvider";
import HomeIcon from "../../../../../assets/icons/home.svg";
import { useParams } from "react-router";
import Breadcrumb from "../../../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../Shared/Components/MainBoxHead/MainBoxHead";
import LessonContent from "../Content/Content";
import { LessonContentContext } from "../../../../../Providers/LessonContentProvider";
import LessonHeader from "../Header/Header";
import classes from "./Canvas.module.css";
import { useLocation } from "react-router-dom";
import { useContext } from "react";

function LessonCanvas(props) {
  require("./Canvas.css");
  const { t } = useTranslation();
  const location = useLocation();
  const lessonContentContext = useContext(LessonContentContext);
  const { courseId, lessonId } = useParams();

  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("group-admin")
    ? "group-admin"
    : roles.includes("manager")
    ? "manager"
    : roles.includes("trainer")
    ? "trainer"
    : roles.includes("content_developer")
    ? "content_developer"
    : "partner";

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "course",
      page: t("Courseoutline"),
      pagePath: `${
        props.isAdmin && props.role === "group-admin"
          ? "/group-admin"
          : props.isAdmin && props.role === "manager"
          ? "/manager"
          : props.isAdmin
          ? "/admin"
          : "/trainer"
      }/course/${courseId || location?.state?.courseID || props.courseID}`,
    },
    {
      id: "lesson",
      page: props.title,
      active: true,
    },
  ];

  return (
    <>
      <LessonContentProvider>
        <LessonContent
          role={role}
          isAdmin={props.isAdmin}
          isTrainer={props.isTrainer}
          isManager={props.isManager}
          isPartner={props.isPartner}
        />
        {/* <div
          className={
            classes["course-details-container"] + " container-fluid mt-4"
          }
        >
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={lessonContentContext.lessonInfo.title || props.title}
                />
                 <LessonHeader /> 
              </MainBox>
            </div>
          </div>
        </div> */}
      </LessonContentProvider>
    </>
  );
}

export default LessonCanvas;
