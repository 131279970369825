import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../../../../assets/icons/delete.svg";
import PreviewIcon from "../../../../../../../assets/icons/eye.svg";
import PdfIcon from "../../../../../../../assets/icons/pdf.svg";
import {
  default as EditIcon,
  default as editIcon,
} from "../../../../../../../assets/icons/pen.svg";
import XlsIcon from "../../../../../../../assets/icons/xls.svg";
import i18n from "../../../../../../../i18n/i18n";
import { Lang } from "../../../../../../../utils";

export const ActivitiesPreviewActions = ({
  editAction,
  exportPdf,
  exportExcel,
  banAllAction,
  publishAllAction,
}) => {
  const { t } = useTranslation();
  const actionsList = [
    ...(banAllAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #8599B6",
              borderRadius: 6,
              height: 50,
            },
            label:
              i18n.language === "ar"
                ? "حظر جميع التعليقات"
                : "Add an introductory video",
            btnTextColor: "#8599B6",
            action: banAllAction,
          },
        ]
      : []),
    ...(publishAllAction
      ? [
          {
            id: "introVideo",
            type: "button",
            outline: true,
            style: {
              border: "2px solid #24b3b9",
              borderRadius: 6,
              height: 50,
            },
            label:
              i18n.language === "ar"
                ? "نشر جميع التعليقات"
                : "Add an introductory video",
            btnTextColor: "#24b3b9",
            action: publishAllAction,
          },
        ]
      : []),
    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: editAction,
            toolTip: i18n.language === Lang.AR ? "تعديل" : "Edit",
            theme: "edit",
          },
        ]
      : []),

    ...(exportPdf
      ? [
          {
            id: "pdf",
            icon: <img src={PdfIcon} alt="" />,
            theme: "pdf",
            action: exportPdf,
          },
        ]
      : []),
    ...(exportExcel
      ? [
          {
            id: "xls",
            icon: <img src={XlsIcon} alt="" />,
            theme: "xls",
            action: exportExcel,
          },
        ]
      : []),
  ];
  return actionsList;
};
