import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  addAdvertisement,
  getNeededDataCreateAdvertisement,
} from "../../../Services/api/Advertisement/AdProvider";
import AdvertisementsForm from "./advertisementsForm.jsx/AdvertisementsForm";

export default function AddAdvertisement() {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);
  const [audience_types, setAudience_types] = useState([]);
  useEffect(() => {
    getNeededDataCreateAdvertisement()
      .then((res) => {
        if (res.status === 200) {
          setAudience_types(res.data.data.audience_types);
          setTypes(res.data.data.types);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submitHandler = async (formData) => {
    try {
      const response = await addAdvertisement(formData);
      if (response.status === 201) {
        localStorage.getItem("type") === "manager"
          ? history.push("/manager/advertisements-list")
          : history.push("/admin/advertisements-list");
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("add_success")}
          </span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return (
    <AdvertisementsForm
      submitHandler={submitHandler}
      types={types}
      audience_types={audience_types}
    />
  );
}
