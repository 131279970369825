import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BanAllActivityComment,
  PublishAllActivityComment,
  addActivityComment,
  deleteActivityComment,
  fetchActivityCommentsList,
} from "../../../../../../../Redux/Actions/activityComment/activityComment.action";
import toLocalTimezone from "../../../../../../../Services/Timezone";
import { exportLessonDiscussionComments } from "../../../../../../../Services/api/DiscussionBoard/DiscussionProvider";
import { ReactComponent as CalendarIcon } from "../../../../../../../assets/icons/calendar-icon.svg";
import HomeIcon from "../../../../../../../assets/icons/home.svg";
import { Lang } from "../../../../../../../utils";
import AddNewItem from "../../../../../../Shared/Components/AddNewItem/AddNewItem";
import MainBox from "../../../../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../../../../Shared/Components/Modal/BasicModal";
import SkeletonCardOverlay from "../../../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import DiscFormModal from "../../../../discBoard/DiscFormModal/DiscFormModal";
import { ActivitiesPreviewActions } from "./ActivitiesPreviewActions";
import ActivitiyCommentBox from "./ActivitiyCommentBox";
import CommentForm from "./CommentForm";
import classes from "./activities.module.css";

function Activity(props) {
  require("./Activity.css");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const editDiscModalRef = useRef();
  const { activityCommentsList, activity } = useSelector(
    (state) => state.activityCommentsListReducer
  );

  const [addNewComment, setAddNewComment] = useState(false);
  const [openEditDiscModal, setOpenEditDiscModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const { addActivityCommentLoading, activityCommentsListLoading } =
    useSelector((state) => state.activityCommentsListReducer);

  const addCommentToLesson = (payload) => {
    if (!addActivityCommentLoading) {
      dispatch(addActivityComment(payload, getCommentsListHandler));
      // getCommentsListHandler();
    }
  };

  const handlePublishAll = () => {
    dispatch(PublishAllActivityComment(id));
  };
  const handleBanAll = () => {
    dispatch(BanAllActivityComment(id));
  };

  const getCommentsListHandler = () => {
    dispatch(
      fetchActivityCommentsList({
        id: id,
        // page: currentPage,
        // perPage: 10,
        // filter,
      })
    );
  };

  useEffect(() => {
    getCommentsListHandler();
  }, []);
  useEffect(() => {}, [deleteActivityComment]);
  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("trainee.lesson.activity.title"),
      pagePath: "#",
    },
    {
      id: `content_development_tax`,
      page: activityCommentsList?.name,
      pagePath: "#",
      active: true,
    },
  ];
  const exportHandler = async (type) => {
    setExportLoading(true);
    try {
      await exportLessonDiscussionComments(id, type);
      setExportLoading(false);
    } catch (e) {
      setExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  const openEditDiscModalHandler = () => {
    setOpenEditDiscModal(true);
    editDiscModalRef.current.showModal();
  };

  const closeEditDiscModalHandler = () => {
    setOpenEditDiscModal(false);
    editDiscModalRef.current.dismissModal();
  };

  return (
    <>
      <div className={classes["activities-list"]}>
        <div className="container-fluid">
          {exportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
          {addActivityCommentLoading && (
            <SkeletonCardOverlay skeletonWidth="100" />
          )}
          {activityCommentsListLoading && (
            <SkeletonCardOverlay skeletonWidth="100" />
          )}
          <div className={classes["activities-list__container"]}>
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <div>
                    <div className="tw-flex tw-items-center tw-mb-4">
                      <button
                        className={classes["activities-list__back-button"]}
                        onClick={() => history.goBack()}
                      >
                        <i
                          className={`fas fa-chevron-${
                            i18n.language === Lang.AR ? "right" : "left"
                          } tw-text-black tw-mt-2`}
                        ></i>
                        <span>{t("back")}</span>
                      </button>
                    </div>
                    <MainBoxHead
                      title={activity?.name}
                      actions={ActivitiesPreviewActions({
                        ...(props.isAdmin || props.isTrainer || props.isManager
                          ? {
                              publishAllAction: () => handlePublishAll(),
                              banAllAction: () => handleBanAll(),
                              editAction: openEditDiscModalHandler,
                              exportPdf: () => exportHandler("pdf"),
                              exportExcel: () => exportHandler("xlsx"),
                            }
                          : {}),
                      })}
                    />
                  </div>
                  <div className="tw-space-y-2">
                    <div
                      className="d-flex align-items-center"
                      style={{
                        gap: "30px",
                      }}
                    >
                      <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                        {t("trainer.course.lesson.activity.activity_details")}
                      </div>
                    </div>
                    <div className="tw-p-4 border">
                      <div className="tw-flex tw-items-center tw-space-s-2 tw-text-teal-500">
                        <CalendarIcon className="tw-h-4" />
                        <div>
                          {`${moment(toLocalTimezone(activity.created_at))
                            .local(true)
                            .format("DD/MM/YYYY")} ${t("the_hour")}
                    ${moment(toLocalTimezone(activity?.created_at))
                      .local(true)
                      .format("hh:mm A")}`}
                        </div>
                      </div>
                      <div
                        className="tw-text-gray-400 tw-text-sm tw-p-5"
                        dangerouslySetInnerHTML={{ __html: activity?.desc }}
                      ></div>
                    </div>
                  </div>

                  <div id="replies-container">
                    <div className="tw-text-teal-600 tw-font-bold tw-mb-2">
                      <span>{t("trainer.course.lesson.activity.coments")}</span>
                      {activity?.interactions_agree && (
                        <span className={classes["comment_approve_msg"]}>
                          {" "}
                          {`( ${t("comment_need_aprove_msg")} )`}
                        </span>
                      )}
                    </div>
                    {props.isPartner ||
                    props.isContentDev ||
                    props.preview ? null : (
                      <AddNewItem
                        title={t("trainer.course.lesson.activity.add_comment")}
                        action={{
                          onClick: () => setAddNewComment(!addNewComment),
                        }}
                      />
                    )}
                    {addNewComment && (
                      <CommentForm
                        handleClose={() => setAddNewComment(!addNewComment)}
                        isComment={true}
                        addCommentHandler={addCommentToLesson}
                      />
                    )}
                    <div
                      className="tw-pe-4 tw-space-y-4"
                      // style={{ maxHeight: "80vh" }}
                    >
                      {activityCommentsList?.map((item) =>
                        props.isAdmin || props.isManager || props.isTrainer ? (
                          <ActivitiyCommentBox
                            item={item}
                            isAdmin={props.isAdmin}
                            isTrainer={props.isTrainer}
                            isManager={props.isManager}
                            isPartner={props.isPartner}
                            isContentDev={props.isContentDev}
                            preview={props.preview}
                          />
                        ) : item.is_published ? (
                          <ActivitiyCommentBox
                            item={item}
                            isAdmin={props.isAdmin}
                            isTrainer={props.isTrainer}
                            isManager={props.isManager}
                            isPartner={props.isPartner}
                            isContentDev={props.isContentDev}
                            preview={props.preview}
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>

                  <BasicModal ref={editDiscModalRef}>
                    {openEditDiscModal && (
                      <DiscFormModal
                        title={t("trainer.course.lesson.activity.edit")}
                        activityId={id}
                        dismissModal={closeEditDiscModalHandler}
                        chapter={activity}
                        getCommentsListHandler={getCommentsListHandler}
                      />
                    )}
                  </BasicModal>
                </MainBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Activity;
