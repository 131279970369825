import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import QuestionTypeEnum, { QuestionTypeWithFile, QuestionTypeWithOptions } from "../../../../../Enums/QuestionTypeEnum";
import EvaluationApi from "../../../../../Services/api/evaluation";
import { getCourseObjectives } from "../../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/crose.svg";
import { ReactComponent as DocumentIcon } from "../../../../../assets/icons/document.svg";
import { ReactComponent as FileIcon } from "../../../../../assets/icons/file.svg";
import i18n from "../../../../../i18n/i18n";
import { Lang } from "../../../../../utils";
import { SunEditorText } from "../../../../Shared/Components";
import FilesDragAndDropInput from "../../../../Shared/Components/FilesDragAndDropInput/FilesDragAndDropInput";
import QuestionTypes from "../../QuestionTypes";
import QuestionValidator, { getQuestionFileTypes } from "./QuestionValidator";
export default function QuestionForm({ onSubmit, courseId, questionData, questionTypes, disabled = false }) {
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [evaluationList, setEvaluationList] = useState([]);
  const [evaluationFetching, setEvaluationFetching] = useState(false);

  const [levels, setLevels] = useState([
    {
      value: 1,
      label: t("trainer.quiz.crud.easy"),
    },
    {
      value: 2,
      label: t("trainer.quiz.crud.medium"),
    },
    {
      value: 3,
      label: t("trainer.quiz.crud.hard"),
    },
  ]);

  useEffect(() => {
    fetchStandards({});
  }, []);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      getCourseObjectives(courseId, inputValue).then((res) => {
        resolve(res.data.data.objectives.data);
      });
    });

  const fetchStandards = async (values) => {
    setEvaluationFetching(true);
    setEvaluationList([]);
    EvaluationApi.list(1, 10, {
      search: values.search,
      includes: values.standards?.map((i) => i.id),
      course_id: courseId,
    }).then((res) => {
      setEvaluationFetching(false);
      setEvaluationList(res.data.data.data.filter((item) => item.status == 1));
    });
  };

  return (
    <Formik
      initialValues={{
        type_id: questionData?.type_id ?? "",
        title: questionData?.title ?? "",
        text: questionData?.text ?? "",
        media: questionData?.media ?? "",
        degree: questionData?.degree ?? "",
        level: questionData?.level ?? "",
        course_objectives: questionData?.course_objectives ? questionData.course_objectives : [],
        // feedback: questionData?.feedback ?? "",
        standards: questionData?.standards ? questionData.standards : [],
        options: questionData?.options ? questionData.options : [],
        answers: questionData?.answers ? questionData.answers : [],
        search: "",
      }}
      onSubmit={async (values) => {
        try {
          let payload = { ...values };
          delete payload.search;
          if (
            ![...QuestionTypeWithOptions, QuestionTypeEnum.hotspot, QuestionTypeEnum.drag_and_drop].includes(
              values.type_id
            )
          )
            payload.options = [];
          if (questionData?.id) payload.id = questionData.id;
          await onSubmit(payload);
        } catch (err) {
          toast.error(
            <>
              <span style={{ fontSize: 13, fontWeight: "bold", display: "block" }}>{err.response.data.msg}</span>
              {err.response.data.errors && (
                <ul>
                  {Object.keys(err.response.data.errors).map((key) => {
                    return (
                      <li>
                        <span style={{ fontSize: 11 }}>{err.response.data.errors[key]}</span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          );
        }
      }}
      validate={(values) => {
        setHasSubmitted(true);

        let errors = QuestionValidator(values, t);

        return errors;
      }}
      validateOnChange={hasSubmitted}
    >
      {({
        setFieldValue,
        setValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="tw-space-y-8">
            <div className="tw-space-y-3">
              {/* type */}
              <div>
                <label className="tw-block">
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.question_type")}</div>

                  <Select
                    id="type_id"
                    isClearable
                    defaultValue={questionTypes.find((item) => item.id == values.type_id)}
                    options={questionTypes}
                    isDisabled={disabled}
                    getOptionValue={(item) => item.id}
                    getOptionLabel={(item) => (i18n.language == Lang.AR ? item.name_ar : item.name_en)}
                    onChange={(item) => {
                      if (item) {
                        setFieldValue("type_id", item.id);
                        setValues({
                          ...values,
                          type_id: item.id,
                          options: values.options.map((option) => ({
                            text: option.text,
                            value: "",
                          })),
                        });
                      }
                    }}
                    placeholder={t("trainer.quiz.crud.select.question_type")}
                  />
                </label>

                <ErrorMessage name={`type_id`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
              </div>

              {/* title field */}
              <div>
                <label className="tw-block">
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.question_title")}</div>

                  <Field
                    name="title"
                    type="text"
                    disabled={disabled}
                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                    placeholder={t("trainer.quiz.crud.input.question_title")}
                    maxLength={5000}
                  />
                </label>
                <ErrorMessage name={`title`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
              </div>

              {/* text field */}
              <div>
                <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.question_text")}</div>

                <SunEditorText
                  value={values.text}
                  setValue={(value) => {
                    setFieldValue("text", value);
                  }}
                  disabled={disabled}
                  dirEditor={"rtl"}
                  maxLength={5000}
                />

                <ErrorMessage name={`text`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
              </div>
              {[...QuestionTypeWithFile, QuestionTypeEnum.hotspot].includes(values.type_id) ? (
                <>
                  {/* media */}
                  <FilesDragAndDropInput
                    id={"media"}
                    label={t("trainer.quiz.crud.choose_file")}
                    placeholder={t("trainer.quiz.crud.choose_file")}
                    buttonTitle={<FileIcon className="tw-w-4 tw-h-4 tw-mx-2 tw-fill-white tw-stroke-white" />}
                    name={"media"}
                    disabled={disabled}
                    accept={getQuestionFileTypes(values.type_id).join(",")}
                    error={errors.media}
                    value={values.media}
                    onChange={(files) => files[0] && setFieldValue("media", files[0], true)}
                  />

                  {values.media ? (
                    <div className="tw-inline-flex tw-max-w-full tw-items-center tw-space-s-4 tw-bg-gray-100 tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-mt-2 tw-w-auto">
                      <div className="tw-grow tw-min-w-0 tw-flex tw-items-center tw-space-s-2">
                        {errors.media ? (
                          <div className="tw-max-w-full tw-text-xs tw-text-red-700 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                            {errors.media}
                          </div>
                        ) : (
                          <>
                            <div className="tw-p-2.5 tw-rounded-full tw-bg-gray-200">
                              <DocumentIcon className="tw-h-4 tw-w-4" />
                            </div>

                            <div className="tw-max-w-full tw-text-teal-700 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                              {values.media.name ? values.media.name : values.media.split("/").at(-1)}
                            </div>
                          </>
                        )}
                      </div>
                      <button
                        onClick={() => setFieldValue("media", "", true)}
                        type="button"
                        className="tw-text-gray-600 tw-w-5 tw-h-5 tw-shrink-0"
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* degree */}
              <div>
                <label className="tw-block">
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.grade")}</div>

                  <Field
                    name="degree"
                    type="number"
                    disabled={disabled}
                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                    placeholder={t("trainer.quiz.crud.input.grade")}
                    min={0}
                  />
                </label>
                <ErrorMessage name={`degree`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
              </div>

              {/* level */}
              <div>
                <label className="tw-block">
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.level")}</div>

                  <Select
                    id="level"
                    isClearable
                    defaultValue={levels.find((item) => item.value == values.level)}
                    isDisabled={disabled}
                    options={levels}
                    onChange={(item) => {
                      if (item) {
                        setFieldValue("level", item.value);
                      }
                    }}
                    placeholder={t("trainer.quiz.crud.select.level")}
                  />
                </label>

                <ErrorMessage name={`level`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
              </div>

              {/* objectives */}
              <div>
                <label className="tw-block">
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("trainer.quiz.crud.objectives")}</div>
                  <AsyncSelect
                    placeholder={t("trainer.quiz.crud.select.objectives")}
                    cacheOptions
                    defaultOptions
                    isClearable
                    defaultValue={questionData?.course_objectives}
                    isDisabled={disabled}
                    loadOptions={promiseOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    isMulti
                    onChange={(items) => {
                      setFieldValue("course_objectives", items);
                    }}
                  />
                </label>

                <ErrorMessage
                  name={`course_objectives`}
                  component="div"
                  className="tw-text-xs tw-text-red-700 tw-h-4"
                />
              </div>

              {/* feedback */}
              {/* <div>
                <label className="tw-block">
                  <div className="tw-text-gray-600 tw-font-medium tw-mb-2">
                    {t("trainer.quiz.crud.negative_feedback")}
                  </div>

                  <Field
                    name="feedback"
                    type="number"
                    min={0}
                    className="tw-block tw-w-full tw-border tw-border-gray-200 tw-rounded tw-p-2.5 tw-text-gray-500"
                    placeholder={t("trainer.quiz.crud.input.negative_feedback")}
                  />
                </label>
                <ErrorMessage
                  name={`feedback`}
                  component="div"
                  className="tw-text-xs tw-text-red-700 tw-h-4"
                />
              </div> */}

              <QuestionOptions
                values={values}
                errors={errors}
                disabled={disabled}
                setFieldValue={setFieldValue}
                setValues={setValues}
              />
              {[QuestionTypeEnum.essay_question, QuestionTypeEnum.text].includes(values.type_id) ? (
                <div>
                  <div className="tw-flex tw-items-center tw-space-s-4 tw-mb-2">
                    <div className="tw-text-gray-600 tw-font-medium">{t("answerCriteria.table_title")}</div>
                  </div>
                  <FieldArray name="standards">
                    {({ insert, remove, push }) => (
                      <div className="tw-border tw-rounded tw-divide-y">
                        <div className="tw-bg-gray-100 tw-p-4 md:tw-flex tw-items-center tw-justify-between">
                          <div className="tw-flex tw-items-center tw-space-s-4">
                            <div className="tw-font-semibold tw-text-gray-500">{t("select_standards")}</div>
                            <div className="tw-text-red-500 tw-text-sm">
                              {t("total_active_weights")}:{" "}
                              {100 - values.standards.reduce((pre, next) => pre + parseInt(next.weight), 0)}%
                            </div>
                          </div>
                          <div className="tw-flex tw-items-center tw-space-s-8">
                            <Field
                              name="search"
                              disabled={disabled}
                              className="tw-w-full tw-p-2.5 tw-border-s tw-bg-transparent"
                              placeholder={t("search.label")}
                              onChange={({ target: { value } }) => {
                                setFieldValue("search", value);
                                fetchStandards({
                                  ...values,
                                  search: value,
                                });
                              }}
                            />
                            <li className="fal fa-search tw-text-teal-500 tw-w-6"></li>
                          </div>
                        </div>
                        <div className="tw-py-4 tw-pe-4">
                          <div className="tw-divide-y tw-divide-black/5 tw-overflow-y-auto inner-scrollbar tw-max-h-[40vh] tw-pe-4">
                            {evaluationFetching ? (
                              [...Array(5).keys()].map((i) => (
                                <div
                                  key={i}
                                  className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full tw-text-gray-500 tw-animate-pulse`}
                                >
                                  <div className="tw-flex tw-items-center tw-space-s-4">
                                    <div
                                      className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded tw-border-gray-300`}
                                    ></div>
                                    <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-40"></div>
                                  </div>
                                  <div className="tw-bg-gray-200 tw-rounded-full tw-h-3 tw-my-1.5 tw-w-60"></div>
                                </div>
                              ))
                            ) : evaluationList.length ? (
                              evaluationList.map((standard) => (
                                <button
                                  key={standard.id}
                                  disabled={
                                    100 - values.standards.reduce((pre, next) => pre + parseInt(next.weight), 0) <
                                      standard.weight && !values.standards.find((i) => i.id == standard.id)
                                  }
                                  type="button"
                                  onClick={
                                    disabled
                                      ? null
                                      : () => {
                                          const standardIndex = values.standards.findIndex((i) => i.id == standard.id);
                                          if (standardIndex != undefined && standardIndex >= 0) remove(standardIndex);
                                          else push(standard);
                                        }
                                  }
                                  className={`md:tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-8 tw-w-full disabled:tw-opacity-50`}
                                >
                                  <div className="tw-flex tw-items-center tw-space-s-4">
                                    <div
                                      className={`tw-h-4 tw-w-4 tw-shrink-0 tw-border-[1px] tw-rounded  ${
                                        values.standards.find((item) => item.id == standard.id)
                                          ? "tw-border-gray-100 tw-border-[3px] tw-bg-teal-600"
                                          : "tw-border-gray-300"
                                      }`}
                                    ></div>
                                    <div>{standard.title}</div>
                                  </div>
                                  <div className="tw-text-teal-500 tw-text-sm">{standard.weight}%</div>
                                </button>
                              ))
                            ) : (
                              <div className="tw-text-gray-500 tw-text-lg tw-p-8 tw-text-center">
                                {t("there_no_matching_users")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  <ErrorMessage name={`standards`} component="div" className="tw-text-xs tw-text-red-700 tw-h-4" />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="tw-flex tw-items-stretch tw-justify-end tw-space-s-4">
              <button
                type="submit"
                disabled={isSubmitting || disabled}
                className="tw-bg-teal-700 disabled:tw-bg-teal-700/50 tw-py-2 tw-px-16 tw-rounded tw-text-white"
              >
                {t("ok")}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

function QuestionOptions({ values, errors, setFieldValue, setValues, disabled = false }) {
  const { t } = useTranslation();
  const questionType = QuestionTypes.find((item) => item.type == values.type_id);

  return (
    <>
      {values.type_id && [...QuestionTypeWithOptions, QuestionTypeEnum.hotspot].includes(values.type_id) ? (
        <div>
          <div className="tw-text-gray-600 tw-font-medium tw-mb-2">{t("answers")}</div>
          <questionType.formComponent
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            disabled={disabled}
            setValues={setValues}
          />
          {errors.options && typeof errors.options == "string" ? (
            <div className="tw-text-xs tw-text-red-700 tw-h-4">{errors.options}</div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
