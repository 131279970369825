import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { aboutUS } from "../../../../Services/api/toutorials/aboutUsProvider";
import master from "../../../../assets/image/footer-master.png";
import visa from "../../../../assets/image/footer-visa.png";
import tebxLogo from "../../../../assets/image/logo.png";
import Instagram from "../../../../assets/icons/Instgram_Q.svg";
import Facebook from "../../../../assets/icons/Facebook_Q.svg";
import Twitter from "../../../../assets/icons/Twitter_Q.svg";
import LinkedIn from "../../../../assets/icons/LinkedIn_Q.svg";
import Qassem_logo from "../../../../assets/image/Qassem_logo.svg";
// import Qassem_logo from "../../../../assets/image/Qassem_logo2.png";

function Footer(props) {
  require("./footer.css");
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const [aboutAs, setAboutAs] = useState("");

  useEffect(() => {
    aboutUS().then((res) => {
      if (res.status && res.status === 200 && res.data.status) {
        setAboutAs(res.data.aboutUS);
      }
    });

    let role = localStorage.getItem("type");
  }, []);

  return (
    <>
      <footer
        className={
          props.query === "site" ? "footer_content_hide" : "footer_content"
        }
        // style={{ marginTop: "30px" }}
      >
        <div className="container-fluid">
          {/* <div className="logo-flex">
            
            <img src={tebxLogo} alt="" />
          </div> */}
          <div className="footer-grid">
            <div className="">
              {/* <div
                style={{
                  marginBottom: 26,
                  maxWidth: "100%",
                  wordBreak: "break-word",
                }}
              >
                <h3
                  style={{
                    marginBottom: 25,
                    color: "#707070",
                  }}
                >
                  {t("about_Qassim")}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${
                      aboutAs
                        ? i18n.language === "ar"
                          ? aboutAs.description_ar.slice(0, 200) + " ..."
                          : aboutAs.description_en.slice(0, 133) + " ..."
                        : ""
                    }`,
                  }}
                ></p>
              </div> */}
              <div className="logo_footer_qassem">
                <img src={Qassem_logo} alt="" className="footer_qassim_logo" />
              </div>
            </div>
            <div className="">
              <h3
                style={{
                  marginBottom: 25,
                  color: "rgb(69 69 69)",
                  fontWeight: "bold",
                }}
              >
                {t("main_links")}
              </h3>
              <div className="">
                <div className="">
                  <ul className="link_list_footer">
                    <li>
                      <NavLink className="NavLink" to="/training-courses">
                        {t("courses")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/training-programs">
                        {t("program")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/trainers">
                        {t("Trainers")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="NavLink"
                        to={
                          localStorage.getItem("type") === "admin"
                            ? "/admin/tickets"
                            : "/tickets"
                        }
                      >
                        {t("admin.label.list_tickets")}
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink className="NavLink" to="/publish-request">
                        {t("course_publish_request")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/faq/faq-list">
                        {t("FAQ.common_questions")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/about-us">
                        {t("AboutUs")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/terms-and-conditions">
                        {t("TermsAndConditions")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/contactUs">
                        {t("ConnectUs")}
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div>
                <h5
                  style={{
                    color: "rgb(69 69 69)",
                    marginTop: 25,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {t("Follow_us")}{" "}
                </h5>
                <ul className="social_media">
                  <li>
                    <a>
                      <img src={Instagram} alt="" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={LinkedIn} alt="" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="">
              <h3
                style={{
                  marginBottom: 25,
                  color: "rgb(69 69 69)",
                  fontWeight: "bold",
                }}
              >
                {t("other_links")}
              </h3>
              <div className="">
                <div className="">
                  <ul className="link_list_footer">
                    {/* <li>
                      <NavLink className="NavLink" to="/training-courses">
                        {t("courses")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/trainers">
                        {t("Trainers")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="NavLink"
                        to={
                          localStorage.getItem("type") === "admin"
                            ? "/admin/tickets"
                            : "/tickets"
                        }
                      >
                        {t("admin.label.list_tickets")}
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink className="NavLink" to="/publish-request">
                        {t("course_publish_request")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="NavLink" to="/faq/faq-list">
                        {t("FAQ.common_questions")}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink className="NavLink" to="/contactUs">
                        {t("ConnectUs")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h5
                  style={{
                    color: "rgb(69 69 69)",
                    marginBottom: 20,
                    marginTop: 20,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {t("location_title")}{" "}
                </h5>
                <p style={{ color: "#707070" }}>
                  المملكة العربية السعودية منطقة القصيم الموليدة شمال مطار
                  الأمير نايف بن عبد العزيز الدولي
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div
        className={props.query === "site" ? "footer_hide" : "footer"}
        style={{
          marginTop: 0,
        }}
      >
        <div className="footer_nav">
          <div className="container-fluid">
            <div className="row">
              <>
                <div className="footer_link">
                  <div className="footer_nav_copy">
                    {t("AllRightsReservedToQassimSite")}
                    {new Date().getFullYear()}
                  </div>
                  <div className="footer_nav_link">
                    <NavLink className="NavLink" to="/about-us">
                      {t("AboutUs")}
                    </NavLink>

                    <NavLink className="NavLink" to="/terms-and-conditions">
                      {t("TermsAndConditions")}
                    </NavLink>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
