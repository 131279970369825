import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LessonTypes from "../../../../../../Enums/LessonTypeEnum";
import LessonTypeAcceptanceFactory from "../../../../../../Helpers/LessonTypeAcceptanceFactory";
import AddActivityToLessonModal from "../../../../../../Modals/AddActivityToLessonModal/AddActivityToLessonModal";
import AddDirectoryToLessonModal from "../../../../../../Modals/AddDirectoryToLessonModal/AddDirectoryToLessonModal";
import AddFileToLessonModal from "../../../../../../Modals/AddFileToLessonModal/AddFileToLessonModal";
import AddSessionToLessonModal from "../../../../../../Modals/AddSessionToLessonModal/AddSessionToLessonModal";
import AddTextToLessonModal from "../../../../../../Modals/AddTextToLessonModal/AddTextToLessonModal";
import AddVideoLinkToLessonModal from "../../../../../../Modals/AddVideoLinkToLessonModal/AddVideoLinkToLessonModal";
import { LessonContentContext } from "../../../../../../Providers/LessonContentProvider";
import { ListTypes, sessionPreview } from "../../../../../../Services/api/Zoom/ZoomProvider";
import { downloadFolderFromLesson, reorderLessonPlans } from "../../../../../../Services/api/courses/courseProvider";
import AddNewItem from "../../../../../Shared/Components/AddNewItem/AddNewItem";
import CustomButton from "../../../../../Shared/Components/CustomButton/CustomButton";
import AddZoomSession from "../../../Lesson/AddZoomSession/AddZoomSession";
import ListLessonActivities from "../Activities/List/List";
import PlanItem from "../Activities/List/PlanItem";
import LessonFile from "../File/File";

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import AddExhibitionPlanToLessonModal from "../../../../../../Modals/AddExhibitionPlanToLessonModal/AddExhibitionPlanToLessonModal";
import toLocalTimezone from "../../../../../../Services/Timezone";
// import { deleteExhibitionPlan } from "../../../../../../Services/api/Exhibition/ExhibitionProvider";
import { deleteExam } from "../../../../../../Services/api/exams/ExamsProvider";

function FileBrowser(props) {
  require("./Browser.css");
  const { t } = useTranslation();
  const history = useHistory();
  const [orderingTimeout, setOrderingTimeout] = useState(null);

  const { lessonId } = useParams();

  const lessonContentContext = useContext(LessonContentContext);
  const handleCloseModal = () => lessonContentContext.setIsModalOpen(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const onFolderDownload = async (event, name, id) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      lessonContentContext.setIsPerformingBackgroundOperation(true);
      await downloadFolderFromLesson(name, id);
      lessonContentContext.setIsPerformingBackgroundOperation(false);
    } catch (error) {
      lessonContentContext.setIsPerformingBackgroundOperation(false);
    }
  };

  const openDirectoryCreationModal = () => {
    lessonContentContext.setTargetModalContent(
      <AddDirectoryToLessonModal
        dismissModal={handleCloseModal}
        title={t(`trainer.course.lesson.directory.addButton`)}
      />
    );
    lessonContentContext.setIsModalOpen(true);
  };

  const openFileUploadModal = (type, id, data, isActivities, setPreview, isContentScorm) => {
    let accept = LessonTypeAcceptanceFactory(type);
    lessonContentContext.setTargetModalContent(
      <AddFileToLessonModal
        isActivities={isActivities}
        isContentScorm={isContentScorm}
        data={data}
        type={type}
        parentID={id}
        accept={accept}
        setPreview={setPreview}
        dismissModal={handleCloseModal}
        title={t(`trainer.course.lesson.${type}.addButton`)}
      />
    );
    lessonContentContext.setIsModalOpen(true);
  };

  const openTextUploadModal = (id, data) => {
    lessonContentContext.setTargetModalContent(
      <AddTextToLessonModal
        data={data}
        dismissModal={handleCloseModal}
        title={t(`trainer.course.lesson.add_lesson_content`)}
        parentID={id}
      />
    );
    lessonContentContext.setIsModalOpen(true);
  };

  const openVideoLinkUploadModal = (id, data) => {
    lessonContentContext.setTargetModalContent(
      <AddVideoLinkToLessonModal
        data={data}
        dismissModal={handleCloseModal}
        title={t(`trainer.course.lesson.video.addButton`)}
        parentID={id}
      />
    );
    lessonContentContext.setIsModalOpen(true);
  };

  const openActivityCreationModal = () => {
    lessonContentContext.setTargetModalContent(
      <AddActivityToLessonModal
        dismissModal={handleCloseModal}
        title={t(`trainer.course.lesson.add_activity`)}
        isInLesson={true}
      />
    );
    lessonContentContext.setIsModalOpen(true);
  };

  // const openExhibitionPlanCreationModal = () => {
  //   lessonContentContext.setTargetModalContent(
  //     <AddExhibitionPlanToLessonModal
  //       role={props.role}
  //       dismissModal={handleCloseModal}
  //       title={t(`add_virtual_exhibition`)}
  //     />
  //   );
  //   lessonContentContext.setIsModalOpen(true);
  // };

  const [dataPreview, setDataPreview] = useState([]);
  const [typesZoom, setTypesZoom] = useState([]);

  async function executeOnLoad(id) {
    let response = await sessionPreview(id);
    await lessonContentContext.fetchLessonContent(id);
    if (response.status && response.status === 200) {
      setDataPreview(response.data.data);
    }
    let response_types = await ListTypes();
    if (response_types.status && response_types.status === 200) {
      setTypesZoom(response_types.data.data);
    }
  }

  const openSessionCreationModal = ({ title, data }) => {
    lessonContentContext.setTargetModalContent(
      <AddSessionToLessonModal
        dismissModal={handleCloseModal}
        title={title}
        data={data}
        executeOnLoad={executeOnLoad}
      />
    );
    lessonContentContext.setIsModalOpen(true);
  };
  const [clickedLessonFile, setClickedLessonFile] = useState(0);
  const onLessonFileClickProxy = (id) => {
    setClickedLessonFile(id);
    props.onLessonFileClick(id);
  };

  const lessonsTabs = [
    {
      id: 0,
      title: t("trainer.course.lesson.content"),
      type: "content",
      component: ({ planable }) => (
        <LessonFile
          file={planable}
          key={planable.id}
          id={planable.id}
          type={planable.type}
          name={planable.title}
          isTrainer={props.isTrainer}
          isAdmin={props.isAdmin}
          isManager={props.isManager}
          isPartner={props.isPartner}
          onFileClick={onLessonFileClickProxy}
          isClicked={planable.id === clickedLessonFile}
          extension={planable.extension}
          openFileUploadModal={openFileUploadModal}
          openTextUploadModal={openTextUploadModal}
          openVideoLinkUploadModal={openVideoLinkUploadModal}
        ></LessonFile>
      ),
    },
    {
      title: t("trainer.course.lesson.activities"),
      type: "activity",
      component: ({ planable }) => (
        <LessonFile
          key={planable.id}
          file={planable}
          id={planable.id}
          type={planable.type}
          name={planable.title}
          isTrainer={props.isTrainer}
          isAdmin={props.isAdmin}
          isManager={props.isManager}
          isPartner={props.isPartner}
          onFileClick={onLessonFileClickProxy}
          isClicked={planable.id === clickedLessonFile}
          extension={planable.extension}
          openFileUploadModal={openFileUploadModal}
          openTextUploadModal={openTextUploadModal}
          openVideoLinkUploadModal={openVideoLinkUploadModal}
          isActivities={true}
        ></LessonFile>
      ),
    },
    {
      title: t("trainer.course.lesson.sessions"),
      type: "meeting",
      component: ({ planable }) => (
        <AddZoomSession
          isAdmin={props.isAdmin}
          isTrainer={props.isTrainer}
          isManager={props.isManager}
          isPartner={props.isPartner}
          openSessionCreationModal={openSessionCreationModal}
          meeting={planable}
          typesZoom={typesZoom}
          // executeOnLoad={executeOnLoad}
        />
      ),
    },
    {
      title: t("trainer.course.lesson.discussions"),
      type: "discussions",
      component: ({ planable }) => (
        <ListLessonActivities
          isAdmin={props.isAdmin}
          isTrainer={props.isTrainer}
          isManager={props.isManager}
          isPartner={props.isPartner}
          discussion={planable}
        />
      ),
    },
    {
      title: t("trainer.course.lesson.exams"),
      type: "quiz",
      component: ({ planable: quiz }) => (
        <PlanItem
          id={quiz.id}
          title={quiz.title}
          onView={() => {
            if (lessonContentContext.lessonInfo.id)
              history.push(
                `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/assessment/${quiz.id}`
              );
          }}
          onEdit={
            !props.isPartner
              ? () => {
                  if (lessonContentContext.lessonInfo.id)
                    history.push(
                      `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/assessment/edit/${quiz.id}`
                    );
                }
              : null
          }
          onDelete={
            !props.isPartner
              ? async (targetId) => {
                  try {
                    const res = await deleteExam(targetId);
                    if (res.status && res.status == 200 && res.data.status) {
                      toast.success(<span style={{ fontSize: 13, fontWeight: "bold" }}>{res.data?.msg}</span>);
                    } else {
                      throw res;
                    }
                  } catch (error) {
                    console.error(error);
                    toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{error.data?.msg}</span>);
                  }
                }
              : null
          }
          content={
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <div>
                <span className="tw-font-bold">{t("attempts_number")}&nbsp;</span>
                {quiz.answer_attempts}
              </div>
              <div>
                <span className="tw-font-bold">{t("pass_percentage")}&nbsp;</span>
                {Math.round(parseFloat(quiz.success_rate) * 100) / 100}%
              </div>
              {/* <div>
                <span className="tw-font-bold">{"درجة الاختبار: "}</span>
                {quiz.total_grade}
              </div> */}
              <div>
                <span className="tw-font-bold ">{t("time_for")}&nbsp;</span>
                {quiz.period}&nbsp;
                {t("minutes")}
              </div>
              <div>
                <span className="tw-font-bold">{t("quiz_grading")}&nbsp;</span>
                {quiz.is_auto_corrected ? t("completed") : t("not_completed")}
              </div>
              <div>
                <span className="tw-font-bold">{t("zoom.start_date")}&nbsp;</span>
                {toLocalTimezone(quiz.start_date)}
              </div>
              <div>
                <span className="tw-font-bold">{t("end_date")}&nbsp;</span>
                {toLocalTimezone(quiz.end_date)}
              </div>
            </div>
          }
        />
      ),
    },
    {
      title: t("trainer.course.lesson.surveies"),
      type: "survey",
      component: ({ planable: survey }) => (
        <PlanItem
          id={survey.id}
          title={survey.name}
          onView={() => {
            if (lessonContentContext.lessonInfo.id) {
              history.push(
                `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/survey/preview/${survey.id}`
              );
            }
          }}
          onEdit={
            !props.isPartner
              ? () => {
                  if (lessonContentContext.lessonInfo.id)
                    history.push(
                      `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/survey/edit/${survey.id}`
                    );
                }
              : null
          }
          onDelete={
            !props.isPartner
              ? (targetId) => {
                  lessonContentContext.deleteSurveyById(targetId);
                }
              : null
          }
          content={
            <div className="tw-space-y-4">
              <div>
                <span className="tw-font-bold">{`${t("survey.type")}: `}</span>
                {/* {survey.course_id
                  ? survey.lesson_id
                    ? */}
                {t("survey.audienceType.lesson")}
                {/* : t("survey.audienceType.course")
                  : t("survey.audienceType.exhibition")} */}
              </div>
              <div>
                <span className="tw-font-bold">{`${t("survey.roles")}: `}</span>
                {survey.roles?.length
                  ? survey.roles.map((role, index) => {
                      if (index < survey.roles.length - 1) {
                        return t(`selection.role.${role.name}`) + " | ";
                      } else {
                        return t(`selection.role.${role.name}`);
                      }
                    })
                  : survey.is_public
                  ? t(`selection.role.all_guest`)
                  : t(`selection.role.all`)}
              </div>
            </div>
          }
        />
      ),
    },
    // {
    //   title: t("admin.label.virtual_galleries"),
    //   type: "exhibition",
    //   component: ({ planId, planable: exhibition }) => (
    //     <PlanItem
    //       id={exhibition.id}
    //       title={exhibition.name}
    //       onView={
    //         !props.isPartner
    //           ? () => {
    //               if (lessonContentContext.lessonInfo.id)
    //                 history.push(
    //                   `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/exhibition/${exhibition.id}`
    //                 );
    //             }
    //           : null
    //       }
    //       onDelete={
    //         !props.isPartner
    //           ? async (targetId) => {
    //               try {
    //                 let response = await deleteExhibitionPlan(
    //                   lessonContentContext.lessonInfo.id,
    //                   planId
    //                 );
    //                 if (response.status == 200) {
    //                   toast.success(
    //                     <span style={{ fontSize: 13, fontWeight: "bold" }}>
    //                       {response.data.msg ? response.data.msg : "success"}
    //                     </span>,
    //                     {
    //                       autoClose: 1000,
    //                     }
    //                   );
    //                 }
    //               } catch (err) {
    //                 toast.error(
    //                   <span style={{ fontSize: 13, fontWeight: "bold" }}>
    //                     {err.response.data.message
    //                       ? err.response.data.message
    //                       : "Failure in service"}
    //                   </span>
    //                 );
    //               }
    //             }
    //           : null
    //       }
    //       content={
    //         <div className="tw-grid tw-grid-cols-2 tw-gap-4">
    //           <div>
    //             <span className="tw-font-bold">{`${t(
    //               "Exhibition.entry_cost"
    //             )}: `}</span>

    //             {exhibition.mode === "Paid"
    //               ? `${exhibition.entrance_cost} ${t("Exhibition.rs")}`
    //               : t("Exhibition.free")}
    //           </div>
    //           <div>
    //             <span className="tw-font-bold">{`${t(
    //               "Exhibition.number_of_booths"
    //             )}: `}</span>
    //             {exhibition.booths_no}
    //           </div>
    //           <div>
    //             <span className="tw-font-bold">{t("zoom.start_date")}</span>
    //             {moment(toLocalTimezone(exhibition.start_time)).format(
    //               "YYYY-MM-DD hh:mm A"
    //             )}
    //           </div>
    //           <div>
    //             <span className="tw-font-bold">{`${t("end_date")}`}</span>
    //             {moment(toLocalTimezone(exhibition.end_time)).format(
    //               "YYYY-MM-DD hh:mm A"
    //             )}
    //           </div>
    //         </div>
    //       }
    //     />
    //   ),
    // },
  ];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // lessonsTabs.forEach((tab) => {
  //   const isSelected = lessonContentContext?.selectedPanel === tab.id;

  //   tab.setOpened = () => {
  //     lessonContentContext.setSelectedPanel(isSelected ? 5 : tab.id);
  //     setSelectedTab(isSelected ? 5 : tab.id);
  //     lessonContentContext.setDataToBeOrganized(
  //       tab.id === 0 ? props.content : props.activities
  //     );
  //     localStorage.setItem("selectedTab", tab.id);
  //   };

  //   tab.opened = isSelected;
  // });

  useEffect(() => {
    if (localStorage.getItem("selectedTab")) {
      const tabId = Number(localStorage.getItem("selectedTab"));
      setSelectedTab(tabId);
      lessonContentContext.setSelectedPanel(tabId);
      lessonContentContext.setDataToBeOrganized(tabId === 1 ? props.activities : props.content);
      // lessonsTabs.forEach((tab) => {
      //   tab.id === tabId ? (tab.opened = true) : (tab.opened = false);
      // });
    }
  }, []);

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      lessonContentContext.setPlans((plans) => {
        const oldIndex = plans.findIndex((plan) => plan.id == active.id);
        const newIndex = plans.findIndex((plan) => plan.id == over.id);
        const orderedList = arrayMove(plans, oldIndex, newIndex);
        if (orderingTimeout) {
          clearTimeout(orderingTimeout);
        }
        setOrderingTimeout(
          setTimeout(() => {
            reorderLessonPlans(lessonId, {
              plans: orderedList.map((item, index) => ({
                id: item.id,
                order: index,
              })),
            })
              .then((res) => {
                if (res.status === 200 && res.data.status) {
                  toast.success(
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>
                      {t("trainer.course.lesson.successful_content_organization")}
                    </span>,
                    {
                      autoClose: 2000,
                    }
                  );
                }
              })
              .catch((err) => {
                toast.error(
                  <span style={{ fontSize: 13, fontWeight: "bold" }}>
                    {t("trainer.course.lesson.failed_organizing_content")}
                  </span>,
                  {
                    autoClose: 2000,
                  }
                );
              });
          }, 1000)
        );
        return orderedList;
      });
    }
  }

  function LessonPlan({ plan }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable(plan);
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
    const tab = lessonsTabs.find((tab) => tab.type == plan.type);
    return (
      <>
        {tab && (
          <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <tab.component planable={plan.planable} planId={plan.id} />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={lessonContentContext.plans} strategy={verticalListSortingStrategy}>
          {lessonContentContext.plans?.map((plan) => {
            return <LessonPlan plan={plan} />;
          })}
        </SortableContext>
      </DndContext>

      {props.isTrainer || props.isAdmin || props.isManager ? (
        <>
          <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("trainer.course.lesson.add_content")}
              actions={[
                {
                  label: t("trainer.course.lesson.add_lesson_files"),
                  onClick: () => {
                    openFileUploadModal(LessonTypes.pdf);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_lesson_file"),
                  onClick: () => {
                    openFileUploadModal(LessonTypes.file);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_audio_content"),
                  onClick: () => {
                    openFileUploadModal(LessonTypes.audio);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_video_content"),
                  onClick: () => {
                    openVideoLinkUploadModal();
                  },
                },
                {
                  label: t("trainer.course.lesson.add_lesson_content"),
                  onClick: () => {
                    openTextUploadModal();
                  },
                },
                {
                  label: t("trainer.course.lesson.add_scorm_content"),
                  onClick: () => {
                    let isActivities = false;
                    let isContentScorm = true;
                    openFileUploadModal(LessonTypes.scorm, null, null, isActivities, null, isContentScorm);
                  },
                },
              ]}
            />
          </div>
          <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("trainer.course.lesson.add_activities")}
              actions={[
                {
                  label: t("trainer.course.lesson.add_scorm_content"),
                  onClick: () => {
                    let isActivities = true;
                    openFileUploadModal(LessonTypes.scorm, null, null, isActivities);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_xapi_content"),
                  onClick: () => {
                    let isActivities = true;
                    openFileUploadModal(LessonTypes.xapi, null, null, isActivities);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_h5p_content"),
                  onClick: () => {
                    let isActivities = true;
                    openFileUploadModal(LessonTypes.h5p, null, null, isActivities);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_html_content"),
                  onClick: () => {
                    let isActivities = true;
                    openFileUploadModal(LessonTypes.html, null, null, isActivities);
                  },
                },
                {
                  label: t("trainer.course.lesson.add_hotspot_content"),
                  onClick: () => {
                    let isActivities = true;
                    openFileUploadModal(LessonTypes.hotspot, null, null, isActivities);
                  },
                },
              ]}
            />
          </div>
          <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("zoom.Add_Session")}
              action={{
                onClick: () => {
                  // history.push(`/zoom/add-session/${id}`);
                  openSessionCreationModal({
                    title: t(`zoom.Add_Session`),
                  });
                },
                disabled: false,
              }}
            />
          </div>
          <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("trainer.course.lesson.add_activity")}
              action={{
                onClick: () => {
                  openActivityCreationModal();
                },
                disabled: false,
              }}
            />
          </div>
          <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("trainer.course.lesson.add_quiz")}
              action={{
                onClick: () => {
                  if (lessonContentContext.lessonInfo.id)
                    history.push(
                      `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/assessment/add`
                    );
                },
                disabled: false,
              }}
            />
          </div>
          <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("trainer.course.lesson.add_survey")}
              action={{
                onClick: () => {
                  if (lessonContentContext.lessonInfo.id)
                    history.push(
                      `/${props.role}/course/${lessonContentContext.lessonInfo.course.id}/lesson/${lessonContentContext.lessonInfo.id}/survey/add`
                    );
                },
                disabled: false,
              }}
            />
          </div>
          {/* <div className="lesson-content-browser__tab__add-new-item">
            <AddNewItem
              title={t("add_virtual_exhibition")}
              action={{
                onClick: () => {
                  openExhibitionPlanCreationModal();
                },
                disabled: false,
              }}
            />
          </div> */}
        </>
      ) : null}

      {(lessonContentContext.selectedPanel === 0 || lessonContentContext.selectedPanel === 1) &&
      !lessonContentContext.disableOrganizingActionButtons ? (
        <div className="lesson-content-browser__actions">
          <CustomButton
            value={t("trainer.course.lesson.header.cancel_organized_content")}
            action={() => {
              lessonContentContext.resetContent();
            }}
            colors="#777"
            disable={lessonContentContext.disableOrganizingActionButtons}
          />
          <CustomButton
            value={t("trainer.course.lesson.header.save_organized_content")}
            action={() => {
              lessonContentContext.reOrganizeContent();
            }}
            colors="#036c77"
            disable={
              lessonContentContext.disableOrganizingActionButtons ||
              lessonContentContext.isPerformingBackgroundOperation
            }
          />
        </div>
      ) : null}
    </>
  );
}

export default FileBrowser;
