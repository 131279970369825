import moment from "moment";
import { useTranslation } from "react-i18next";
import noResult from "../../assets/image/Noresult.png";
import { utcToLocal } from "../../utils/utcToLocal";

function LogsModal(props) {
  require("./LogsModal.css");
  const { t } = useTranslation();

  //   useEffect(() => {
  //     for (const [key, value] of Object.entries(props.logsChange)) {
  //     }
  //   }, [props.logsChange]);

  function isValidDate(value) {
    // Check if the value is a string
    if (typeof value !== "string") return false;

    // Regex to match valid date formats (YYYY-MM-DD HH:MM:SS.sss or YYYY-MM-DDTHH:MM:SS.sssZ)
    const dateRegex = /^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}(\.\d+)?(Z)?$/;

    // If it doesn't match the date pattern, return false
    if (!dateRegex.test(value)) return false;

    // Try to create a Date object from the string value
    const date = new Date(value);

    // Check if the date is valid (date.getTime() returns NaN for invalid dates)
    return !isNaN(date.getTime());
  }

  return (
    <>
      <div
        className={"tajah-basic-modal-md"}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={"tajah-basic-modal-header"}>
          <h4>{props.title}</h4>
          <div>
            <i
              className="fas fa-times tajah-modal-dismiss"
              onClick={() => {
                props.deleteModalRef.current.dismissModal();
              }}
            ></i>
          </div>
        </div>

        <div className={"tajah-basic-modal-body"}>
          <div
            className="table-responsive"
            style={{
              width: "90%",
            }}
          >
            {props.logsChange.length == 0 ? (
              <span
                className="course-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "50px",
                }}
              >
                <img src={noResult} alt="" />
                <h5>{t("no_record")}</h5>
              </span>
            ) : (
              <table className="table table-bordered">
                <thead>
                  <tr
                    style={{ textAlign: "center", backgroundColor: "#024f92" }}
                  >
                    <th scope="col">{t("log.key")}</th>
                    <th scope="col">{t("log.old_value")}</th>
                    <th scope="col">{t("log.new_value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.logsChange.map((log, index) => {
                    return (
                      <tr style={{ textAlign: "center" }} key={index}>
                        <td scope="row" style={{ width: "225px" }}>
                          {log.key}
                        </td>

                        <td style={{ width: "225px", whiteSpace: "pre-wrap" }}>
                          {isValidDate(log.oldValue) ? (
                            <>
                              {moment(log.oldValue).format("DD/MM/YYYY")}
                              <br />
                              {utcToLocal(log.oldValue)}
                            </>
                          ) : log.oldValue ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: log.oldValue }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td style={{ width: "225px", whiteSpace: "pre-wrap" }}>
                          {isValidDate(log.value) ? (
                            <>
                              {moment(log.value).format("DD/MM/YYYY")}
                              <br />
                              {utcToLocal(log.value)}
                            </>
                          ) : log.value ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: log.value }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LogsModal;
