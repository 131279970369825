import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getlivechatReplyById,
  postLivechatAddReply,
  postLivechatEditReply,
  postSupportTicketAddReply,
  postSupportTicketEditReply,
  resetLivechat,
} from "../../../Redux/Actions/livechat/livechat.action";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner/LoadingSpinner";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import Title from "../../Shared/Components/Title/Title";
import classes from "./answersReply.module.css";
const UpdateAnswersReply = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { livechat_add_reply, livechat_edit_reply, livechat_reply_by_id } =
    useSelector((state) => state.livechatReducer);
  const { id } = useParams();
  const { t } = useTranslation();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [replyRes, setReplyRes] = useState(false);
  const [loading, setLoading] = useState(false);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "chat",
      page:
        state?.source === "support-ticket"
          ? t("admin.label.list_tickets")
          : t("chat.title"),
      pagePath:
        state?.source === "support-ticket"
          ? `/${localStorage.getItem("type")}/tickets`
          : `/${localStorage.getItem("type")}/livechat`,
    },
    {
      id: "answer_reply",
      page: id ? t("chat.edit_answer_reply") : t("chat.add_answer_reply"),
      active: true,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getlivechatReplyById(id));
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (livechat_reply_by_id.status) {
      setReplyRes(livechat_reply_by_id.data.reply);
      setLoading(false);
    }
  }, [livechat_reply_by_id]);

  useEffect(() => {
    if (livechat_add_reply.status) {
      setHasSubmitted(false);
      if (state?.source === "support-ticket") {
        history.push({
          pathname: `/${localStorage.getItem("type")}/livechat/answers-reply`,
          state: {
            source: "support-ticket",
          },
        });
      } else {
        history.push(`/${localStorage.getItem("type")}/livechat/answers-reply`);
      }
      dispatch(resetLivechat());
    }
  }, [livechat_add_reply]);

  useEffect(() => {
    if (livechat_edit_reply.status) {
      setHasSubmitted(false);
      if (state?.source === "support-ticket") {
        history.push({
          pathname: `/${localStorage.getItem("type")}/livechat/answers-reply`,
          state: {
            source: "support-ticket",
          },
        });
      } else {
        history.push(`/${localStorage.getItem("type")}/livechat/answers-reply`);
      }
      dispatch(resetLivechat());
    }
  }, [livechat_edit_reply]);

  return (
    <div className={classes["chat_list"]}>
      <div className="container-fluid">
        <div className={classes["chat_list__container"]}>
          <div className="row">
            <Breadcrumb list={breadcrumbList} />
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                {loading ? (
                  <div className={classes["loading__container"]}>
                    <LoadingSpinner />
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      id: id ? id : "",
                      message_ar: id ? replyRes.message_ar : "",
                      message_en: id ? replyRes.message_en : "",
                      status: id ? replyRes.status : 0,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);
                      if (!id) {
                        delete values.id;
                        delete values.status;
                      }
                      try {
                        if (id) {
                          if (state?.source === "support-ticket") {
                            dispatch(postSupportTicketEditReply(values));
                          } else {
                            dispatch(postLivechatEditReply(values));
                          }
                        } else {
                          if (state?.source === "support-ticket") {
                            dispatch(postSupportTicketAddReply(values));
                          } else {
                            dispatch(postLivechatAddReply(values));
                          }
                        }
                      } catch (err) {
                        setHasSubmitted(false);
                        toast.error(
                          <>
                            <span className={classes.error}>
                              {err.response.data.msg}
                            </span>
                            <ul>
                              {Object.keys(err.response.data.errors).map(
                                (key) => {
                                  return (
                                    <li>
                                      <span style={{ fontSize: 11 }}>
                                        {err.response.data.errors[key]}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        );
                      }
                      setSubmitting(false);
                    }}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};

                      if (!values.message_ar) {
                        errors.message_ar = t("Exhibition.errors.required");
                      }

                      if (!values.message_en) {
                        errors.message_en = t("Exhibition.errors.required");
                      }

                      if (Object.keys(errors).length === 0) {
                        setHasSubmitted(true);
                      } else {
                        setHasSubmitted(false);
                      }

                      return errors;
                    }}
                    validateOnChange={hasSubmitted}
                  >
                    {({ errors, handleSubmit, values, setFieldValue }) => (
                      <form onSubmit={handleSubmit}>
                        <Title
                          title={
                            id
                              ? t("chat.edit_answer_reply")
                              : t("chat.add_answer_reply")
                          }
                          fontSize={24}
                        />

                        <div className={classes["m-40"]}>
                          <div className={classes["d-flex-input"]}>
                            <label className={classes["input-label"]}>
                              {t("chat.answer_reply_ar")}
                            </label>
                          </div>

                          <textarea
                            name="message_ar"
                            value={values.message_ar}
                            onChange={(e) =>
                              setFieldValue("message_ar", e.target.value)
                            }
                            rows={5}
                            cols={30}
                            className={classes.textarea}
                          />
                          {errors.message_ar && (
                            <p
                              className={`form-input-error-space ${classes["input-field-error"]}`}
                            >
                              {errors.message_ar}
                            </p>
                          )}
                        </div>

                        <div className={classes["m-40"]}>
                          <div className={classes["d-flex-input"]}>
                            <label className={classes["input-label"]}>
                              {t("chat.answer_reply_en")}
                            </label>
                          </div>
                          <textarea
                            name="message_en"
                            value={values.message_en}
                            onChange={(e) =>
                              setFieldValue("message_en", e.target.value)
                            }
                            rows={5}
                            cols={30}
                            className={classes.textarea}
                          />
                          {errors.message_en && (
                            <p
                              className={`form-input-error-space ${classes["input-field-error"]}`}
                            >
                              {errors.message_en}
                            </p>
                          )}
                        </div>

                        <div
                          className={`${classes["modal-button"]} modal-buttons tw-mt-5`}
                        >
                          <CustomButton
                            value={t("FAQ.done")}
                            colors={"#036c77"}
                            type={"button"}
                            iconClass={"mt-2"}
                            action={handleSubmit}
                            classes={"button-width"}
                            loading={hasSubmitted}
                            disable={hasSubmitted}
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAnswersReply;
