import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePusherContext } from "../../../../Contexts/PusherContext";
import { getTraineeChatSessions } from "../../../../Redux/Actions/livechat/livechat.action";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import RatingModal from "../../../../Modals/RatingModal/RatingModal";
import { refundOrder } from "../../../../Services/api/Financial/FinancialProvider";
import { addRate } from "../../../../Services/api/Roles/RolesProvider";
import { setWatchingProgress } from "../../../../Services/api/Trainee/TraineeProvider";
import {
  addCourseRate,
  exportCertificate,
  getCoursePaymentDetails,
  getCourseTree,
} from "../../../../Services/api/courses/courseProvider";
import calenderIcon from "../../../../assets/icons/calender.svg";
import { getLocaleTime } from "../../../../utils/utcToLocal";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import ModelWithInput from "../../../Shared/Components/ModelWithInput/ModelWithInput";
import ProgressBar from "../../../Shared/Components/ProgressBar/ProgressBar";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import StarsRating from "../../../Shared/Components/StarsRating/StarsRating";
import LessonSurveyItem from "../TrainingCourseContentLessons/LessonContentView/LessonSurveyItem";
import CertificateModal from "./CertificateModal";
import ChaperItem from "./ChaperItem";
import CourseRow from "./CourseRow";
import TrainingRatingModal from "./TrainingRatingModal";
import classes from "./trainingCourseContent.module.css";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import PrivateChatPopup from "./PrivateChatPopup/PrivateChatPopup";

export default function CourseContentView({ courseFromProgram, role }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [course, setCourse] = useState({});
  const [chapters, setChapters] = useState([]);
  const { id: courseId } = useParams();
  const history = useHistory();
  const [programCourses, setProgramCourses] = useState([]);
  const [isProgram, setIsProgram] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [inProgram, setInProgram] = useState(false);
  const [isCourseClosed, setIsCourseClosed] = useState(true);
  const [selectedTrainer, setSelectedTrainer] = useState(0);
  const [showTrainerModal, setShowTrainerModal] = useState(false);
  const [showExportCertificateModal, setShowExportCertificateModal] =
    useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const openRatingModalRef = useRef();
  const openTrainersModalRef = useRef();
  const openExportCertificateModalRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    getCourseTree(courseId).then((res) => {
      if (res.status == 200 && res.data.status) {
        const courseTree = res.data.response;
        setCourse(courseTree.courseInfo);
        setChapters(courseTree.chapters);
        setIsProgram(Number(courseTree.courseInfo?.is_program));
        setIsCourseClosed(
          courseTree.courseInfo?.status === "Closed" ||
            courseTree.courseInfo?.status === "مغلقة"
        );

        if (Number(courseTree.courseInfo?.is_program)) {
          let programProgressCount = 0;
          let programWatchCount = 0;
          let userProgressEvidencesCount = 0;
          let userWatchEvidencesCount = 0;
          courseTree.courseInfo.courses.forEach((course) => {
            programProgressCount += Number(course.user_progress_trackers_count);
            programWatchCount += Number(course.user_watch_trackers_count);
            userProgressEvidencesCount += Number(
              course.user_progress_evidences_count
            );
            userWatchEvidencesCount += Number(
              course.user_watch_evidences_count
            );
          });
          setProgressPercentage(
            Math.round(
              (userProgressEvidencesCount / programProgressCount) * 100
            ) || 0
          );
          setWatchPercentage(
            Math.round((userWatchEvidencesCount / programWatchCount) * 100) || 0
          );
          getCoursePaymentDetails(courseId, "program")
            .then((res) => {
              if (res.status && res.status === 200 && res.data.status) {
                let tempProgramCourses = courseTree.courseInfo.courses;
                res.data.program.payments.forEach((payment) => {
                  payment.courses.forEach((course) => {
                    tempProgramCourses.forEach((programCourse) => {
                      if (programCourse.id === course.id) {
                        programCourse.payment = payment;
                      }
                    });
                  });
                });
                setProgramCourses(tempProgramCourses);
              }
            })
            .catch((err) => {});
        } else {
          setProgressPercentage(
            courseTree.courseInfo?.progress_percentage || 0
          );
          setWatchPercentage(courseTree.courseInfo?.watch_percentage || 0);
          setInProgram(courseTree.courseInfo?.program_id ? true : false);
        }
        setIsLoading(false);
      }
    });
  }

  const handleOpenRatingModal = (id = 0) => {
    setSelectedTrainer(id);
    setShowRatingModal(true);
    openRatingModalRef.current.showModal();
  };

  const handleCloseRatingModal = () => {
    setSelectedTrainer(0);
    setShowRatingModal(false);
    openRatingModalRef.current.dismissModal();
  };

  const handleOpenTrainersModal = (id = 0) => {
    setShowTrainerModal(true);
    openTrainersModalRef.current.showModal();
  };

  const handleCloseTrainersModal = () => {
    setShowTrainerModal(false);
    openTrainersModalRef.current.dismissModal();
  };

  const handleOpenExportCertificateModal = () => {
    setShowExportCertificateModal(true);
    openExportCertificateModalRef.current.showModal();
  };

  const handleCloseExportCertificateModal = () => {
    setShowExportCertificateModal(false);
    openExportCertificateModalRef.current.dismissModal();
  };

  const handleOpenRefundModal = (id = 0) => {
    setShowRefundModal(true);
  };

  const saveWatchingProgressAPI = async ({
    watchable,
    watchable_type,
    chapter_id,
  }) => {
    try {
      if (!watchable.include_view_per || watchable.is_watched) {
        return;
      }
      const payload = {
        course_id: courseId,
        watchable_id: watchable.id,
        watchable_type,
        chapter_id: chapter_id,
      };
      setWatchingProgress(payload).then((res) => {
        fetchData();
      });
    } catch (err) {}
  };

  const submitRating = async (rating, trainerId) => {
    try {
      setIsUpdating(true);
      let dataToBeUploaded = {
        ...rating,
      };
      if (trainerId) {
        dataToBeUploaded.rateable_id = trainerId;
        dataToBeUploaded.course_id = courseId;
      } else if (selectedTrainer === 0) {
        dataToBeUploaded.rateable_id = courseId;
      } else {
        dataToBeUploaded.rateable_id = selectedTrainer;
        dataToBeUploaded.course_id = courseId;
      }

      let response = showTrainerModal
        ? await addRate(dataToBeUploaded)
        : await addCourseRate(dataToBeUploaded);
      if (response.status === 201 && response.data.status) {
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("rate.label.success")}
          </span>
        );
        if (!showTrainerModal) {
          // dispatch(fetchCourseTree(courseId));
        }
        setIsUpdating(false);

        if (selectedTrainer === 0) {
          fetchData();
        }
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
        setIsUpdating(false);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.response.data.msg ? err.response.data.msg : "Failure in service"}
        </span>
      );
      setIsUpdating(false);
    }
    !trainerId && handleCloseRatingModal();
  };

  const submitRefund = async (reason) => {
    try {
      setIsUpdating(true);
      const dataToBeUploaded = {
        reason,
        payment_method: "Wallet",
      };
      let response = await refundOrder(
        courseId,
        dataToBeUploaded,
        isProgram ? "program" : "course"
      );
      if (response.status === 201 && response.data.status) {
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg}
          </span>
        );
        setIsUpdating(false);
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
        setIsUpdating(false);
      }
    } catch (err) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {err.data.msg ? err.data.msg : "Failure in service"}
        </span>
      );
      setIsUpdating(false);
    }
    setShowRefundModal(false);
    fetchData();
  };

  const exportCertificateApi = async ({ lang }) => {
    setIsUpdating(true);
    try {
      await exportCertificate(courseId, lang);
    } catch (e) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {e.response.data.msg
            ? e.response.data.msg
            : t("export_certificate_failed")}
        </span>
      );
    } finally {
      setIsUpdating(false);
      handleCloseExportCertificateModal();
    }
  };

  const { pusher } = usePusherContext();
  const dispatch = useDispatch();
  const { courseTree, courseTreeLoading, sendNotificationLoading } =
    useSelector((state) => state.coursesListReducer);
  const { trainee_chat_sessions } = useSelector(
    (state) => state.livechatReducer
  );

  const [sessions, setSessions] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [messages, setMessages] = useState([]);
  const [showPrivateChatModal, setShowPrivateChatModal] = useState(false);

  useEffect(() => {
    dispatch(getTraineeChatSessions(courseId));
  }, [courseId]);

  useEffect(() => {
    // if (trainee_chat_sessions.length > 0) {
    setSessions(trainee_chat_sessions);
  }, [trainee_chat_sessions]);

  const handleOpenPrivateChat = () => {
    setSessions((prevObjects) =>
      prevObjects.map((obj) => ({ ...obj, is_seen: true }))
    );
    setShowPrivateChatModal(true);
  };

  const handleClosePrivateChat = () => {
    setSessionId(null);
    setShowPrivateChatModal(false);
  };

  useEffect(() => {
    const channel = pusher.subscribe("learning-chat");
    channel.bind("App\\Events\\PrivateMessageSent", (data) => {
      if (Number(data.chat_id) === Number(sessionId)) {
        if (!messages.find((message) => message.id === data.message.id)) {
          setMessages((oldArray) => [...oldArray, data.message]);
        }
      } else {
        let session = sessions.find((session) => session.id === data.chat_id);
        if (session) {
          setSessions((prevObjects) => [
            { ...session, is_seen: false },
            ...prevObjects.filter((obj) => obj.id !== data.chat_id),
          ]);
        } else {
          dispatch(getTraineeChatSessions(courseId));
        }
      }
    });
    return () => {
      channel?.unbind("App\\Events\\PrivateMessageSent");
      pusher?.unsubscribe("learning-chat");
    };
  }, [sessionId]);

  useEffect(() => {
    if (sessionId) {
      setSessions((prevObjects) =>
        prevObjects.map((obj) =>
          obj.id === sessionId ? { ...obj, is_seen: true } : obj
        )
      );
    }
  }, [sessionId]);

  return (
    <>
      {isUpdating ? (
        <div
          style={{
            height: "60vh",
            width: "200px",
          }}
        >
          <SkeletonCardOverlay />
        </div>
      ) : (
        <></>
      )}

      <BasicModal ref={openTrainersModalRef}>
        {showTrainerModal && (
          <TrainingRatingModal
            handleCancel={handleCloseTrainersModal}
            handleSubmit={submitRating}
            showTrainerModal={showTrainerModal}
            openTrainersModalRef={openTrainersModalRef}
            courseInfo={course}
          />
        )}
      </BasicModal>

      <BasicModal ref={openExportCertificateModalRef}>
        {showExportCertificateModal && (
          <CertificateModal
            handleExport={exportCertificateApi}
            handleCancel={handleCloseExportCertificateModal}
          />
        )}
      </BasicModal>
      {showRefundModal && (
        <ModelWithInput
          openModal={showRefundModal}
          setOpenModal={setShowRefundModal}
          modalTitle={t("general.refund_request")}
          buttonText={t("general.submit")}
          type={"textarea"}
          inputLabel={t("general.refund_reason")}
          handleSubmit={submitRefund}
          note={`${t("financial.refund.refund_note")} ${
            course?.refund_amount
          } ${t("rs")}`}
        />
      )}
      <BasicModal ref={openRatingModalRef}>
        {showRatingModal && (
          <RatingModal
            handleSubmit={submitRating}
            handleCancel={handleCloseRatingModal}
          />
        )}
      </BasicModal>
      <div className="tw-container tw-mx-auto tw-py-8 tw-px-8">
        {isLoading ? (
          <div
            style={{
              height: "60vh",
            }}
          >
            <SkeletonCard />
          </div>
        ) : (
          <div className="tw-rounded-xl tw-bg-white">
            <div className="tw-space-y-8 tw-p-8">
              {courseFromProgram ? (
                <div className="tw-flex tw-items-stretch tw-justify-between">
                  <div className="tw-text-xl">{t("showProgram")}</div>
                  <button
                    type="button"
                    onClick={() => history.goBack()}
                    className="tw-flex tw-items-center tw-justify-center tw-px-8 tw-rounded-lg tw-bg-gray-200"
                  >
                    <div className="tw-text-gray-500 tw-py-2">{t("back")}</div>
                  </button>
                </div>
              ) : null}
              <div className="tw-flex tw-flex-col xl:tw-flex-row tw-items-center tw-justify-between tw-space-y-4 xl:tw-space-y-0">
                <div className="tw-text-xl tw-font-bold">{course.name}</div>
                <div className="tw-flex tw-flex-wrap tw-items-center tw-space-s-2 tw-whitespace-nowrap tw-space-y-4">
                  {role ? (
                    <>
                      <button
                        type="button"
                        onClick={() => history.goBack()}
                        className="tw-flex tw-items-center tw-justify-center tw-px-8 tw-rounded-lg tw-bg-gray-200"
                      >
                        <div className="tw-text-gray-500 tw-py-2">
                          {t("back")}
                        </div>
                      </button>
                    </>
                  ) : (
                    <>
                      <div></div>
                      {sessions.length > 0 && course?.is_program !== 1 && (
                        <>
                          {showPrivateChatModal && (
                            <PrivateChatPopup
                              handleClosePrivateChat={handleClosePrivateChat}
                              setMessages={setMessages}
                              showPrivateChatModal={showPrivateChatModal}
                              messages={messages}
                              sessionId={sessionId}
                              setSessionId={setSessionId}
                              sessions={sessions}
                            />
                          )}
                          <CustomButton
                            value={
                              <div className={classes["private-chat"]}>
                                {sessions.some(
                                  (session) => !session.is_seen
                                ) && <div className={classes["new-msg"]}></div>}
                                <span>{t("chat.chatWithTrainer")}</span>
                              </div>
                            }
                            classes={classes.btnFont}
                            action={handleOpenPrivateChat}
                            colors="#24649d"
                            disable={false}
                          />
                        </>
                      )}
                      {!inProgram &&
                      course?.has_template &&
                      progressPercentage >=
                        course.progress_success_percentage &&
                      watchPercentage >= course.watch_success_percentage &&
                      (course?.certificate_after_course == 0 ||
                        moment(
                          getLocaleTime(new Date(course?.end_date))
                        ).isBefore(moment())) ? (
                        <button
                          type="button"
                          onClick={handleOpenExportCertificateModal}
                          className=" tw-px-8 tw-py-2 tw-rounded-lg tw-text-[#ff9301] tw-border tw-border-[#ff9301]"
                        >
                          {t("export_certificate")}
                        </button>
                      ) : (
                        <></>
                      )}

                      {!isProgram &&
                      course?.quiz_results_count &&
                      (!isCourseClosed ||
                        Number(course?.accessable_after_close) === 1) ? (
                        <>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(`/trainees/course/${courseId}/exams`)
                            }
                            className=" tw-px-8 tw-py-2 tw-rounded-lg tw-text-[#3bb9be] tw-border tw-border-[#3bb9be]"
                          >
                            {t("quiz_scores")}
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(
                                `/trainees/course/${courseId}/activities`
                              )
                            }
                            className=" tw-px-8 tw-py-2 tw-rounded-lg tw-text-[#3bb9be] tw-border tw-border-[#3bb9be]"
                          >
                            {t("activities_scores")}
                          </button>
                        </>
                      ) : null}
                      {!courseFromProgram &&
                      !isCourseClosed &&
                      !course.enrolled_by_gift &&
                      (Number(course.active_refunds_count) !== 0 ||
                        Number(course.program_active_refunds_count) !== 0) &&
                      course.my_enrolment?.register_by === null ? (
                        <button
                          type="button"
                          onClick={() => handleOpenRefundModal()}
                          className=" tw-px-8 tw-py-2 tw-rounded-lg tw-text-white tw-bg-[#006e78]"
                          disabled={
                            Number(course.active_refund_requests_count) !== 0
                          }
                        >
                          {t("trainer.course.refund")}
                        </button>
                      ) : null}
                      {!isCourseClosed ? (
                        <button
                          type="button"
                          onClick={() =>
                            history.push(
                              `/${
                                isProgram
                                  ? "training-programs"
                                  : "training-courses"
                              }/${courseId}/details`
                            )
                          }
                          className=" tw-px-8 tw-py-2 tw-rounded-lg tw-text-white tw-bg-[#8599b7]"
                        >
                          {isProgram
                            ? t("trainer.program.details")
                            : t("trainer.course.details")}
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={handleOpenTrainersModal}
                        className=" tw-px-8 tw-py-2 tw-rounded-lg tw-text-white tw-bg-[#3db9be]"
                      >
                        {t("courses_list.rate_trainers")}
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="tw-space-y-4">
                <div className={classes["training-course-content-rating"]}>
                  <StarsRating
                    rating={course?.ratings_avg_stars || 0}
                    cutStyle={classes["stars-font-size"]}
                  />
                  <button
                    type="button"
                    onClick={() => handleOpenRatingModal()}
                    className={`tw-px-8 tw-py-2 tw-rounded-lg tw-text-white  ${
                      role ? classes.disabledButton : "tw-bg-[#3db9be]"
                    }`}
                    disabled={role}
                  >
                    {t("rate_now")}
                  </button>
                </div>
                <ProgressBar
                  progress={progressPercentage}
                  title={
                    isProgram
                      ? t("yourCompleteProgram")
                      : t("yourCompleteCourse")
                  }
                  color="#24b3b9"
                />
                <ProgressBar
                  progress={watchPercentage}
                  title={t("watchingProgress")}
                  color="#ff9300"
                />
              </div>
              {!isCourseClosed ||
              Number(course?.accessable_after_close) === 1 ? (
                !isProgram ? (
                  chapters?.length ? (
                    <div className="tw-space-y-4">
                      <div>{t("course_chapters")}</div>
                      {chapters.map((chapter, index) => (
                        <ChaperItem
                          key={chapter.id}
                          index={index}
                          courseId={courseId}
                          chapter={chapter}
                          isCourseClosed={isCourseClosed}
                          accessableAfterClose={course?.accessable_after_close}
                          saveWatchingProgressAPI={saveWatchingProgressAPI}
                          role={role}
                        />
                      ))}
                    </div>
                  ) : null
                ) : programCourses?.length ? (
                  <div className="tw-space-y-4">
                    <div>{t("program_courses")}</div>
                    {programCourses.map((item) => (
                      <CourseRow
                        id={courseId}
                        key={item.id}
                        course={item}
                        program={course}
                        role={role}
                      />
                    ))}
                  </div>
                ) : null
              ) : (
                <div className="tw-text-center tw-text-red-500 tw-font-bold tw-text-lg tw-p-4">
                  {t(`${isProgram ? "program" : "course"}_closed`)}
                </div>
              )}
              {(!isCourseClosed ||
                Number(course?.accessable_after_close) === 1) &&
              course.surveys?.length ? (
                <div className="tw-space-y-4">
                  <div>{t("course_surveys")}</div>
                  {course.surveys.map((survey, index) => (
                    <LessonSurveyItem
                      isCourseClosed={isCourseClosed}
                      key={survey.id}
                      index={index}
                      courseId={courseId}
                      survey={survey}
                      role={role}
                      onOpen={() => {
                        !role &&
                          saveWatchingProgressAPI({
                            watchable_type: "survey",
                            watchable: survey,
                          });
                      }}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
