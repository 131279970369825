import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Pagination } from "@mui/material";
import {
  getAllAvailableCourses,
  getAllAvailablePrograms,
  getAllSpecialty,
} from "../../Services/api/HomeCoursesProvider";
import HomeIcon from "../../assets/icons/Group 3.svg";
import SearchIcon from "../../assets/icons/SearchHome.svg";
import ClearIcon from "../../assets/icons/delete-gray.svg";
import Breadcrumb from "../Shared/Components/Breadcrumb/Breadcrumb";
import CourseCard from "../Shared/Components/CourseCard/CourseCard";
import MainBox from "../Shared/Components/MainBox/MainBox";
import SkeletonCardOverlay from "../Shared/Components/Spinner/SkeletonCardOverlay";
import DottedLine from "../Shared/Components/dottedLIne/DottedLine";
import MainSectionSelection from "../home/MainSection/MainSectionSelection";
import classes from "./homeCourses.module.css";
import LoadingSpinner from "../Shared/Components/LoadingSpinner/LoadingSpinner";
import CustomButton from "../Shared/Components/CustomButton/CustomButton";

export default function HomeCourses({ isProgram }) {
  const { t, i18n } = useTranslation();
  const [availableCourses, setAvailableCourses] = useState([]);
  const [tab, setTab] = useState(null);
  const [meta, setMeta] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [specialtisList, setSpecialtisList] = useState([]);

  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };

  const getSpecialties = () => {
    getAllSpecialty()
      .then((res) => {
        if (res.status && res.status === 200) {
          setSpecialtisList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSpecialties();
  }, []);

  const getfilterData = () => {
    const filter = { perPage: 9, page: currentPage };
    searchName && (filter.search = searchName);
    return filter;
  };

  useEffect(() => {
    setCoursesLoading(true);
    const apiCall = isProgram
      ? getAllAvailablePrograms
      : getAllAvailableCourses;
    const filter = getfilterData();

    apiCall({ tab, filter })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setAvailableCourses(res.data.data);
          setMeta(res.data.meta);
          setLoading(false);
          setCoursesLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [tab, currentPage]);

  const displayLevel = (level) => {
    if (level === 1) {
      return t("levels.beginner");
    } else if (level === 2) {
      return t("levels.medium");
    } else if (level === 3) {
      return t("levels.advanced");
    }
  };
  const handleSubmit = (isrest) => {
    setCoursesLoading(true);
    const apiCall = isProgram
      ? getAllAvailablePrograms
      : getAllAvailableCourses;
    const filter = getfilterData();
    if (isrest) {
      filter.search = "";
    }
    apiCall({ tab, filter })
      .then((res) => {
        // console.log(res);
        if (res.status && res.status === 200) {
          setAvailableCourses(res.data.data);
          setMeta(res.data.meta);
          setLoading(false);
          setCoursesLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {!loading ? (
        <div className="container-fluid" style={{ padding: "20px" }}>
          <div className="row" style={{ marginTop: "20px" }}>
            <div className={classes["title_search"]}>
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                  flex: "2 1 auto",
                  alignItems: "center",
                }}
              >
                <img
                  src={HomeIcon}
                  alt=""
                  onClick={() => history.push("/")}
                  style={{ cursor: "pointer" }}
                />
                {isProgram ? (
                  <p className={classes["title"]}>{t("program")}</p>
                ) : (
                  <p className={classes["title"]}>{t("courses")}</p>
                )}
              </div>
              <div className={classes["search_container"]}>
                <div className={classes["search-box"]}>
                  <img src={SearchIcon} alt="" />
                  <input
                    type="text"
                    placeholder={t("homeSearch")}
                    name="search"
                    value={searchName}
                    onChange={handleSearch}
                    style={{ width: "100%" }}
                  />
                  {searchName && (
                    <img
                      src={ClearIcon}
                      alt=""
                      onClick={() => {
                        setSearchName("");
                        handleSubmit(true);
                      }}
                    />
                  )}
                </div>
                <CustomButton
                  value={t("searchbtn")}
                  colors={"#025299"}
                  type={"button"}
                  action={() => handleSubmit()}
                  classes={classes.searchbtn}
                />
              </div>
            </div>
            <MainBox
              style={{
                padding: "0rem 0rem",
                borderRadius: "15px",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <div className={classes["specialty_container"]}>
                <div
                  className={`${classes["specialty_tab"]} ${
                    tab === null ? classes["selected-tab"] : ""
                  } `}
                  onClick={() => {
                    if (tab !== null) {
                      setTab(null);
                      setCoursesLoading(true);
                      setCurrentPage(1);
                    }
                  }}
                >
                  <p>{isProgram ? t("allPrograms") : t("allCourses")}</p>
                </div>
                {specialtisList.map((specialty) => (
                  <div
                    className={`${classes["specialty_tab"]} ${
                      specialty.id === tab ? classes["selected-tab"] : ""
                    } `}
                    onClick={() => {
                      if (specialty.id !== tab) {
                        console.log(tab);
                        setTab(specialty.id);
                        setCoursesLoading(true);
                        setCurrentPage(1);
                      }
                    }}
                    key={specialty.id}
                  >
                    <p>
                      {i18n.language === "ar"
                        ? specialty.name_ar
                        : specialty.name_en}
                    </p>
                  </div>
                ))}
              </div>
            </MainBox>
            <MainBox className="border-8" style={{ width: "100%" }}>
              {coursesLoading ? (
                <div className={classes["loading__container"]}>
                  <LoadingSpinner />
                </div>
              ) : (
                <div className={classes["courses_card_grid"]}>
                  {availableCourses?.map((course) => (
                    <>
                      <CourseCard
                        levelbadge={displayLevel(Number(course.level))}
                        selfPaced={course.self_paced}
                        mode={course.mode}
                        price={course.price}
                        offer={
                          course.offers.length > 0 && course.offers[0].status
                            ? course.offers[0]
                            : false
                        }
                        img={course.img}
                        title={course.name}
                        briefDesc={course.brief_description}
                        date={course.start_date.split(" ")[0]}
                        seat={course.remaining_seats}
                        trainers={!isProgram && course.trainers}
                        hour={course.number_hours}
                        handleSubmit={() => {
                          history.push(
                            `/${
                              isProgram
                                ? "training-programs"
                                : "training-courses"
                            }/${course.id}/details`
                          );
                        }}
                        btn={t("programs.values.show_details")}
                      />
                    </>
                  ))}
                </div>
              )}
              <Pagination
                count={meta?.last_page || 0}
                showFirstButton
                showLastButton
                variant="outlined"
                shape="rounded"
                page={currentPage}
                className="main-pagination"
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
              />
            </MainBox>
          </div>
        </div>
      ) : (
        <SkeletonCardOverlay />
      )}
    </>
  );
}
