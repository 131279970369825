import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useHistory,
  useLocation,
  withRouter,
  Link,
} from "react-router-dom"; // TODO:
import { toast } from "react-toastify";
import PasswordInput from "../../../Components/Input/PasswordInput";
import { AuthContext } from "../../../Services/api/auth/AuthContext";
import Trainer from "../../../assets/icons/Teacher.svg";
import Trainee from "../../../assets/icons/Trainer.svg";
import {
  changePassword,
  changeToAlternativeEmail,
  getAlternativeEmail,
  login,
  loginSso,
  loginSSOQassem,
  userBasic,
} from "../../../Services/api/auth/AuthProvider";
import { getProfileInfo } from "../../../Services/api/profile/profileProvider";
import { FooterContext } from "../../../Services/api/toutorials/FooterContext";
import warning from "../../../assets/icons/warning-green.svg";
import i18n from "../../../i18n/i18n";
import CustomButton from "../../Shared/Components/CustomButton/CustomButton";
import Input from "../../Shared/Components/Input/Input";
import { BasicModal } from "../../Shared/Components/Modal/BasicModal";
import "../Register/register.css";
import Social from "../Social/Social";
import InvalidEmailModal from "./invalidEmailModal/InvalidEmailModal";
import Logo from "../../../assets/image/Qassem_logo.svg";

function Login(props) {
  require("./login.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const { state, search } = useLocation();

  const authContext = useContext(AuthContext);
  const footerCtx = useContext(FooterContext);
  const [email, setEmail] = useState("");
  const [idNum, setIdNum] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [disabled, setDisabled] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const [showInvalidEmailModal, setShowInvalidEmailModal] = useState(false);
  const invalidEmailModal = useRef(null);
  const [isAlternativeEmail, setIsAlternativeEmail] = useState(false);
  const [alternativeEmail, setAlternativeEmail] = useState("");
  const [roleSelected, setRoleSelected] = useState(true);
  const [role, setRole] = useState(1);
  const token = new URLSearchParams(search).get("token");
  const userEmail = new URLSearchParams(search)
    .get("email")
    ?.replace(/ /g, "+");
  // console.log(token, userEmail);
  useEffect(() => {
    if (token && userEmail) {
      localStorage.setItem("token", token);
      localStorage.setItem("email", userEmail);
      userBasic().then((res) => {
        let roles = [];
        res.data.user.roles.forEach((item) => {
          roles.push(item.name);
        });
        localStorage.setItem("roles", JSON.stringify(roles));
        // localStorage.setItem("token", res.data.user.token);
        localStorage.setItem("name_ar", res.data.user.name_ar);
        localStorage.setItem("name_en", res.data.user.name_en);
        localStorage.setItem("email", res.data.user.email);
        localStorage.setItem("is_admin", res.data.user.is_admin);
        localStorage.setItem("img", res.data.user.img);
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    footerCtx.setFooter(false);
    return () => {
      footerCtx.setFooter(true);
    };
  }, []);

  const passwordHandleChange = (password) => {
    setPassword(password);
  };

  function validateForm() {
    return forgotPassword
      ? email.length > 0 || idNum.length > 0
      : (email.length > 0 || idNum.length > 0) && password.length > 6;
  }

  const handleOpenInvalidEmailModal = () => {
    setShowInvalidEmailModal(true);
    invalidEmailModal.current.showModal();
  };

  const handleCloseInvalidEmailModal = () => {
    setShowInvalidEmailModal(false);
    invalidEmailModal.current.dismissModal();
  };

  const handleGetAlternativeEmail = () => {
    getAlternativeEmail(email)
      .then((res) => {
        if (res.status == 200) {
          setIsAlternativeEmail(true);
          setAlternativeEmail(res.data.email);
          showForgotPassword();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    const regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (email === "" || email.trim() === "") {
      event.preventDefault();
      setError(<div className="error_login">{t("error_msg_empty_email")}</div>);
    } else if (password === "" || password.trim() === "") {
      event.preventDefault();
      setError(
        <div className="error_login">{t("error_msg_empty_password")}</div>
      );
    } else if (password.length < 6) {
      event.preventDefault();
      setError(
        <div className="error_login">{t("error_msg_length_password")}</div>
      );
    } else if (regex.test(email) === false) {
      event.preventDefault();
      setError(<div className="error_login">{t("error_msg_shape_email")}</div>);
    } else {
      authContext.setIsPerformingAuthenticationOperation(true);
      setDisabled(true);
      event.preventDefault();
      const emailUniversity = email.split("@")[1];
      if (emailUniversity === "iau.edu.sa") {
        loginSso(email, password)
          .then((res) => {
            if (res.data.user.accessToken) {
              let roles = [];
              res.data.user.roles.forEach((item) => {
                roles.push(item.name);
              });
              localStorage.setItem("roles", JSON.stringify(roles));
              localStorage.setItem("token", res.data.user.accessToken);
              localStorage.setItem("name_ar", res.data.user.name_ar);
              localStorage.setItem("name_en", res.data.user.name_en);
              localStorage.setItem("email", res.data.user.email);
              localStorage.setItem("is_admin", res.data.user.is_admin);
              localStorage.setItem("img", res.data.user.img);
              localStorage.setItem("userId", res.data.user.id);

              authContext.setAuth({ token: res.data.user.accessToken });
              authContext.setRoles(JSON.stringify(roles));
              setDisabled(false);
              if (res.data.user.is_admin === true) {
                if (res.data.user.roles[0].name === "group-admin") {
                  history.push("/group-admin");
                } else if (res.data.user.roles[0].name === "manager") {
                  history.push("/manager");
                } else {
                  history.push("/admin");
                }
                window.location.reload();
              } else {
                window.location.href =
                  process.env.REACT_APP_FRONT_BASE_URL + "private-profile";
              }
            }
          })
          .catch((error) => {
            setDisabled(false);
            authContext.setIsPerformingAuthenticationOperation(false);
            console.log(error);
            if (
              error.response.data.msg === "This email was swapped!" ||
              error.response.data.msg === "تم إستبدال هذا البريد الإلكتروني"
            ) {
              toast.error(
                <span style={{ fontSize: 14 }}>{error.response.data.msg}</span>
              );
            } else {
              toast.error(
                <span style={{ fontSize: 14 }}>{error.response.data.msg}</span>
              );
              handleOpenInvalidEmailModal();
            }
            if (error == "Error: Request failed with status code 422") {
              setError(<div className="error_login">{t("error_msg_sso")}</div>);
            }
          });
      } else {
        login(email, password)
          .then((res) => {
            console.log(res.data.user);
            if (res?.data?.user?.accessToken) {
              let roles = [];
              res.data.user.roles.length > 0 &&
                res.data.user.roles.forEach((item) => {
                  roles.push(item.name);
                });
              localStorage.setItem("roles", JSON.stringify(roles));
              localStorage.setItem(
                "type",
                res.data.user.roles[0]?.name || "trainee"
              );
              localStorage.setItem("token", res.data.user.accessToken);
              localStorage.setItem("tokenChat", res.data.user.accessToken);
              localStorage.setItem("name_ar", res.data.user.name_ar);
              localStorage.setItem("name_en", res.data.user.name_en);
              localStorage.setItem("email", res.data.user.email);
              localStorage.setItem("is_admin", res.data.user.is_admin);
              localStorage.setItem("img", res.data.user.img);
              localStorage.setItem("userId", res.data.user.id);
              localStorage.setItem("type_login", res.data.user.type_login);
              localStorage.setItem("phone", res.data.user.phone);
              localStorage.setItem("group", res.data.group?.id);
              // localStorage.removeItem("tokenChat");
              localStorage.removeItem("chat_username");
              localStorage.removeItem("chat_email");
              localStorage.removeItem("chatId");
              authContext.setAuth({ token: res.data.user.accessToken }); // todo:
              authContext.setRoles(JSON.stringify(roles));
              authContext.setIsPerformingAuthenticationOperation(false);
              if (
                res.data.user.is_admin === true &&
                res.data.user.roles[0].name === "group-admin"
              ) {
                history.push("/group-admin");
                window.location.reload();
              } else if (
                res.data.user.is_admin === true &&
                res.data.user.roles[0].name === "manager"
              ) {
                history.push("/manager");
                window.location.reload();
              } else if (res.data.user.is_admin === true) {
                history.push("/admin");

                window.location.reload();
              } else if (
                res.data.user.roles.length > 0 &&
                res.data.user.roles[0].name === "accountant"
              ) {
                history.push("/accountant");
                window.location.reload();
              } else if (res.data.user.roles.length === 0) {
                history.push("/");
              } else {
                history.push("/private-profile");
                window.location.reload();
              }
            }
          })
          .catch((error) => {
            console.log(error);
            authContext.setIsPerformingAuthenticationOperation(false);
            if (error?.data?.msg === "Account Suspended!") {
              setError(
                <div className="error_login">{t("error_msg_Suspended")}</div>
              );
            } else if (error?.data?.msg === "تم إرسال رابط التحقق") {
              setError(
                <div className="error_login">
                  {t("error_msg_link_confirmation")}
                </div>
              );
            } else if (
              error?.data?.msg === "The provided credentials are incorrect"
            ) {
              setError(<div className="error_login">{t("error_msg")}</div>);
            } else if (error?.data?.errNum === 500) {
              setError(
                <div className="error_login">{t("error_msg_server")}</div>
              );
            }
          });
      }
    }
    setDisabled(false);
  };

  const handleSubmitSSO = (event) => {
    event.preventDefault();
    loginSSOQassem(idNum, password)
      .then((res) => {
        if (res.data.user.accessToken) {
          let roles = [];
          res.data.user.roles.forEach((item) => {
            roles.push(item.name);
          });
          localStorage.setItem("roles", JSON.stringify(roles));
          localStorage.setItem("token", res.data.user.accessToken);
          localStorage.setItem("name_ar", res.data.user.name_ar);
          localStorage.setItem("name_en", res.data.user.name_en);
          localStorage.setItem("email", res.data.user.email);
          localStorage.setItem("is_admin", res.data.user.is_admin);
          localStorage.setItem("img", res.data.user.img);
          localStorage.setItem("userId", res.data.user.id);

          authContext.setAuth({ token: res.data.user.accessToken });
          authContext.setRoles(JSON.stringify(roles));
          setDisabled(false);
          if (res.data.user.is_admin === true) {
            if (res.data.user.roles[0].name === "group-admin") {
              history.push("/group-admin");
            } else if (res.data.user.roles[0].name === "manager") {
              history.push("/manager");
            } else {
              history.push("/admin");
            }
            window.location.reload();
          } else {
            // window.location.href =
            //   process.env.REACT_APP_FRONT_BASE_URL + "private-profile";
            history.push("/private-profile");
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        setDisabled(false);
        authContext.setIsPerformingAuthenticationOperation(false);
        console.log(error);
        toast.error(<span style={{ fontSize: 14 }}>{error.data.msg}</span>);
        // if (
        //   error.data.msg === "This email was swapped!" ||
        //   error.data.msg === "تم إستبدال هذا البريد الإلكتروني"
        // ) {
        //   toast.error(
        //     <span style={{ fontSize: 14 }}>{error.response.data.msg}</span>
        //   );
        // } else {
        //   toast.error(
        //     <span style={{ fontSize: 14 }}>{error.response.data.msg}</span>
        //   );
        //   handleOpenInvalidEmailModal();
        // }
        if (error == "Error: Request failed with status code 422") {
          setError(<div className="error_login">{t("error_msg_sso")}</div>);
        }
      });
    setDisabled(false);
  };

  const showHide = (e) => {
    e.preventDefault();
    setType(type === "input" ? "password" : "input");
  };

  let spinnerLoading = "";
  if (disabled === true) {
    spinnerLoading = <div className="lds-dual-ring"></div>;
  } else {
    spinnerLoading = "";
  }

  const showForgotPassword = () => {
    setForgotPassword(true);
    setError("");
  };

  const showLogin = () => {
    setForgotPassword(false);
  };

  const sendEmailForgotPassword = async () => {
    authContext.setIsPerformingAuthenticationOperation(true);
    let data = {
      email: email,
    };

    try {
      isAlternativeEmail
        ? await changeToAlternativeEmail(data)
        : await changePassword(data);
      toast.success(<span style={{ fontSize: 14 }}>{t("sentToEmail")}</span>);
    } catch (error) {
      console.log(error);
      toast.error(<span style={{ fontSize: 14 }}>{t("notSentToEmail")}</span>);
    } finally {
      authContext.setIsPerformingAuthenticationOperation(false);
    }
  };

  const handleSelection = () => {
    // console.log(role, roleSelected);

    if (role !== 3 && role !== 4 && role !== 5) {
      return;
    }
    if (role === 4) {
      console.log(role);
      window.location.replace(
        "https://qaseem-staging-be.tajahdev.dev/saml2/main/login"
      );
    } else {
      setRoleSelected(false);
    }
  };

  return (
    <>
      <div className="auth_forms_login">
        <div className="auth-home-login">
          <img src={Logo} alt="" className={"logo_qassem_login"} />
        </div>
        <p className="auth_label">
          {/* {forgotPassword ? t("updatePassword") : t("welcome_back")} */}
          {t("welcome_back")}
        </p>
        <span className="small_note">{t("login")}</span>
        <br />
        <span
          className="small_note"
          style={{
            border: "1px solid gray",
            padding: "3px",
            borderRadius: "5px",
            cursor: "pointer",
            color: "black",
            fontSize: 14,
            fontWeight: 600,
            backgroundColor: "rgba(0, 255, 255, 0.137)",
          }}
          onClick={() => {
            setRole(5);
          }}
        >
          {" "}
          تسجيل عبر منصة تاجة
          <div
            className={role === 5 ? "slected" : "unselected"}
            style={{ margin: "2px auto", border: "1px solid gray" }}
          ></div>
        </span>
        {roleSelected ? (
          <div className="auth_forms_reg">
            <div className="auth_form_flex_col auth_form_form">
              <div className="auth_label_registar">
                {/* <p className="auth_label">{t("RegisteraNewUser")}</p> */}
                <span className="small_note">{t("login_note")}</span>
              </div>
              <div className="trainer_trainee_box">
                <div
                  className="Trainer_trainee_cont"
                  onClick={() => setRole(3)}
                >
                  <img src={Trainer} alt="" className="Trainer_traine_icon" />
                  <p>{t("selection.role.training_staff")}</p>
                  <div className="select_cont">
                    <div
                      className={role === 3 ? "slected" : "unselected"}
                    ></div>
                  </div>
                </div>
                <div
                  className="Trainer_trainee_cont"
                  onClick={() => setRole(4)}
                >
                  <img src={Trainee} alt="" className="Trainer_traine_icon" />
                  <p>{t("selection.role.trainee")}</p>
                  <div className="select_cont">
                    <div
                      className={role === 4 ? "slected" : "unselected"}
                    ></div>
                  </div>
                </div>
              </div>
              <CustomButton
                value={t("next")}
                type="button"
                variant="contained"
                colors="#126d77"
                classes="auth_btn"
                action={handleSelection}
              />
              {/* <Social /> */}
              <div
                className="auth_label_registar"
                style={{
                  width: "100%",
                  height: "100%",
                  paddingTop: "20px",
                  fontSize: "13px",
                }}
              >
                {/* <Social /> */}
                <Link to="#" className="auth_social_label">
                  {t("DontHaveAnAccount")}{" "}
                  <span style={{ color: "#126d77" }}>{t("register")}</span>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <>
            <span className="small_note">
              {role === 4
                ? t("selection.role.trainee")
                : role === 3
                ? t("selection.role.training_staff")
                : ""}
            </span>{" "}
            <form
              onSubmit={role === 3 ? handleSubmitSSO : handleSubmit}
              className="auth_form_flex_col auth_form_form"
            >
              {role === 5 ? (
                <div>
                  <Input
                    type="text"
                    placeholder={
                      isAlternativeEmail
                        ? alternativeEmail
                        : forgotPassword
                        ? t("Email")
                        : t("email_login")
                    }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    label={
                      isAlternativeEmail ? t("alternative_email") : t("Email")
                    }
                    direction="ltr"
                    // noteLogin={!forgotPassword && t("email_note")}
                  />
                </div>
              ) : (
                <div>
                  <Input
                    // type="number"
                    placeholder={t("system_users.id_number")}
                    value={idNum}
                    onChange={(e) => setIdNum(e.target.value)}
                    required
                    label={t("system_users.id_number")}
                    direction="ltr"
                    // noteLogin={!forgotPassword && t("email_note")}
                  />
                </div>
              )}

              {forgotPassword ? null : (
                <PasswordInput setContent={passwordHandleChange} />
              )}
              {/* <div style={{ textAlign: "center", margin: "15px 0" }}>
                <button
                  className="forgotPasswordBtn"
                  onClick={() =>
                    forgotPassword ? showLogin() : showForgotPassword()
                  }
                  type="button"
                >
                  {forgotPassword ? t("loginBtn") : t("updatePasswordBtn")}
                </button>
              </div> */}
              <CustomButton
                value={
                  forgotPassword
                    ? t("sendEmailForgotPassword")
                    : t("EnterMyAccount")
                }
                type={forgotPassword ? "button" : "submit"}
                variant="contained"
                action={forgotPassword ? () => sendEmailForgotPassword() : null}
                disable={!validateForm() || disabled}
                loading={disabled}
                colors="#025299"
                classes="auth_btn"
              />
              <div className="error_login">{error}</div>
              {state &&
              state.accountStatus &&
              state.accountStatus === "inactive" ? (
                <div className="error_login">{t("error_msg_Suspended")}</div>
              ) : null}
            </form>
            <div
              className="auth_label_registar select_role_back"
              onClick={() => setRoleSelected(true)}
            >
              {/* <p className="auth_label">{t("RegisteraNewUser")}</p> */}
              <span className="small_note_Back">{t("back_to_user_Type")}</span>
            </div>
            <div
              className="auth_label_registar"
              style={{
                width: "100%",
                height: "100%",
                paddingTop: "10px",
                paddingBottom: "30px",
                fontSize: "13px",
              }}
            >
              {/* <Social /> */}
              <Link to="#" className="auth_social_label">
                {t("DontHaveAnAccount")}{" "}
                <span style={{ color: "#126d77" }}>{t("register")}</span>
              </Link>
            </div>
          </>
        )}
      </div>

      <BasicModal ref={invalidEmailModal}>
        {showInvalidEmailModal && (
          <InvalidEmailModal
            img={warning}
            label={t("login_failed")}
            labelClassName="login-invalid-email-label"
            // subLabel={t("login_failed_msg")}
            descriptionOne={t("graduated_condition_msg_one")}
            descriptionTwo={t("graduated_condition_msg_two")}
            // descriptionThree={t("graduated_condition_msg_three")}
            descriptionClassName="login-invalid-email-description"
            successText={t("replace")}
            successColor="#036c77"
            action={() => {
              handleGetAlternativeEmail();
              handleCloseInvalidEmailModal();
            }}
            dismiss={handleCloseInvalidEmailModal}
            dismissText={t("cancel")}
            dismissColor="#9e9e9e"
          />
        )}
      </BasicModal>
    </>
  );
}

export default withRouter(Login);
