import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import styles from "./addTargetModal.module.css";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { toast } from "react-toastify";
import classes from "../../../../../../Modals/AddSetNewQuestions/AddSetNewQuestions.module.css";
import Input from "../../../../../Shared/Components/Input/Input";
import CustomButton from "../../../../../Shared/Components/CustomButton/CustomButton";
import { Formik } from "formik";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch, useSelector } from "react-redux";
import { educationalGoalsActions } from "../../../../../../Redux/Actions/educationalGoals/educationalGoals.actions";

const AddTargetModal = ({
  modalState,
  courseID,
  isEdited,
  isEditedState,
  selectedObjective,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addObjectiveLoading, addObjectiveError } = useSelector(
    (state) => state.educationalGoalsReducer
  );

  useEffect(() => {
    return () => {
      isEditedState(false);
      modalState(false);
    };
  }, []);

  function CloseIcon({ className }) {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <g
          id="x-circle_2_"
          data-name="x-circle (2)"
          transform="translate(-1 -1)"
        >
          <circle
            id="Ellipse_23"
            data-name="Ellipse 23"
            cx="10"
            cy="10"
            r="10"
            transform="translate(2 2)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_35"
            data-name="Line 35"
            x1="6"
            y2="6"
            transform="translate(9 9)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <line
            id="Line_36"
            data-name="Line 36"
            x2="6"
            y2="6"
            transform="translate(9 9)"
            fill="none"
            stroke="#e62626"
            strokeLinecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    );
  }

  return (
    <div className={styles.modal_container}>
      <div className={styles.modal_header_row}>
        <Typography
          id="modal-modal-title"
          variant="h2"
          style={{ color: "#046C77", fontWeight: "bold" }}
        >
          {isEdited ? t("edu.edit_goal") : t("edu.add_new_goal")}
        </Typography>
        <button
          type="button"
          onClick={() => modalState(false)}
          style={{ background: "#fff" }}
        >
          <CloseIcon className="tw-h-6 tw-w-6" />
        </button>
      </div>

      <Divider className={styles.modal_header_divier} />
      <Typography
        id="modal-modal-title"
        variant="h4"
        style={{ color: "#046C77", fontWeight: "bold" }}
      >
        {t("edu.goal_title")}
      </Typography>

      <Formik
        initialValues={{
          name_ar: isEdited ? selectedObjective.name_ar : "",
          name_en: isEdited ? selectedObjective.name_en : "",
          description_ar: isEdited ? selectedObjective.description_ar : "",
          description_en: isEdited ? selectedObjective.description_en : "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (isEdited) {
              let editedDataEduGoal = {
                ...values,
                _method: "PUT",
              };
              await dispatch(
                educationalGoalsActions.editEducationalObjective(
                  selectedObjective.id,
                  editedDataEduGoal
                )
              );
              modalState(false);
            } else {
              let dataEduGoal = {
                course_id: courseID,
                ...values,
              };
              await dispatch(
                educationalGoalsActions.addEducationalObjective(dataEduGoal)
              );
              modalState(false);
            }

            if (!addObjectiveLoading && !addObjectiveError) {
              modalState(false);
              isEditedState(false);
            }
          } catch (err) {
            console.log(addObjectiveError);
            toast
              .error
              // <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>{err.response.data.msg}</span>
              // <span style={{ fontSize: 13, fontWeight: "bold", display:'block', paddingBottom: '10px' }}>{addObjectiveError}</span>
              ();
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name_ar) {
            errors.name_ar = t("edu.errors.required");
          }

          if (!values.name_en) {
            errors.name_en = t("edu.errors.required");
          }

          if (!values.description_ar) {
            errors.description_ar = t("edu.errors.required");
          }

          if (!values.description_en) {
            errors.description_en = t("edu.errors.required");
          }
          return errors;
        }}
        validateOnChange={addObjectiveLoading}
      >
        {({ errors, handleChange, handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className={styles.modal_container}>
            <div className={classes["m-20"]}>
              <Input
                label={t("edu.goal_title_ar")}
                type="text"
                name="name_ar"
                value={values.name_ar}
                placeholder={t("edu.goal_title_ar")}
                onChange={handleChange}
                error={<errors className="name_ar"></errors>}
              />
            </div>

            <div className={classes["m-20"]}>
              <Input
                label={t("edu.goal_title_en")}
                type="text"
                name="name_en"
                value={values.name_en}
                placeholder={t("edu.goal_title_en")}
                onChange={handleChange}
                error={errors.name_en}
              />
            </div>

            <Typography
              id="modal-modal-title"
              variant="h4"
              style={{
                marginTop: 25,
                color: "#046C77",
                fontWeight: "bold",
              }}
            >
              {t("edu.targetDescription")}
            </Typography>

            <div className={classes["m-20"]}>
              <label htmlFor="description_ar" style={{ color: "#777777" }}>
                {t("edu.targetDescAr")}
              </label>
              <SunEditor
                placeholder={t("edu.targetDescAr")}
                value={values.description_ar}
                defaultValue={values.description_ar}
                onChange={(e) => {
                  handleChange({
                    target: { name: "description_ar", value: e },
                  });
                }}
                setOptions={{
                  height: 150,
                  buttonList: [[]],
                }}
                setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px; text-align: right; direction: rtl"
              />
              {errors.description_ar && (
                <p
                  className={`form-input-error-space ${styles.input_field_error}`}
                >
                  {errors.description_ar}
                </p>
              )}
            </div>

            <div className={classes["m-20"]}>
              <label htmlFor="description_en" style={{ color: "#777777" }}>
                {t("edu.targetDescEn")}
              </label>
              <SunEditor
                placeholder={t("edu.targetDescEn")}
                value={values.description_en}
                defaultValue={values.description_en}
                onChange={(e) => {
                  handleChange({
                    target: { name: "description_en", value: e },
                  });
                }}
                setOptions={{
                  height: 150,
                  buttonList: [[]],
                }}
                setDefaultStyle="font-family: 'Montserrat', sans-serif; font-size: 16px; text-align: right; direction: rtl"
              />
              {errors.description_en && (
                <p
                  className={`form-input-error-space ${styles.input_field_error}`}
                >
                  {errors.description_en}
                </p>
              )}
            </div>

            <div className={`${classes["modal-button"]} modal-buttons tw-mt-5`}>
              <CustomButton
                value={t("FAQ.done")}
                colors={"#036c77"}
                type={"button"}
                iconClass={"mt-2"}
                action={handleSubmit}
                classes={styles.button_width}
                loading={addObjectiveLoading}
                disable={addObjectiveLoading}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddTargetModal;
