import React from "react";
import EventCard from "./EventCard";
import classes from "./CalenderNew.module.css";

export default function DayEvents({ eventsOfDay, badgesLoading, eventTypes }) {
  console.log(eventsOfDay);

  return (
    <div className={classes.dayEvents}>
      <div className={classes.eventCardsContainer}>
        {eventsOfDay.map((event) => (
          <EventCard event={event} />
        ))}
      </div>
      <div className={classes.badgesCont}>
        {!badgesLoading &&
          eventTypes?.map((type) => (
            <>
              <div
                className={classes.badge}
                style={{ backgroundColor: type.color }}
              ></div>
              <p className={classes.typeText}>{type.text}</p>
            </>
          ))}
      </div>
    </div>
  );
}
