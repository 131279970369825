import { specialtiesDetailsConstants } from "../../Constants/specialties/specialtiesDetails.constants";

const initialState = {
  specialty: {},
  departments: [],
  specialtyLoading: false,
  specialtyError: null,
  specialtySuccess: false,
  specialtyMeta: {},

  updateSpecialtyStatusLoading: false,
  updateSpecialtyStatusError: null,
  updateSpecialtyStatusSuccess: false,
};

const specialtiesDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET SPECIALTY
    case specialtiesDetailsConstants.GET_SPECIALTY_REQUEST:
      return {
        ...state,
        specialtyLoading: true,
      };

    case specialtiesDetailsConstants.GET_SPECIALTY_SUCCESS:
      return {
        ...state,
        specialtyLoading: false,
        specialtySuccess: true,
        specialtyError: null,
        specialty: action.payload.specialty,
        departments: action.payload.departments,
        specialtyMeta: action.payload.meta,
      };

    case specialtiesDetailsConstants.GET_SPECIALTY_REJECTED:
      return {
        ...state,
        specialtyLoading: false,
        specialtyError: action.payload,
        specialtySuccess: false,
      };
    // UPDATE Specialty STATUS
    case specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_REQUEST:
      return {
        ...state,
        updateSpecialtyStatusLoading: true,
      };

    case specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_SUCCESS:
      return {
        ...state,
        updateSpecialtyStatusLoading: false,
        updateSpecialtyStatusSuccess: true,
        updateSpecialtyStatusError: null,

        departments: state.departments.map((department) => {
          if (department.id === action.payload.id) {
            return {
              ...department,
              status: !department.status,
            };
          }
          return department;
        }),
      };

    case specialtiesDetailsConstants.UPDATE_SPECIALTY_STATUS_REJECTED:
      return {
        ...state,
        updateSpecialtyStatusLoading: false,
        updateSpecialtyStatusError: action.payload,
        updateSpecialtyStatusSuccess: false,
      };

    default:
      return state;
  }
};

export default specialtiesDetailsReducer;
