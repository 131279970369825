import { useTranslation } from "react-i18next";
import calendericon from "../../../../assets/icons/dateIcon.svg";
import seaticon from "../../../../assets/icons/seaticon.svg";
import CustomButton from "../CustomButton/CustomButton";
import Title from "../Title/Title";
import classes from "./courseCard.module.css";
import i18n from "../../../../i18n/i18n";

const CourseCard = ({
  img,
  rightimgbadge,
  rightcolorbadge,
  leftimgbadge,
  leftcolorbadge,
  badgelabel,
  badgecolor,
  title,
  price,
  offer = false,
  date,
  seat,
  hour,
  levelbadge,
  levelcolor,
  handleSubmit,
  expired,
  isClosed,
  btn,
  level,
  userdata = false,
  userimg,
  username,
  selfPaced,
  userrate,
  isCurrentCourses,
  progressValue = 0,
  watchValue = 0,
  mode,
  briefDesc,
  trainers,
  home,
}) => {
  const { t } = useTranslation();

  const levelclass =
    level === "1"
      ? classes["badge-green"]
      : level === "2"
      ? classes["badge-yellow"]
      : level === "3"
      ? classes["badge-black"]
      : "";

  return (
    <div
      className={classes.card}
      style={home ? { margin: "0 10px" } : { width: "100%" }}
    >
      <div className={classes["header-content"]}>
        <div className={classes["img-container"]}>
          <div className={classes["img-badge"]}>
            {isCurrentCourses || isClosed ? null : (
              <>
                <div className={classes["price-container"]}>
                  {offer ? (
                    <span className={classes["old-price"]}>
                      {price} {t("rs")}
                    </span>
                  ) : null}
                  <label className={classes.price}>
                    {mode === "Paid" ? (
                      <>
                        {offer ? null : (
                          <p className={classes["price-label"]}>
                            {t("general.price")}
                          </p>
                        )}
                        <span className={classes["price-number"]}>
                          {offer
                            ? Number(offer.mechanism) === 1
                              ? Number(price) - Number(offer.value)
                              : Number(price) -
                                (Number(price) * Number(offer.value)) / 100
                            : price}
                        </span>{" "}
                        <span className={classes.cur}>{t("rs")}</span>
                      </>
                    ) : (
                      <>
                        <p className={classes["price-label"]}>
                          {t("general.free")}
                        </p>
                        <span className={classes["price-number"]}>
                          0.00 <span className={classes.cur}>{t("rs")}</span>
                        </span>
                      </>
                    )}
                  </label>
                </div>
                {offer && (
                  <div className={classes["offer-badge"]}>
                    <span className={classes["offer-badge-off"]}>
                      {t("general.off")}
                    </span>
                    <span className={classes["offer-badge-number"]}>
                      {offer.value +
                        " " +
                        (Number(offer.mechanism) === 1 ? t("general.rs") : "%")}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <img src={img} alt="course" className={classes.img} />
        </div>
      </div>

      <div className={classes["bottom-content"]}>
        <div>
          <p style={{ color: "#03A2B2", fontSize: "12px", fontWeight: 600 }}>
            {levelbadge && `${levelbadge} - `}
            {hour && `${hour} ${t("general.hours")}`}
          </p>
        </div>
        <div className={classes["title-price-container"]}>
          <Title
            title={`${title?.slice(0, 28)} ${title.length > 28 ? "..." : ""}`}
            fontSize={20}
            titleColor="#000"
          />
          <div className={classes["desc-container"]}>{briefDesc}</div>
        </div>
        {isClosed ? (
          <p style={{ color: "#046c77" }}>{t("course_completed")}</p>
        ) : (
          <>
            {isCurrentCourses ? (
              <>
                <div className={classes["progress-container"]}>
                  <h3 style={{ color: "#6d6d6d" }}>
                    {t("achivementProgress")}
                  </h3>
                  <div className={classes["progress"]}>
                    <div
                      className={classes["progress-bar"]}
                      role="progressbar"
                      style={{
                        width: `${progressValue}%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <p style={{ color: "#6d6d6d" }}>{progressValue}%</p>
                </div>
                <div className={classes["progress-container"]}>
                  <h3 style={{ color: "#6d6d6d" }}>
                    {t("watchingProgressTwo")}
                  </h3>
                  <div className={classes["progress"]}>
                    <div
                      className={classes["watch-progress-bar"]}
                      role="progressbar"
                      style={{
                        width: `${watchValue}%`,
                      }}
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <p style={{ color: "#6d6d6d" }}>{watchValue}%</p>
                </div>
              </>
            ) : (
              trainers && (
                <div className={classes["user-container"]}>
                  <div style={{ display: "flex" }}>
                    {trainers.map(
                      (trainer, index) =>
                        index < 4 && (
                          <img
                            className={classes["user-img"]}
                            alt="trainer img"
                            src={trainer.img}
                          />
                        )
                    )}
                    {trainers.length > 4 && (
                      <div className={classes["user-img-more"]}>
                        <p>+{trainers.length - 4}</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {trainers.map((trainer, index) => {
                        if (index === trainers.length - 1) {
                          return (
                            <span className={classes["trainers-name"]}>
                              {i18n.language === "ar"
                                ? trainer.name_ar
                                : trainer.name_en}
                            </span>
                          );
                        } else {
                          return (
                            <span className={classes["trainers-name"]}>
                              {i18n.language === "ar"
                                ? trainer.name_ar
                                : trainer.name_en}
                              ,&nbsp;
                            </span>
                          );
                        }
                      })}
                    </div>
                    <p className={classes["trainers-tag"]}>{t("Trainers")}</p>
                  </div>
                </div>
              )
            )}
          </>
        )}
        <div className={classes["date-seat-hour"]}>
          {!selfPaced && (
            <span className={classes.iconscontainer}>
              <img
                src={calendericon}
                alt=""
                // style={{
                //   width: "16px",
                // }}
              />{" "}
              {date}
            </span>
          )}

          <span className={classes.iconscontainer}>
            <img
              src={seaticon}
              alt=""
              // style={{
              //   width: "18px",
              // }}
            />{" "}
            {seat} {t("general.seat_available")}
          </span>
        </div>
        <CustomButton
          value={btn}
          colors={"#03A2B2"}
          type={"button"}
          action={() => handleSubmit()}
          disable={expired}
          classes={classes.btn}
        />
      </div>
    </div>
  );
};

export default CourseCard;
